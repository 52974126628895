import React, { useContext, useEffect, useState } from 'react'

import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { useNavigate } from 'react-router-dom'
import DataTable from 'react-data-table-component'
import { ToastContainer, toast } from 'react-toastify'
import { IoClose } from 'react-icons/io5'
import { MdOutlineEdit } from 'react-icons/md'
import dayjs from 'dayjs'
import { UserContext } from './UserContext'
import { StockListContext } from './StockListContext'
import LivePrice from './LivePrice'

const PendingTrade = () => {
  const { userProfileData, setUserProfileData } = useContext(UserContext)
  const { socketData, orderData } = useContext(StockListContext)
  // console.log('orderData', orderData)
  const [realTimePrices, setRealTimePrices] = useState({})
  useEffect(() => {
    // Update real-time prices when socketData changes
    if (socketData) {
      setRealTimePrices(socketData)
    }
  }, [socketData])

  const navigate = useNavigate()
  const [withrawalAmount, setWithrawalAmount] = useState('')
  const [withrawalRemark, setWithrawalRemark] = useState('')
  const [transactionTypeFilter, setTransactionTypeFilter] = useState('')
  const [stockBuyMarketLimt, setStockBuyMarketLimt] = useState('')

  const token = localStorage.getItem('accesToken')
  const baseUrl = process.env.REACT_APP_API_BASE_URL

  const [historyData, setHistoryData] = useState([])
  const [pendingListData, setpendingListData] = useState('')

  // =================order history==================

  const orderHistory = () => {
    var myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${token}`)
    var formdata = new FormData()

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    }

    fetch(`${baseUrl}pending-order-list`, requestOptions)
      .then(response => {
        if (response.status === 401) {
          navigate('/login')
          localStorage.removeItem('accesToken')
        }
        return response.json()
      })
      .then(result => {
        // console.log(result)
        setpendingListData(result?.data)
      })
      .catch(error => console.log('error', error))
  }

  useEffect(() => {
    orderHistory()
  }, [token])

  const [modalData, setmodalData] = useState([])
  const [modalDataEditData, setmodalDataEditData] = useState([])

  // open modal for
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const openModalWithData = rowData => {
    setShow(true)
    setmodalData(rowData)
  }

  // open modal for edit data
  const [showDataEdit, setShowDataEdit] = useState(false)
  const handleCloseDataEdit = () => setShowDataEdit(false)

  const [triggrtPrice, setTriggerPrice] = useState('')
  const [price, setPrice] = useState('')
  const [lastPrice, setlastPrice] = useState('')

  const openModalWithDataEdit = rowData => {
    setShowDataEdit(true)
    setmodalDataEditData(rowData)
    setPrice(rowData?.price)
    setTriggerPrice(rowData?.tiggere_price)
    setStockBuyMarketLimt(rowData?.market_limit_type)
    setlastPrice(rowData?.price)
  }

  const PendingListcolumns = [
    {
      name: 'SR No.',
      selector: (row, index) => index + 1,
      sortable: true,
      grow: 0,
      sortable: true,
      maxWidth: '50px'
    },
    {
      name: 'Time & Date',
      selector: 'created_at',
      sortable: true,
      minWidth: '150px',

      cell: row => {
        // Format the 'created_at' date using dayjs
        return dayjs(row.created_at).format('hh:mm A, DD MMM YYYY') // Adjust the format as needed
      }
    },
    {
      name: 'Buy / Sell',
      selector: 'buy_sell_type',
      sortable: true,
      cell: row => {
        const backgroundColor =
          row.buy_sell_type === 'sell' ? '#ff0000cc' : '#008000c4' // Example: Setting different colors based on the type
        const textColor = 'white' // White text color

        const cellStyle = {
          backgroundColor,
          color: textColor,
          padding: '8px 16px', // Add padding as needed
          borderRadius: '2px' // Add border-radius as needed
        }

        return <div style={cellStyle}>{row.buy_sell_type}</div>
      }
    },

    {
      name: 'Instrument',
      selector: 'stock_name',
      sortable: true,
      minWidth: '150px'
    },
    // {
    //   name: 'Quantity',
    //   cell: row => {
    //     const quantity = row.quantity
    //     const leverage = row.leverage

    //     if (quantity !== 0 || quantity > 2) {
    //       const quantityByLeverage = quantity / leverage
    //       return quantityByLeverage // Displaying the result with 2 decimal places
    //     } else {
    //       return 'N/A' // To handle division by zero or null leverage
    //     }
    //   },
    //   sortable: false // You may set sortable as per your requirement
    // },
    {
      name: 'Quantity',
      selector: 'actual_quantity',
      sortable: true,
      minWidth: '100px'
    },

    {
      name: 'Current Price',
      selector: 'stock_name',
      sortable: true,
      minWidth: '130px',
      cell: row => {
        return <LivePrice stockName={row.stock_full_name} />
      }
      // cell: row => {
      //   const stockKey = row.stock_full_name

      //   if (
      //     (realTimePrices[stockKey] &&
      //       realTimePrices[stockKey].ff &&
      //       realTimePrices[stockKey].ff.indexFF) ||
      //     (realTimePrices[stockKey] &&
      //       realTimePrices[stockKey].ff &&
      //       realTimePrices[stockKey].ff.marketFF)
      //   ) {
      //     const openValue =
      //       realTimePrices[stockKey].ff?.indexFF?.ltpc?.ltp ||
      //       realTimePrices[stockKey].ff?.marketFF?.ltpc?.ltp
      //     return <div>{openValue}</div>
      //   } else {
      //     return <div>-</div>
      //   }
      // }
    },

    {
      name: 'Price',
      selector: 'price',
      sortable: true
    },
    // {
    //   name: 'Profit/Loss (%)',
    //   selector: 'profit_loss_percentage',
    //   sortable: true,
    //   minWidth: '140px',
    //   cell: row => {
    //     const costPrice = row.price // Assuming this is the cost price
    //     const currentPrice =
    //       realTimePrices[row.stock_full_name]?.ff?.indexFF?.ltpc?.ltp || null

    //     if (costPrice && currentPrice) {
    //       const profitLoss = ((currentPrice - costPrice) / costPrice) * 100
    //       const formattedProfitLoss = profitLoss.toFixed(2) // Displaying the result with 2 decimal places
    //       const textColor = profitLoss >= 0 ? '#008000' : '#ff0000' // Green for profit, red for loss

    //       const cellStyle = {
    //         color: textColor,
    //         padding: '8px 16px', // Add padding as needed
    //         borderRadius: '2px' // Add border-radius as needed
    //       }

    //       return <div style={cellStyle}>{formattedProfitLoss}%</div>
    //     } else {
    //       return <div>-</div>
    //     }
    //   }
    // },
    // {
    //   name: 'Profit/Loss',
    //   selector: 'profit_loss_percentage',
    //   sortable: true,
    //   minWidth: '130px',
    //   cell: row => {
    //     const costPrice = row.price // Assuming this is the cost price
    //     const currentPrice =
    //       realTimePrices[row.stock_full_name]?.ff?.indexFF?.ltpc?.ltp || null

    //     if (costPrice && currentPrice) {
    //       const profitLoss = currentPrice - costPrice
    //       const formattedProfitLoss = profitLoss.toFixed(2) // Displaying the result with 2 decimal places
    //       const textColor = profitLoss >= 0 ? '#008000' : '#ff0000' // Green for profit, red for loss

    //       const cellStyle = {
    //         color: textColor,
    //         padding: '8px 16px', // Add padding as needed
    //         borderRadius: '2px' // Add border-radius as needed
    //       }

    //       return <div style={cellStyle}>{formattedProfitLoss}</div>
    //     } else {
    //       return <div>-</div>
    //     }
    //   }
    // },
    {
      name: 'Leverage',
      selector: 'leverage',
      sortable: true,
      cell: row => {
        const isNumber = typeof row.leverage === 'number'
        return isNumber ? `${row.leverage}X` : row.leverage
      }
    },
    // {
    //   name: 'Total Price',
    //   selector: 'total_amount',
    //   sortable: true
    // },
    {
      name: 'Type',
      selector: 'market_limit_type',
      sortable: true
    },

    {
      name: 'Trigger Price',
      selector: 'tiggere_price',
      sortable: true,
      cell: row => {
        return row.tiggere_price || '-'
      }
    },
    {
      name: 'Status',
      selector: 'order_status',
      sortable: true
    },
    {
      name: 'Actions',
      cell: row => (
        <>
          <button
            title='Trade Close'
            className='table_buttons table_buttons_danger'
            onClick={() => openModalWithData(row)}
          >
            <IoClose />
          </button>
          <button
            title='Trade Close'
            className='table_buttons table_buttons_edit'
            onClick={() => openModalWithDataEdit(row)}
          >
            <MdOutlineEdit />
          </button>
        </>
      ),
      button: true
    }
  ]

  // ===========stockDataEtit===========
  const stockDataEtit = e => {
    e.preventDefault()

    // if (parseFloat(triggrtPrice) > parseFloat(price)) {
    //   toast.error('Trigger price cannot be greater than the price.', {
    //     autoClose: 2000
    //   })
    //   return
    // }

    var myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${token}`)

    var formdata = new FormData()
    formdata.append('id', modalDataEditData?.id)

    if (stockBuyMarketLimt === 'SL') {
      formdata.append('tiggere_price', triggrtPrice)
    }

    // formdata.append('buy_sell_type', modalDataEditData?.buy_sell_type)
    formdata.append('quantity', modalDataEditData?.quantity)
    formdata.append('market_limit_type', stockBuyMarketLimt)
    formdata.append('price', price)

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    }

    fetch(`${baseUrl}order-edit`, requestOptions)
      .then(response => {
        if (response.status === 401) {
          navigate('/login')
          localStorage.removeItem('accesToken')
        }
        return response.json()
      })
      .then(result => {
        console.log(result)

        if (result.status) {
          toast.success(result.message, {
            autoClose: 1000
          })

          setShowDataEdit(false)
          orderHistory()
        } else {
          toast.error(result.message, {
            autoClose: 2000
          })
        }
      })
      .catch(error => console.log('error', error))
  }

  const stockHistory = () => {
    var myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${token}`)

    var formdata = new FormData()
    if (
      transactionTypeFilter &&
      transactionTypeFilter !== 'All transaction types'
    ) {
      formdata.append('type', transactionTypeFilter) // Include transactionTypeFilter in the request if it exists and is not "All transaction types"
    }

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    }

    fetch(`${baseUrl}stock-history`, requestOptions)
      .then(response => {
        if (response.status === 401) {
          navigate('/login')
          localStorage.removeItem('accesToken')
        }
        return response.json()
      })
      .then(result => {
        // console.log(result)

        if (result && result.data) {
          let filteredData = result.data.reverse()

          // Check if transactionTypeFilter exists and is not "All transaction types" to filter data
          if (
            transactionTypeFilter &&
            transactionTypeFilter !== 'All transaction types'
          ) {
            filteredData = result.data.filter(
              item => item.buy_sell_type === transactionTypeFilter
            )
          }

          setHistoryData(filteredData.reverse())
        } else {
          console.log('No data found or incorrect API response structure.')
        }
      })
      .catch(error => console.log('Error fetching or filtering data:', error))
  }

  // ========Trade Close==============

  const TradeClose = () => {
    var myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${token}`)

    var formdata = new FormData()
    formdata.append('id', modalData?.id)

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    }

    fetch(`${baseUrl}trade-cancel`, requestOptions)
      .then(response => {
        if (response.status === 401) {
          navigate('/login')
          localStorage.removeItem('accesToken')
        }
        return response.json()
      })
      .then(result => {
        console.log(result)
        if (result.status) {
          toast.success(result.message, {
            autoClose: 1000
          })

          setShow(false)
          orderHistory()
          stockHistory()

          setTimeout(() => {
            window.location.reload()
          }, 1000)
        } else {
          toast.error(result.message, {
            autoClose: 2000
          })
        }
      })
      .catch(error => console.log('error', error))
  }

  return (
    <div>
      <DataTable
        className='mt-2'
        columns={PendingListcolumns}
        data={pendingListData}
        pagination // Enable pagination if needed
      />

      {/* close Trade Modal */}
      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {' '}
            <h5> Are you sure you want to close trade? </h5>{' '}
          </Modal.Title>
        </Modal.Header>

        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            No
          </Button>
          <Button variant='primary' onClick={TradeClose}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
      {/* edit modal */}
      <Modal
        show={showDataEdit}
        onHide={handleCloseDataEdit}
        aria-labelledby='contained-modal-title-vcenter'
        centered
        className='stock_modal_main stock_modal_edit'
      >
        <form onSubmit={stockDataEtit}>
          {/* <Modal.Header
            closeButton
            style={{
              backgroundColor:
                modalDataEditData?.buy_sell_type === 'sell'
                  ? '#0848af'
                  : '#eb3b3b'
            }}
          >
            <Modal.Title className='stock_modal_title'>
              <h4 className='green_text mr-2'>
                Sell {modalDataEditData?.stock_name}
                <span>BSE * </span>{' '}
                <span>{modalDataEditData?.quantity} Qty</span>
              </h4>
              <h6 className='text-light'>
                Total Price:
                {(
                  modalDataEditData?.quantity * modalDataEditData?.price
                ).toFixed(2)}
              </h6>
            </Modal.Title>
          </Modal.Header> */}
          <Modal.Header
            closeButton
            style={{
              backgroundColor:
                modalDataEditData?.buy_sell_type === 'sell'
                  ? '#eb3b3b'
                  : '#0848af'
            }}
          >
            <Modal.Title className='stock_modal_title'>
              <h4 className='green_text mr-2'>
                {modalDataEditData?.buy_sell_type === 'buy' ? (
                  <>
                    Buy {modalDataEditData?.stock_name}
                    <span>BSE * </span>
                    <span>{modalDataEditData?.quantity} Qty</span>
                  </>
                ) : modalDataEditData?.buy_sell_type === 'sell' ? (
                  <>
                    Sell {modalDataEditData?.stock_name}
                    <span>BSE * </span>
                    <span>{modalDataEditData?.quantity} Qty</span>
                  </>
                ) : (
                  // Handle other cases if needed
                  <span>Unknown buy_sell_type</span>
                )}
              </h4>

              <h6 className='text-light'>
                Total Price:
                {(
                  modalDataEditData?.quantity * modalDataEditData?.price
                ).toFixed(2)}
              </h6>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <div className='buy_modal_price_box'>
                  <div className='input-container'>
                    <input
                      type='number'
                      id='inputField'
                      placeholder=''
                      value={modalDataEditData?.quantity}
                      onChange={e =>
                        setmodalDataEditData(prevData => ({
                          ...prevData,
                          quantity: e.target.value
                        }))
                      }
                      min='1'
                    />
                    <label for='inputField' className='input-label'>
                      Qty
                    </label>
                  </div>
                  <div>
                    <div className='input-container'>
                      <input
                        type='number'
                        id='inputFieldbuyprice'
                        placeholder=''
                        // value={price}
                        value={
                          stockBuyMarketLimt === 'market' ? lastPrice : price
                        }
                        onChange={e => setPrice(e.target.value)}
                        disabled={
                          stockBuyMarketLimt === 'market' ||
                          stockBuyMarketLimt === 'SL'
                        }
                      />
                      <label for='inputFieldbuyprice' className='input-label'>
                        Price
                      </label>
                    </div>
                    <div className='d-flex flex_radio_buttons_div'></div>
                  </div>
                  <div>
                    <div className='input-container'>
                      <input
                        type='text'
                        id='inputField'
                        placeholder=''
                        value={triggrtPrice}
                        onChange={e => setTriggerPrice(e.target.value)}
                        disabled={
                          stockBuyMarketLimt === 'market' ||
                          stockBuyMarketLimt === 'limit'
                        }
                      />
                      <label for='inputField' className='input-label'>
                        Trigger Price
                      </label>
                    </div>
                  </div>
                </div>

                <div className='flex_radio_buttons_div mb-2'>
                  <div className='d-flex'>
                    <input
                      type='radio'
                      id='market'
                      name='marketLimit'
                      value='market'
                      checked={stockBuyMarketLimt === 'market'}
                      // onChange={() => setStockBuyMarketLimt('market')}
                      onChange={() => {
                        setStockBuyMarketLimt('market')
                        setPrice(lastPrice)
                      }}
                    />
                    <label for='market'>Market</label>
                  </div>
                  <div className='d-flex'>
                    <input
                      type='radio'
                      id='limit'
                      name='marketLimit'
                      value='limit'
                      checked={stockBuyMarketLimt === 'limit'}
                      onChange={() => setStockBuyMarketLimt('limit')}
                    />
                    <label for='limit'>Limit</label>
                  </div>
                  <div className='d-flex'>
                    <input
                      type='radio'
                      id='SL'
                      name='marketLimit'
                      value='SL'
                      checked={stockBuyMarketLimt === 'SL'}
                      // onChange={() => setStockBuyMarketLimt('SL')}
                      onChange={() => {
                        setStockBuyMarketLimt('SL')
                        setPrice(lastPrice)
                      }}
                    />
                    <label for='SL'>SL</label>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className='text-right'>
            <div>
              <button
                type='submit'
                className='btn btn-primary '
                style={{ marginRight: '10px' }}
              >
                Submit
              </button>
              <Button variant='secondary' onClick={handleCloseDataEdit}>
                cancel
              </Button>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  )
}

export default PendingTrade
