import React, { useEffect, useRef, memo, useContext, useState } from 'react'
import { createChart, ColorType } from 'lightweight-charts'

import proto from './LivePrice.proto'
import { Buffer } from 'buffer'
import { UpstoxTokenContext } from './UpstoxTokenContext'


export default function Chart(props) {
    const protobuf = require('protobufjs')

    let protobufRoot = null
    const initProtobuf = async () => {
        protobufRoot = await protobuf.load(proto)
        // console.log('Protobuf part initialization complete')
    }
    const { upstoxToken } = useContext(UpstoxTokenContext)

    const getUrl = async token => {
        const apiUrl = 'https://api-v2.upstox.com/feed/market-data-feed/authorize'
        let headers = {
            'Content-type': 'application/json',
            Authorization:
                `Bearer ${upstoxToken}`
        }
        const response = await fetch(apiUrl, {
            method: 'GET',
            headers: headers
        })
        if (!response.ok) {
            throw new Error('Network response was not ok')
        }
        const res = await response.json()
        return res.data.authorizedRedirectUri
    }

    const blobToArrayBuffer = async blob => {
        if ('arrayBuffer' in blob) return await blob.arrayBuffer()
        return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.onload = () => resolve(reader.result)
            reader.onerror = () => reject()
            reader.readAsArrayBuffer(blob)
        })
    }

    const decodeProfobuf = buffer => {
        if (!protobufRoot) {
            console.warn('Protobuf part not initialized yet!')
            return null
        }
        const FeedResponse = protobufRoot.lookupType(
            'com.upstox.marketdatafeeder.rpc.proto.FeedResponse'
        )
        return FeedResponse.decode(buffer)
    }

    const {
        hdatdata,
        stockName,
        colors: {
            backgroundColor = 'white',
            lineColor = '#2962FF',
            textColor = 'black',
            areaTopColor = '#2962FF',
            areaBottomColor = 'rgba(41, 98, 255, 0.28)'
        } = {}
    } = props;
    let chart = null;
    const chartContainerRef = useRef();
    const [refData, seTrefData] = useState(0);


    useEffect(() => {
        initProtobuf();

        const handleResize = () => {
            chart.applyOptions({ width: chartContainerRef.current.clientWidth })
        }

        if (refData == 0) {
            _getDd();
        }

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [hdatdata, stockName]);


    const _getDd = async () => {
        seTrefData(1);
        chart = createChart(chartContainerRef.current, {
            layout: {
                textColor: 'black',
                background: { type: 'solid', color: 'white' }
            },
            width: chartContainerRef.current.clientWidth,
            height: 300,
            timeScale: {
                timeVisible: true,
                secondsVisible: false,
            },
        })

        // console.log("sdf");

        chart.timeScale().fitContent();

        const newSeries = chart.addCandlestickSeries({
            upColor: '#26a69a',
            downColor: '#ef5350',
            borderVisible: false,
            wickUpColor: '#26a69a',
            wickDownColor: '#ef5350'
        })

        try {
            const wsUrl = await getUrl(stockName);
            const ws = new WebSocket(wsUrl);

            ws.onopen = () => {
                const data = {
                    guid: 'someguid',
                    method: 'sub',
                    data: {
                        mode: 'full',
                        instrumentKeys: [stockName]
                    }
                }
                ws.send(Buffer.from(JSON.stringify(data)))
            }

            ws.onclose = () => {
                console.log('Disconnected')
            }

            ws.onmessage = async event => {
                const arrayBuffer = await blobToArrayBuffer(event.data)
                let buffer = Buffer.from(arrayBuffer)
                let result = decodeProfobuf(buffer)
                if (result != null) {
                    const dataPoint = result?.feeds?.[stockName]?.ff.indexFF
                        ? result?.feeds?.[stockName]?.ff.indexFF.marketOHLC.ohlc
                        : result?.feeds?.[stockName]?.ff.marketFF.marketOHLC.ohlc

                    const dataPoint2 = result?.feeds?.[stockName]?.ff.indexFF
                        ? result?.feeds?.[stockName]?.ff.indexFF.ltpc.ltp
                        : result?.feeds?.[stockName]?.ff.marketFF.ltpc.ltp

                    var formattedDatess = new Date()
                    const livedatad = [
                        formattedDatess,
                        dataPoint2,
                        parseFloat(dataPoint[0].high),
                        parseFloat(dataPoint[0].low),
                        parseFloat(dataPoint[0].close)
                    ];


                    const convertDateToString = inputDate => {
                        const date = new Date(inputDate)
                        const year = date.getFullYear()
                        const month = String(date.getMonth() + 1).padStart(2, '0')
                        const day = String(date.getDate()).padStart(2, '0')
                        const hours = String(date.getHours()).padStart(2, '0')
                        const minutes = String(date.getMinutes()).padStart(2, '0')
                        return Date.parse(`${year}-${month}-${day} ${hours}:${minutes}`) / 1000
                    }

                    if (livedatad.length > 0) {
                        const allData = [livedatad, ...hdatdata]
                        const flength = allData.length;
                        const convertedArray = allData.map(data => {
                            return {
                                time: convertDateToString(data[0]),
                                open: data[1],
                                high: data[2],
                                low: data[3],
                                close: data[4]
                            }
                        })
                        const fdata = await convertedArray.sort((a, b) => a.time - b.time);
                        if (fdata.length > 2 && fdata.length == flength) {
                            // console.log(fdata.length, flength);
                            // console.log(convertedArray);
                            newSeries.setData(fdata);
                        }
                    }

                }
            }

            ws.onerror = error => {
                console.log('WebSocket error:', error)
            }

            return () => {
                ws.close();
            }
        } catch (error) {
            console.error('WebSocket connection error:', error)
        }

        return chart.remove();
    }

    return (
        <div ref={chartContainerRef}></div>
    )
} 