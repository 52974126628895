import React from 'react'
import MainLayout from './MainLayout'

const Watchlist = () => {
  return (
    <>
      <MainLayout>
        <section className='side_content_main_box'>
          <div className='headings'>
            <h2>Watchlist</h2>
          </div>

          <section className='dashboard_boxes_main_section '>
            <div className='row'>
              <div className='col-12 mb-4'>
                <div>
                  {' '}
                  {/* filters */}
                  <div className='account_filter_div transaction_filter_div'>
                    {/* account type */}
                    <div>
                      <select
                        className='form-select account_filter_btn'
                        aria-label='Default select example'
                      >
                        <option selected> Order Type</option>
                        <option value='Market'>Market</option>
                        <option value='Limit'>Limit</option>
                        <option value='SL Limit'>SL Limit</option>
                        <option value='SL Market'>SL Market</option>
                      </select>
                    </div>

                    {/* date filter */}
                    <div>
                      <select
                        className='form-select account_filter_btn'
                        aria-label='Default select example'
                      >
                        <option selected>Product</option>
                        <option value='Delivery'>Delivery</option>
                        <option value='Intraday'>Intraday</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-lg-4 col-md-6 col-sm-12'>
                <div className='stocks_box_main'>
                  <img
                    src='https://stock-logos.dhan.co/stock-logos/INE669E01016_vodafone_idea.png'
                    alt='stock'
                    className='img-fluid'
                  />
                  <h3>Vodafone Idea</h3>
                  <h4>
                    13.40 -0.45{' '}
                    <span className='stock_red_indicator'>
                      {' '}
                      (-3.25%){' '}
                      <img
                        src='https://dhan.co/_next/static/media/loss.e2d8b7b1.svg'
                        alt='down'
                        className='img-fluid'
                      />{' '}
                    </span>
                  </h4>
                </div>
              </div>

              <div className='col-lg-4 col-md-6 col-sm-12'>
                <div className='stocks_box_main'>
                  <img
                    src='https://stock-logos.dhan.co/stock-logos/INE009A01021_infosys.png'
                    alt='stock'
                    className='img-fluid'
                  />
                  <h3>Infosys</h3>
                  <h4>
                    13.40 -0.45{' '}
                    <span className='stock_red_indicator'>
                      {' '}
                      (-3.25%){' '}
                      <img
                        src='https://dhan.co/_next/static/media/loss.e2d8b7b1.svg'
                        alt='down'
                        className='img-fluid'
                      />{' '}
                    </span>
                  </h4>
                </div>
              </div>

              <div className='col-lg-4 col-md-6 col-sm-12'>
                <div className='stocks_box_main'>
                  <img
                    src='https://stock-logos.dhan.co/stock-logos/INE019A01038_jsw_steel.png'
                    alt='stock'
                    className='img-fluid'
                  />
                  <h3>JSW Steel</h3>
                  <h4>
                    13.40 -0.45{' '}
                    <span className='stock_red_indicator'>
                      {' '}
                      (-3.25%){' '}
                      <img
                        src='https://dhan.co/_next/static/media/loss.e2d8b7b1.svg'
                        alt='down'
                        className='img-fluid'
                      />{' '}
                    </span>
                  </h4>
                </div>
              </div>
            </div>
          </section>
        </section>
      </MainLayout>
    </>
  )
}

export default Watchlist
