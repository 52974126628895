import React, { useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { FaLongArrowAltRight } from 'react-icons/fa'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import { ToastContainer, toast } from 'react-toastify'
import { v4 as uuidv4 } from 'uuid'
const Register = () => {
  const [name, setName] = useState()
  const [email, setEmail] = useState()
  const [mobile, setMobile] = useState()
  const [password, setPassword] = useState()
  const [termsChecked, setTermsChecked] = useState(false)
  const baseUrl = process.env.REACT_APP_API_BASE_URL
  const navigate = useNavigate()

  const device_unique_id = uuidv4()
  const [loginLoader, setLoginLoader] = useState(false)

  const UserRegister = e => {
    e.preventDefault()

    if (!termsChecked) {
      toast.error('Please accept the terms and conditions.', {
        autoClose: 2000
      })
      return // Prevent registration if terms are not accepted
    }

    var formdata = new FormData()
    formdata.append('name', name)
    formdata.append('mobile_number', mobile)
    formdata.append('email', email)
    formdata.append('password', password)
    formdata.append('device_unique_id', device_unique_id)
    formdata.append('device_token', '')
    formdata.append('is_agree', termsChecked ? '1' : '0')

    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    }
    setLoginLoader(true)
    fetch(`${baseUrl}register`, requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.status) {
          toast.success(result.message, {
            autoClose: 1000
          })
          setLoginLoader(false)
          setTimeout(() => {
            navigate('/login')
          }, 2000)
        } else {
          toast.error(result.message, {
            autoClose: 2000
          })
          setLoginLoader(false)
        }
      })
      .catch(error => console.log('error', error))
  }
  return (
    <>
      <Header />
      <ToastContainer />
      <section className='login_main_section'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-6 col-md-12 m-auto form_box'>
              <form className='' onSubmit={UserRegister} autoComplete='off'>
                <div className='headings text-center'>
                  <h2 className=''>
                    Welcome to <span>Wells Capital</span>{' '}
                  </h2>
                  <h2 className=''>Create an Account</h2>
                </div>

                <div className='mb-3'>
                  <label for='name' className='form-label'>
                    Full name
                  </label>
                  <input
                    type='text'
                    className='form-control'
                    id='name'
                    value={name}
                    onChange={e => setName(e.target.value)}
                    placeholder=''
                  />
                </div>
                <div className='mb-3'>
                  <label for='email' className='form-label'>
                    Email address
                  </label>
                  <input
                    type='email'
                    className='form-control'
                    id='email'
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    placeholder=''
                  />
                </div>
                <div className='mb-3'>
                  <label for='phone' className='form-label'>
                    Mobile number
                  </label>
                  <input
                    type='number'
                    className='form-control'
                    id='phone'
                    value={mobile}
                    onChange={e => setMobile(e.target.value)}
                    placeholder=''
                  />
                </div>
                <div className='mb-3'>
                  <label for='password' className='form-label'>
                    Password
                  </label>
                  <input
                    type='password'
                    className='form-control'
                    id='password'
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                    placeholder=''
                  />
                </div>
                <div className='mb-3 form-check'>
                  <input
                    type='checkbox'
                    className='form-check-input'
                    id='terms'
                    checked={termsChecked}
                    onChange={e => setTermsChecked(e.target.checked)}
                  />
                  <label
                    className='form-check-label terms_msg_label'
                    for='terms'
                  >
                    I declare and confirm that I am not a citizen or resident of
                    the US for tax purposes.
                  </label>
                </div>
                <div className='col-12 mt-5 text-center'>
                  <button
                    type='submit'
                    className='yellow_btn start_now_btn'
                    disabled={loginLoader}
                  >
                    {loginLoader ? (
                      'Loading...'
                    ) : (
                      <>
                        Register <FaLongArrowAltRight />{' '}
                      </>
                    )}{' '}
                  </button>
                </div>
                <div className='col-12 mt-4 text-center'>
                  <p>
                    Already have an account{' '}
                    <NavLink to='/login' className=''>
                      Login
                    </NavLink>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  )
}

export default Register
