import React, { createContext, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

export const UserContext = createContext()

export const UserProvider = ({ children }) => {
  const [userProfileData, setUserProfileData] = useState(null)

  const baseUrl = process.env.REACT_APP_API_BASE_URL
  const [accesToken, setAccessToken] = useState('')

  const token = localStorage.getItem('accesToken')
  const navigate = useNavigate()

  const UserProfileDataFun = () => {
    setAccessToken(token)

    var myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${token}`)

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    }

    fetch(`${baseUrl}get-profile`, requestOptions)
      .then(response => {
        if (response.status === 401) {
          navigate('/login')
          localStorage.removeItem('accesToken')
        }
        return response.json()
      })
      .then(result => {
        setUserProfileData(result?.data)
      })
      .catch(error => console.log('error', error))
  }

  useEffect(() => {
    UserProfileDataFun()
  }, [token])

  return (
    <UserContext.Provider value={{ userProfileData, setUserProfileData }}>
      {children}
    </UserContext.Provider>
  )
}
