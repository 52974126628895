import React from 'react'
import { FaLongArrowAltRight } from 'react-icons/fa'
import { NavLink } from 'react-router-dom'

const IncredibleFeatures = () => {
  return (
    <>
      <section className='common_section  Incredible_Features_section'>
        <div className='container'>
          <div className='row text-center'>
            <div className='col-12 mb_50'>
              <div className='headings'>
                <h2 className=''>
                  <span> Trade Stocks</span> with Incredible Features
                </h2>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-6 col-md-6 col-sm-12'>
              <img
                src='https://stock-logos.dhan.co/static-new-images/mtfappscreen.png'
                alt='features'
                className='img-fluid'
              />
            </div>
            <div className='col-lg-6 col-md-6 col-sm-12'>
              <div className='headings'>
                <h2>Margin Trading Facility</h2>
                <p className='common_para'>
                  Take bigger positions with lesser capital with MTF. Get 4x
                  leverage on 950+ stocks and view the leverage amount for every
                  stock before you take a position. Once you've taken a
                  position, sell only when you want to!
                </p>
              </div>
              <div className='row'>
                <div className='col-lg-6 col-md-6 col-sm-12'>
                  <div className='Incredible_Features_small_box'>
                    <img
                      src='https://cdn-icons-png.flaticon.com/128/3449/3449234.png'
                      alt='features'
                      className='img-fluid'
                    />
                    <h4>Leverage upto 20X</h4>
                  </div>
                </div>
                <div className='col-lg-6 col-md-6 col-sm-12'>
                  <div className='Incredible_Features_small_box'>
                    <img
                      src='https://cdn-icons-png.flaticon.com/128/4379/4379798.png'
                      alt='features'
                      className='img-fluid'
                    />
                    <h4>Instant deposit and withdrawal</h4>
                  </div>
                </div>
                <div className='col-lg-6 col-md-6 col-sm-12'>
                  <div className='Incredible_Features_small_box'>
                    <img
                      src='https://cdn-icons-png.flaticon.com/128/13430/13430770.png'
                      alt='features'
                      className='img-fluid'
                    />
                    <h4>Real time stock scans</h4>
                  </div>
                </div>
                <div className='col-lg-6 col-md-6 col-sm-12'>
                  <div className='Incredible_Features_small_box'>
                    <img
                      src='https://cdn-icons-png.flaticon.com/128/7870/7870517.png'
                      alt='features'
                      className='img-fluid'
                    />
                    <h4>Expert advice provided</h4>
                  </div>
                </div>


                <div className='col-lg-6 mt-5'>
                  <NavLink to='/login' className='yellow_btn start_now_btn'>
                    Start Trading <FaLongArrowAltRight />
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default IncredibleFeatures
