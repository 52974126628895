
import React, { useEffect, useState, useContext } from "react";
import proto from "./LivePrice.proto";
import { Buffer } from "buffer";
import { UpstoxTokenContext } from './UpstoxTokenContext'

function LivePrice({ stockName }) {

  const { upstoxToken } = useContext(UpstoxTokenContext)
  const protobuf = require("protobufjs");

  // Initialize Protobuf root
  let protobufRoot = null;
  const initProtobuf = async () => {
    protobufRoot = await protobuf.load(proto);
    // console.log("Protobuf part initialization complete");
  };

  // Function to get WebSocket URL
  const getUrl = async (token) => {
    const apiUrl = "https://api-v2.upstox.com/feed/market-data-feed/authorize";
    let headers = {
      "Content-type": "application/json",
      Authorization: `Bearer ${upstoxToken}`,
    };
    const response = await fetch(apiUrl, {
      method: "GET",
      headers: headers,
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const res = await response.json();
    return res.data.authorizedRedirectUri;
  };

  // Helper functions for handling Blob and ArrayBuffer
  const blobToArrayBuffer = async (blob) => {
    if ("arrayBuffer" in blob) return await blob.arrayBuffer();
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = () => reject();
      reader.readAsArrayBuffer(blob);
    });
  };

  // Decode Protobuf messages
  const decodeProfobuf = (buffer) => {
    if (!protobufRoot) {
      console.warn("Protobuf part not initialized yet!");
      return null;
    }
    const FeedResponse = protobufRoot.lookupType(
      "com.upstox.marketdatafeeder.rpc.proto.FeedResponse"
    );
    return FeedResponse.decode(buffer);
  };

  // MarketDataFeed component

  const [isConnected, setIsConnected] = useState(false);
  const [feedData, setFeedData] = useState([]);
  // console.log("feedData is", feedData)
  // Establish WebSocket connection
  useEffect(() => {
    const connectWebSocket = async (stockName) => {
      try {
        const wsUrl = await getUrl(stockName);
        const ws = new WebSocket(wsUrl);

        ws.onopen = () => {
          setIsConnected(true);
          // console.log("Connected");
          const data = {
            guid: "someguid",
            method: "sub",
            data: {
              mode: "full",
              instrumentKeys: [stockName],
            },
          };
          ws.send(Buffer.from(JSON.stringify(data)));
        };

        ws.onclose = () => {
          setIsConnected(false);
          console.log("Disconnected");
        };

        ws.onmessage = async (event) => {
          const arrayBuffer = await blobToArrayBuffer(event.data);
          let buffer = Buffer.from(arrayBuffer);
          let response = decodeProfobuf(buffer);
          setFeedData(response);
        };

        ws.onerror = (error) => {
          setIsConnected(false);
          console.log("WebSocket error:", error);
        };

        return () => ws.close();
      } catch (error) {
        console.error("WebSocket connection error:", error);
      }
    };

    initProtobuf();
    connectWebSocket(stockName);
  }, [stockName]);

  return (
    <div>
      {feedData?.feeds?.[stockName]?.ff?.marketFF?.ltpc?.ltp || feedData?.feeds?.[stockName]?.ff?.indexFF?.ltpc?.ltp || '0.00'}
    </div>
  );
}

export default LivePrice;
