import React, { useState } from 'react'
import { Modal, Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'

const Deposit = ({ showModalDeposite, handleCloseDeposite }) => {
  const [depositeAmount, setDepositeAmount] = useState('')
  const token = localStorage.getItem('accesToken')
  const baseUrl = process.env.REACT_APP_API_BASE_URL
  const navigate = useNavigate()
  const FundDeposite = e => {
    e.preventDefault()
    var myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${token}`)

    var formdata = new FormData()
    formdata.append('amount', depositeAmount)

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    }

    fetch(`${baseUrl}user-fund-add`, requestOptions)
      .then(response => {
        if (response.status === 401) {
          navigate('/login')
          localStorage.removeItem('accesToken')
        }
        return response.json()
      })
      .then(result => {
        if (result.status) {
          toast.success(result.message, {
            autoClose: 1000
          })
          handleCloseDeposite()
          setDepositeAmount('')

          setTimeout(() => {
            window.location.reload()
          }, 1000)
        } else {
          toast.error(result.message, {
            autoClose: 2000
          })
        }
      })
      .catch(error => console.log('error', error))
  }

  return (
    <>
      <ToastContainer />
      <Modal show={showModalDeposite} onHide={handleCloseDeposite} centered>
        <form onSubmit={FundDeposite}>
          <Modal.Header closeButton>
            <Modal.Title>Amount Deposit </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='mb-3'>
              <label for='amount' className='form-label'>
                Enter Amount
              </label>
              <input
                type='amount'
                className='form-control'
                id='amount'
                placeholder='Enter amount here'
                value={depositeAmount}
                onChange={e => setDepositeAmount(e.target.value)}
              />
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button variant='secondary' onClick={handleCloseDeposite}>
              Close
            </Button>
            <Button
              variant='primary'
              type='submit'
              onClick={handleCloseDeposite}
            >
              Deposit
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  )
}

export default Deposit
