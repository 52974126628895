import React, { useContext, useEffect, useState } from 'react'
import MainLayout from './MainLayout'
import { NavLink } from 'react-router-dom'
import { AiOutlinePlus } from 'react-icons/ai'
import { BsDownload } from 'react-icons/bs'
import { UserContext } from './UserContext'
import Deposit from './Deposit'
import Withdrawal from './Withdrawal'
import dayjs from 'dayjs'
import { MdPieChartOutlined } from 'react-icons/md'
import { IoWalletOutline } from 'react-icons/io5'
import { BsBag } from 'react-icons/bs'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { IoMdInformationCircleOutline } from 'react-icons/io'
import OpenTrade from './OpenTrade'
const MyAccounts = () => {
  const { userProfileData } = useContext(UserContext)

  // ===========deposite modal===========
  const [showModalDeposite, setShowModalDeposite] = useState(false)
  const handleCloseDeposite = () => setShowModalDeposite(false)
  const handleShowDeposite = () => setShowModalDeposite(true)

  // ===========Sell modal===========
  const [showModalWithdrawal, setShowModalWithdrawal] = useState(false)
  const handleCloseWithdrawal = () => setShowModalWithdrawal(false)
  const handleShowWithdrawal = () => setShowModalWithdrawal(true)

  const [OrderHistory, setOrderHistory] = useState([])
  const [currentValueData, setCurrentValueData] = useState([])

  const [loading, setLoading] = useState(true)
  const [transactionHistory, settransactionHistory] = useState([])
  const [loadingTRX, setLoadingTRX] = useState(true)

  const token = localStorage.getItem('accesToken')
  const baseUrl = process.env.REACT_APP_API_BASE_URL

  // ==========Order history=============

  useEffect(() => {
    setLoading(true) // Set loading to true when fetching data

    var myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${token}`)

    var formdata = new FormData()

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    }

    fetch(`${baseUrl}order-list`, requestOptions)
      .then(response => response.json())
      .then(result => {
        // console.log(result)
        // const lastFiveItems = result?.data.slice(-5) // Get the last 5 items
        // setOrderHistory(lastFiveItems)
        setLoading(false) // Set loading to false after fetching data
      })
      .catch(error => {
        console.log('error', error)
        setLoading(false) // Set loading to false in case of an error
      })
  }, [])

  // ==========Transaction history=============
  useEffect(() => {
    setLoadingTRX(true) // Set loading to true when fetching data

    var myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${token}`)

    var formdata = new FormData()

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    }

    fetch(`${baseUrl}transaction-history`, requestOptions)
      .then(response => response.json())
      .then(result => {
        // console.log(result)

        // const lastFiveItems = result?.data.slice(-5) // Get the last 5 items
        // settransactionHistory(lastFiveItems)
        setLoadingTRX(false)
      })
      .catch(error => {
        // console.log('error', error)
        setLoadingTRX(false) // Set loading to false in case of an error
      })
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      try {
        var myHeaders = new Headers()
        myHeaders.append('Authorization', `Bearer ${token}`)
        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow'
        }

        const response = await fetch(
          `${baseUrl}get-current-value`,
          requestOptions
        )
        const result = await response.json()

        setCurrentValueData(result?.data)
        setLoading(false)
      } catch (error) {
        console.error('Error fetching data:', error)
        setLoading(false)
        // Handle the error, you might want to set an error state or display an error message
      }
    }

    fetchData()
  }, [token, baseUrl])

  // margin modal
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  return (
    <>
      <MainLayout>
        <section className='side_content_main_box'>
          <div className='headings'>
            <h2>
              Hi,
              {userProfileData ? userProfileData.name : 'Loading...'}{' '}
            </h2>
            <div className='account_box_side_buttons'>
              <button onClick={handleShowDeposite}>
                <AiOutlinePlus className=' sidebar_link_icon' /> Deposit
              </button>
              <Deposit
                showModalDeposite={showModalDeposite}
                handleCloseDeposite={handleCloseDeposite}
              />

              <button onClick={handleShowWithdrawal}>
                <BsDownload className=' sidebar_link_icon' /> Withdrawal
              </button>
              <Withdrawal
                showModalWithdrawal={showModalWithdrawal}
                handleCloseWithdrawal={handleCloseWithdrawal}
              />
            </div>
          </div>
          <section className='dashboard_boxes_main_section real_demo_tabs'>
            <div className='row'>
              <div className='col-12 Most_Popular_Stocks_tabs '>
                <div className='row'>
                  <div className='col-lg-4 col-md-4 col-sm-12'>
                    <div className='dashboard_boxes'>
                      <h4 className='heading'>
                        <IoWalletOutline className='icon' />
                        Balance
                      </h4>
                      <div className='dashboard_boxes_flex'>
                        <div
                          className='dashboard_boxes_left_side'
                          style={{ border: 'none', paddingLeft: '0' }}
                        >
                          <h3>
                            ₹{' '}
                            {userProfileData &&
                            typeof userProfileData.user_wallet === 'number'
                              ? userProfileData.user_wallet.toFixed(2)
                              : 'Loading...'}
                          </h3>
                          <h5>Balance available</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-4 col-md-4 col-sm-12'>
                    <div className='dashboard_boxes'>
                      <div className='dashbox_heading_flex'>
                        <h4 className='heading'>
                          <MdPieChartOutlined className='icon' />
                          Leverage
                        </h4>

                        <button onClick={handleShow} className='eye_button'>
                          <IoMdInformationCircleOutline className='icon' />
                        </button>
                      </div>
                      <div className='dashboard_boxes_flex'>
                        <div className='dashboard_boxes_left_side'>
                          <h3>Upto {userProfileData?.margin}X</h3>
                          <h5>Leverage available</h5>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='col-lg-4 col-md-4 col-sm-12'>
                    <div className='dashboard_boxes'>
                      <h4 className='heading'>
                        <BsBag className='icon' />
                        Holdings
                      </h4>
                      <div className='dashboard_boxes_flex'>
                        <div className='dashboard_boxes_left_side'>
                          {currentValueData &&
                          currentValueData.totalCurrentValue !== undefined ? (
                            <>
                              <h3
                                className={
                                  currentValueData.totalCurrentValue -
                                    currentValueData.totalHolding <
                                  0
                                    ? 'red_text'
                                    : 'green_text'
                                }
                              >
                                ₹
                                {loading
                                  ? 'Loading...'
                                  : (
                                      currentValueData.totalCurrentValue -
                                      currentValueData.totalHolding
                                    ).toFixed(2)}
                              </h3>
                              <h5>P&L</h5>
                            </>
                          ) : (
                            <p>Loading...</p>
                          )}
                        </div>

                        <div className='dashboard_boxes_left_side'>
                          {currentValueData &&
                          currentValueData.totalCurrentValue !== undefined ? (
                            <>
                              <h5>
                                Current value{' '}
                                <span
                                  className={
                                    currentValueData.totalCurrentValue <
                                    currentValueData.totalHolding
                                      ? 'red_text'
                                      : 'green_text'
                                  }
                                >
                                  ₹{' '}
                                  {loading
                                    ? 'Loading...'
                                    : currentValueData.totalCurrentValue}
                                </span>{' '}
                              </h5>
                              <h5>
                                Investment{' '}
                                <span>
                                  ₹{' '}
                                  {loading
                                    ? 'Loading...'
                                    : currentValueData.totalHolding}
                                </span>{' '}
                              </h5>
                            </>
                          ) : (
                            <p>Loading...</p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* order History */}
            <div className='row'>
              <div className='col-12'>
                <div className='dashbaord_table'>
                  <div className='table-responsive'>
                    <h3 className='dashboard_small_headings'>Open Trade</h3>
                    {/* {loading ? (
                      <div>Loading...</div>
                    ) : (
                      <>
                        <table className='table table-bordered'>
                          <thead>
                            <tr>
                              <th scope='col'>SR.NO</th>
                              <th scope='col'>Time & Date</th>
                              <th scope='col'>Buy/Sell</th>
                              <th scope='col'>Product</th>
                              <th scope='col'>QTY</th>
                              <th scope='col'>Price</th>
                              <th scope='col'>Type</th>
                              <th scope='col'>Trade Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {OrderHistory.map((data, index) => (
                              <tr key={index}>
                                <th scope='row'>{index + 1}</th>
                                <td>
                                  {dayjs(data?.created_at).format(
                                    'hh:mm a, MMM DD, YYYY'
                                  )}
                                </td>
                                <td>{data?.buy_sell_type}</td>
                                <td>{data?.stock_name}</td>
                                <td>{data?.quantity}</td>
                                <td>{data?.price}</td>
                                <td>{data?.market_limit_type}</td>
                                <td>{data?.trade_status}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                        <div className='dashboard_link'>
                          <NavLink to='/orders'>View More</NavLink>
                        </div>
                      </>
                    )} */}
                    <OpenTrade />
                  </div>
                </div>
              </div>
            </div>

            {/* transaction History */}
            <div className='row'>
              <div className='col-12'>
                <div className='dashbaord_table'>
                  <div className='table-responsive'>
                    <h3 className='dashboard_small_headings'>
                      Transaction history
                    </h3>
                    {loading ? (
                      // Show a loader while data is being fetched
                      <div>Loading...</div>
                    ) : (
                      // Render the table once data is loaded
                      <>
                        <table className='table table-bordered'>
                          <thead>
                            <tr>
                              <th scope='col'>SR.NO</th>
                              <th scope='col'>Time & Date</th>
                              <th scope='col'>Type</th>
                              <th scope='col'>Amount</th>
                              {/* <th scope='col'>Status</th> */}
                              <th scope='col'>Remark</th>
                            </tr>
                          </thead>
                          <tbody>
                            {transactionHistory.map((data, index) => (
                              <tr key={index}>
                                <th scope='row'>{index + 1}</th>
                                <td>
                                  {dayjs(data?.created_at).format(
                                    'hh:mm a, MMM DD, YYYY'
                                  )}
                                </td>
                                <td>{data?.trnx_type}</td>
                                <td>{data?.amount}</td>
                                {/* <td>{data?.status}</td> */}
                                <td>{data?.hash}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>

                        <div className='dashboard_link'>
                          <NavLink to='/transaction'>View More</NavLink>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>
      </MainLayout>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>The Margin Slab</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <table className='table table-bordered'>
              <thead className='thead-light'>
                <tr>
                  <td>Sr. No.</td>
                  <td>Wallet Balance</td>
                  <td>Margin</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td> 0 - 500K</td>
                  <td>Upto 3X</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td> 500K - 1000K</td>
                  <td>Upto 10X</td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>More than 1000k</td>
                  <td>Upto 20X</td>
                </tr>
              </tbody>
            </table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default MyAccounts
