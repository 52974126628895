import React, { createContext, useState, useEffect } from 'react'
import socket from './socket'
export const StockListContext = createContext()

export const StockListProvider = ({ children, stockName }) => {

  // console.log("stockName is ",)
  const [stockListData, setStockList] = useState([])
  const [stockListDataA, setStockListA] = useState({})

  // ================================================================
  // =================stock list live data socket====================
  // ================================================================
  const [socketData, setSocketData] = useState({})


  useEffect(() => {
    socket.emit('getdata', '')
    socket.on('message', rawData => {
      try {
        const parsedData = JSON.parse(rawData)
        setSocketData(prevData => {
          // Merge the old data with the new data if new data is not 0 or null
          return {
            ...prevData,
            ...(parsedData?.feeds !== null && parsedData?.feeds !== 0
              ? parsedData.feeds || {}
              : {})
          }
        })
      } catch (error) {
        console.error('Error parsing socket data:', error)
      }
    })

    // Clean up the socket listener when the component unmounts
    return () => {
      socket.off('message')
    }
  }, [])

  const [orderData, setorderData] = useState([])

  // useEffect(() => {
  //   socket.emit('orderData', '')
  //   socket.on('orderdetail', orderData => {
  //     try {
  //       setorderData(orderData)
  //     } catch (error) {
  //       console.error('error:', error)
  //     }
  //   })
  // }, [])

  return (
    <StockListContext.Provider
      value={{
        stockListData,
        setStockList,
        socketData,
        setSocketData,
        stockListDataA,
        setStockListA,
        orderData
      }}
    >
      {children}
    </StockListContext.Provider>
  )
}
