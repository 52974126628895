import React from 'react'
import MainLayout from './MainLayout'
import { NavLink } from 'react-router-dom'
import { AiOutlinePlus } from 'react-icons/ai'
import { BsDownload } from 'react-icons/bs'

const Analytics = () => {
  return (
    <>
      <MainLayout>
        <section className='side_content_main_box'>
          <div className='headings'>
            <h2>Analytics</h2>
          </div>
          <section className='dashboard_boxes_main_section real_demo_tabs Market_News_tabs'>
            <div className='row'>
              <div className='col-12 Most_Popular_Stocks_tabs '>
                <ul className='nav nav-tabs' id='myTab' role='tablist'>
                  <li className='nav-item' role='presentation'>
                    <button
                      className='nav-link active'
                      id='marketNews-tab'
                      data-bs-toggle='tab'
                      data-bs-target='#marketNews'
                      type='button'
                      role='tab'
                      aria-controls='marketNews'
                      aria-selected='true'
                    >
                      Market News
                    </button>
                  </li>
                </ul>
              </div>
              <div className=''>
                <div className='tab-content' id='myTabContent'>
                  <div
                    className='tab-pane fade show active'
                    id='marketNews'
                    role='tabpanel'
                    aria-labelledby='marketNews-tab'
                  >
                    <div className='row'>
                      <div className='col-lg-12 col-md-12 col-sm-12'>
                        <div className='accordion' id='accordionExample'>
                          <div className='accordion-item'>
                            <h2 className='accordion-header' id='headingOne'>
                              <button
                                className='accordion-button'
                                type='button'
                                data-bs-toggle='collapse'
                                data-bs-target='#collapseOne'
                                aria-expanded='true'
                                aria-controls='collapseOne'
                              >
                                <div className='Market_News_tabs_button_main'>
                                  <img
                                    src='https://editorial.fxstreet.com/images/Macroeconomics/Countries/Europe/Eurozone_countries/Germany/reichstag-dome-in-berlin-at-dusk-26952798_XtraSmall.jpg'
                                    alt='news'
                                    className='img-fluid Market_News_thumbnail'
                                  />
                                  <div className='news_details'>
                                    <h4>
                                      German Preliminary Manufacturing PMI rises
                                      sharply to 42.3 in November vs. 41.2
                                      expected
                                    </h4>
                                    <div className='author_div'>
                                      <h5 className='author_name'>
                                        Akash CHoudhary
                                      </h5>
                                      <h5 className='news_date'>
                                        Nov 23, 09:00 GMT
                                      </h5>
                                    </div>
                                    <div className='news_tegs'>
                                      <ul>
                                        <li>EURUSD </li>
                                        <li> EUROZONE </li>
                                        <li> PMI</li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </button>
                            </h2>
                            <div
                              id='collapseOne'
                              className='accordion-collapse collapse '
                              aria-labelledby='headingOne'
                              data-bs-parent='#accordionExample'
                            >
                              <div className='accordion-body'>
                                <strong>
                                  German Preliminary Manufacturing PMI rises
                                  sharply to 42.3 in November vs. 41.2 expected
                                </strong>{' '}
                                Stock trading contains multiple associated
                                components that facilitate the process of
                                trading. Thus, there are fees involved. For
                                example, there are SEBI charges, STT, GST,
                                Depository Charges, and more. On a broker level,
                                there are brokerage fees. On Dhan there are no
                                account opening charges or AMC. Furthermore, you
                                can invest in stocks (delivery) for ₹0, while it
                                is ₹20 or 0.03% for Equity Intraday & All
                                Segment Futures. For All Segment Options, the
                                charges are ₹20 of trade value. We are
                                transparent with all charges and pricing.
                              </div>
                            </div>
                          </div>

                          <div className='accordion-item'>
                            <h2 className='accordion-header' id='headingtwo'>
                              <button
                                className='accordion-button'
                                type='button'
                                data-bs-toggle='collapse'
                                data-bs-target='#collapsetwo'
                                aria-expanded='true'
                                aria-controls='collapsetwo'
                              >
                                <div className='Market_News_tabs_button_main'>
                                  <img
                                    src='https://editorial.fxstreet.com/images/Markets/Currencies/Majors/EURUSD/euro-or-dollar-3353142_XtraSmall.jpg'
                                    alt='news'
                                    className='img-fluid Market_News_thumbnail'
                                  />
                                  <div className='news_details'>
                                    <h4>
                                      Eurozone HCOB Services PMI came in at
                                      48.2, above expectations (48.1) in
                                      November
                                    </h4>
                                    <div className='author_div'>
                                      <h5 className='author_name'>
                                        Akash CHoudhary
                                      </h5>
                                      <h5 className='news_date'>
                                        Nov 23, 09:00 GMT
                                      </h5>
                                    </div>
                                    <div className='news_tegs'>
                                      <ul>
                                        <li>EURUSD </li>
                                        <li> EUROZONE </li>
                                        <li> PMI</li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </button>
                            </h2>
                            <div
                              id='collapsetwo'
                              className='accordion-collapse collapse '
                              aria-labelledby='headingtwo'
                              data-bs-parent='#accordionExample'
                            >
                              <div className='accordion-body'>
                                <strong>
                                  What are the charges associated with stock
                                  trading on Dhan?
                                </strong>{' '}
                                Stock trading contains multiple associated
                                components that facilitate the process of
                                trading. Thus, there are fees involved. For
                                example, there are SEBI charges, STT, GST,
                                Depository Charges, and more. On a broker level,
                                there are brokerage fees. On Dhan there are no
                                account opening charges or AMC. Furthermore, you
                                can invest in stocks (delivery) for ₹0, while it
                                is ₹20 or 0.03% for Equity Intraday & All
                                Segment Futures. For All Segment Options, the
                                charges are ₹20 of trade value. We are
                                transparent with all charges and pricing.
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>
      </MainLayout>
    </>
  )
}

export default Analytics
