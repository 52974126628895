import React, { useState } from 'react'
import Sidebar from './Sidebar'
import Header from './Header'
import { IoStatsChartSharp } from 'react-icons/io5'

import { RxHamburgerMenu } from 'react-icons/rx'
import { IoMdClose } from 'react-icons/io'

const MainLayout = ({ children }) => {
  const [isToggled, setIsToggled] = useState(true)
  const toggleClass = () => {
    setIsToggled(!isToggled)
  }
  return (
    <>
      <div className='main_section'>
        <Header />
        <div className='container-fluid'>
          <section className='main__section'>
            <div className={isToggled ? 'sidebar_div' : 'sidebar_div_coll'}>
              <Sidebar />
            </div>
            <div
              className={isToggled ? 'SideContent_div' : 'SideContent_div_coll'}
            >
              <button className='menu_toggle_btn' onClick={toggleClass}>
                {!isToggled ? (
                  <h6>
                    {/* <IoMdClose className='icon' /> */}
                    <IoStatsChartSharp className='icons' /> Marketwatch
                  </h6>
                ) : (
                  <h6>
                    {/*<RxHamburgerMenu className='icon' />*/}
                    <IoStatsChartSharp className='icons' /> Marketwatch
                  </h6>
                )}
              </button>
              <button className='menu_close_btn' onClick={toggleClass}>
                <IoMdClose className='icon' />
              </button>
              {children}
            </div>
          </section>
        </div>
      </div>
    </>
  )
}

export default MainLayout
