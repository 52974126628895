import React from 'react'
const IndustryStandardPrices = () => {
  return (
    <>
      <section className='common_section features_section Most_Popular_Stocks_section Standard_Prices_section'>
        <div className='container'>
          <div className='row'>
            <div className='col-12 mb_50'>
              <div className='headings'>
                <h2>
                  Experience More at <span> Industry Standard Prices</span>
                </h2>
              </div>
            </div>

            <div className='col-lg-3 col-md-6 col-sm-12 mt-3'>
              <div className='pricing_box'>
                <div className='pricing_bg_round_main'>
                  <div className='pricing_bg_round'></div>
                  <h1>
                    ₹<span>0</span>{' '}
                  </h1>
                </div>
                <h2>For Account Opening, AMC & Platform Fees</h2>
                <p className='common_para'>
                  Open free account with zero AMC or platform fees.
                </p>
              </div>
            </div>
            <div className='col-lg-3 col-md-6 col-sm-12 mt-3'>
              <div className='pricing_box'>
                <div className='pricing_bg_round_main'>
                  <div className='pricing_bg_round'></div>
                  <h1>
                    ₹<span>0</span>{' '}
                  </h1>
                </div>
                <h2>
                  For Equity Delivery,
                  <br /> ETFs & IPO
                </h2>
                <p className='common_para'>
                  Yes, absolutely free! No charges for delivery based trades.
                </p>
              </div>
            </div>
            <div className='col-lg-3 col-md-6 col-sm-12 mt-3'>
              <div className='pricing_box'>
                <div className='pricing_bg_round_main'>
                  <div className='pricing_bg_round'></div>
                  <h1>
                    ₹<span>20</span>{' '}
                  </h1>
                </div>
                <h2>For Equity Intraday & All Segment Futures</h2>
                <p className='common_para'>
                  ₹20 or 0.03% of trade value per executed order, whichever is
                  lower.
                </p>
              </div>
            </div>
            <div className='col-lg-3 col-md-6 col-sm-12 mt-3'>
              <div className='pricing_box'>
                <div className='pricing_bg_round_main'>
                  <div className='pricing_bg_round'></div>
                  <h1>
                    ₹<span>20</span>{' '}
                  </h1>
                </div>
                <h2>For All Segment Options</h2>
                <p className='common_para'>
                  ₹20 of trade value per executed order for equity, commodity &
                  currency options.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default IndustryStandardPrices
