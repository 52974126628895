import React, { createContext, useState, useEffect } from 'react'


export const UpstoxTokenContext = createContext()

export const UpstoxTokenProvider = ({ children }) => {
  const [upstoxToken, setUpstoxToken] = useState('')
  const baseUrl = process.env.REACT_APP_API_BASE_URL



  // ================================================================
  // =================get-token====================
  // ================================================================
  useEffect(() => {
    var requestOptions = {
      method: 'GET',
      redirect: 'follow'
    }

    fetch(`${baseUrl}get-token`, requestOptions)
      .then(response => response.json())
      .then(result => {

        setUpstoxToken(result?.data?.upstox_authorization_token)
      })
      .catch(error => console.log('error', error))
  }, [])

  return (
    <UpstoxTokenContext.Provider
      value={{
        upstoxToken
      }}
    >
      {children}
    </UpstoxTokenContext.Provider>
  )
}
