import React from 'react'
import { FaLongArrowAltRight } from 'react-icons/fa'
import { NavLink } from 'react-router-dom'
const MostPopularStocks = () => {
  return (
    <>
      <section className='common_section features_section Most_Popular_Stocks_section'>
        <div className='container'>
          <div className='row'>
            <div className='col-12 mb_50'>
              <div className='headings'>
                <h2>
                  Trade and Invest in<span> Most Popular Stocks</span>
                </h2>
              </div>
            </div>
            <div className='col-12 Most_Popular_Stocks_tabs'>
              <ul className='nav nav-tabs' id='myTab' role='tablist'>
                <li className='nav-item' role='presentation'>
                  <button
                    className='nav-link active'
                    id='Nifty-tab'
                    data-bs-toggle='tab'
                    data-bs-target='#Nifty'
                    type='button'
                    role='tab'
                    aria-controls='Nifty'
                    aria-selected='true'
                  >
                    Nifty 50
                  </button>
                </li>
                <li className='nav-item' role='presentation'>
                  <button
                    className='nav-link'
                    id='Finnifty-tab'
                    data-bs-toggle='tab'
                    data-bs-target='#Finnifty'
                    type='button'
                    role='tab'
                    aria-controls='Finnifty'
                    aria-selected='false'
                  >
                    Finnifty
                  </button>
                </li>
                <li className='nav-item' role='presentation'>
                  <button
                    className='nav-link'
                    id='Nifty_bank-tab'
                    data-bs-toggle='tab'
                    data-bs-target='#Nifty_bank'
                    type='button'
                    role='tab'
                    aria-controls='Nifty_bank'
                    aria-selected='false'
                  >
                    Nifty Bank
                  </button>
                </li>
                <li className='nav-item' role='presentation'>
                  <button
                    className='nav-link'
                    id='Top_Gainers-tab'
                    data-bs-toggle='tab'
                    data-bs-target='#Top_Gainers'
                    type='button'
                    role='tab'
                    aria-controls='Top_Gainers'
                    aria-selected='false'
                  >
                    Top Gainers
                  </button>
                </li>
                <li className='nav-item' role='presentation'>
                  <button
                    className='nav-link'
                    id='Top_Losers-tab'
                    data-bs-toggle='tab'
                    data-bs-target='#Top_Losers'
                    type='button'
                    role='tab'
                    aria-controls='Top_Losers'
                    aria-selected='false'
                  >
                    Top Losers
                  </button>
                </li>
              </ul>
            </div>
            <div className=''>
              <div className='tab-content' id='myTabContent'>
                <div
                  className='tab-pane fade show active'
                  id='Nifty'
                  role='tabpanel'
                  aria-labelledby='Nifty-tab'
                >
                  <div className='row'>
                    <div className='col-lg-3 col-md-6 col-sm-12'>
                      <div className='stocks_box_main'>
                        <img
                          src='https://stock-logos.dhan.co/stock-logos/INE669E01016_vodafone_idea.png'
                          alt='stock'
                          className='img-fluid'
                        />
                        <h3>Vodafone Idea</h3>
                        <h4>
                          13.40 -0.45{' '}
                          <span className='stock_red_indicator'>
                            {' '}
                            (-3.25%){' '}
                            <img
                              src='https://dhan.co/_next/static/media/loss.e2d8b7b1.svg'
                              alt='down'
                              className='img-fluid'
                            />{' '}
                          </span>
                        </h4>
                      </div>
                    </div>
                    <div className='col-lg-3 col-md-6 col-sm-12'>
                      <div className='stocks_box_main'>
                        <img
                          src='https://stock-logos.dhan.co/stock-logos/INE009A01021_infosys.png'
                          alt='stock'
                          className='img-fluid'
                        />
                        <h3>Infosys</h3>
                        <h4>
                          13.40 -0.45{' '}
                          <span className='stock_green_indicator'>
                            {' '}
                            (-3.25%){' '}
                            <img
                              src='https://dhan.co/_next/static/media/profit.3b06dbc4.svg'
                              alt='up'
                              className='img-fluid'
                            />{' '}
                          </span>
                        </h4>
                      </div>
                    </div>
                    <div className='col-lg-3 col-md-6 col-sm-12'>
                      <div className='stocks_box_main'>
                        <img
                          src='https://stock-logos.dhan.co/stock-logos/INE669E01016_vodafone_idea.png'
                          alt='stock'
                          className='img-fluid'
                        />
                        <h3>Vodafone Idea</h3>
                        <h4>
                          13.40 -0.45{' '}
                          <span className='stock_red_indicator'>
                            {' '}
                            (-3.25%){' '}
                            <img
                              src='https://dhan.co/_next/static/media/loss.e2d8b7b1.svg'
                              alt='down'
                              className='img-fluid'
                            />{' '}
                          </span>
                        </h4>
                      </div>
                    </div>
                    <div className='col-lg-3 col-md-6 col-sm-12'>
                      <div className='stocks_box_main'>
                        <img
                          src='https://stock-logos.dhan.co/stock-logos/INE009A01021_infosys.png'
                          alt='stock'
                          className='img-fluid'
                        />
                        <h3>Infosys</h3>
                        <h4>
                          13.40 -0.45{' '}
                          <span className='stock_green_indicator'>
                            {' '}
                            (-3.25%){' '}
                            <img
                              src='https://dhan.co/_next/static/media/profit.3b06dbc4.svg'
                              alt='up'
                              className='img-fluid'
                            />{' '}
                          </span>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='tab-pane fade'
                  id='Finnifty'
                  role='tabpanel'
                  aria-labelledby='Finnifty-tab'
                >
                  <div className='row'>
                    <div className='col-lg-3 col-md-6 col-sm-12'>
                      <div className='stocks_box_main'>
                        <img
                          src='https://stock-logos.dhan.co/stock-logos/INE669E01016_vodafone_idea.png'
                          alt='stock'
                          className='img-fluid'
                        />
                        <h3>Vodafone Idea</h3>
                        <h4>
                          13.40 -0.45{' '}
                          <span className='stock_red_indicator'>
                            {' '}
                            (-3.25%){' '}
                            <img
                              src='https://dhan.co/_next/static/media/loss.e2d8b7b1.svg'
                              alt='down'
                              className='img-fluid'
                            />{' '}
                          </span>
                        </h4>
                      </div>
                    </div>
                    <div className='col-lg-3 col-md-6 col-sm-12'>
                      <div className='stocks_box_main'>
                        <img
                          src='https://stock-logos.dhan.co/stock-logos/INE009A01021_infosys.png'
                          alt='stock'
                          className='img-fluid'
                        />
                        <h3>Infosys</h3>
                        <h4>
                          13.40 -0.45{' '}
                          <span className='stock_green_indicator'>
                            {' '}
                            (-3.25%){' '}
                            <img
                              src='https://dhan.co/_next/static/media/profit.3b06dbc4.svg'
                              alt='up'
                              className='img-fluid'
                            />{' '}
                          </span>
                        </h4>
                      </div>
                    </div>
                    <div className='col-lg-3 col-md-6 col-sm-12'>
                      <div className='stocks_box_main'>
                        <img
                          src='https://stock-logos.dhan.co/stock-logos/INE669E01016_vodafone_idea.png'
                          alt='stock'
                          className='img-fluid'
                        />
                        <h3>Vodafone Idea</h3>
                        <h4>
                          13.40 -0.45{' '}
                          <span className='stock_red_indicator'>
                            {' '}
                            (-3.25%){' '}
                            <img
                              src='https://dhan.co/_next/static/media/loss.e2d8b7b1.svg'
                              alt='down'
                              className='img-fluid'
                            />{' '}
                          </span>
                        </h4>
                      </div>
                    </div>
                    <div className='col-lg-3 col-md-6 col-sm-12'>
                      <div className='stocks_box_main'>
                        <img
                          src='https://stock-logos.dhan.co/stock-logos/INE009A01021_infosys.png'
                          alt='stock'
                          className='img-fluid'
                        />
                        <h3>Infosys</h3>
                        <h4>
                          13.40 -0.45{' '}
                          <span className='stock_green_indicator'>
                            {' '}
                            (-3.25%){' '}
                            <img
                              src='https://dhan.co/_next/static/media/profit.3b06dbc4.svg'
                              alt='up'
                              className='img-fluid'
                            />{' '}
                          </span>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='tab-pane fade'
                  id='Nifty_bank'
                  role='tabpanel'
                  aria-labelledby='Nifty_bank-tab'
                >
                  <div className='row'>
                    <div className='col-lg-3 col-md-6 col-sm-12'>
                      <div className='stocks_box_main'>
                        <img
                          src='https://stock-logos.dhan.co/stock-logos/INE669E01016_vodafone_idea.png'
                          alt='stock'
                          className='img-fluid'
                        />
                        <h3>Vodafone Idea</h3>
                        <h4>
                          13.40 -0.45{' '}
                          <span className='stock_red_indicator'>
                            {' '}
                            (-3.25%){' '}
                            <img
                              src='https://dhan.co/_next/static/media/loss.e2d8b7b1.svg'
                              alt='down'
                              className='img-fluid'
                            />{' '}
                          </span>
                        </h4>
                      </div>
                    </div>
                    <div className='col-lg-3 col-md-6 col-sm-12'>
                      <div className='stocks_box_main'>
                        <img
                          src='https://stock-logos.dhan.co/stock-logos/INE009A01021_infosys.png'
                          alt='stock'
                          className='img-fluid'
                        />
                        <h3>Infosys</h3>
                        <h4>
                          13.40 -0.45{' '}
                          <span className='stock_green_indicator'>
                            {' '}
                            (-3.25%){' '}
                            <img
                              src='https://dhan.co/_next/static/media/profit.3b06dbc4.svg'
                              alt='up'
                              className='img-fluid'
                            />{' '}
                          </span>
                        </h4>
                      </div>
                    </div>
                    <div className='col-lg-3 col-md-6 col-sm-12'>
                      <div className='stocks_box_main'>
                        <img
                          src='https://stock-logos.dhan.co/stock-logos/INE669E01016_vodafone_idea.png'
                          alt='stock'
                          className='img-fluid'
                        />
                        <h3>Vodafone Idea</h3>
                        <h4>
                          13.40 -0.45{' '}
                          <span className='stock_red_indicator'>
                            {' '}
                            (-3.25%){' '}
                            <img
                              src='https://dhan.co/_next/static/media/loss.e2d8b7b1.svg'
                              alt='down'
                              className='img-fluid'
                            />{' '}
                          </span>
                        </h4>
                      </div>
                    </div>
                    <div className='col-lg-3 col-md-6 col-sm-12'>
                      <div className='stocks_box_main'>
                        <img
                          src='https://stock-logos.dhan.co/stock-logos/INE009A01021_infosys.png'
                          alt='stock'
                          className='img-fluid'
                        />
                        <h3>Infosys</h3>
                        <h4>
                          13.40 -0.45{' '}
                          <span className='stock_green_indicator'>
                            {' '}
                            (-3.25%){' '}
                            <img
                              src='https://dhan.co/_next/static/media/profit.3b06dbc4.svg'
                              alt='up'
                              className='img-fluid'
                            />{' '}
                          </span>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='tab-pane fade'
                  id='Top_Gainers'
                  role='tabpanel'
                  aria-labelledby='Top_Gainers-tab'
                >
                  <div className='row'>
                    <div className='col-lg-3 col-md-6 col-sm-12'>
                      <div className='stocks_box_main'>
                        <img
                          src='https://stock-logos.dhan.co/stock-logos/INE669E01016_vodafone_idea.png'
                          alt='stock'
                          className='img-fluid'
                        />
                        <h3>Vodafone Idea</h3>
                        <h4>
                          13.40 -0.45{' '}
                          <span className='stock_red_indicator'>
                            {' '}
                            (-3.25%){' '}
                            <img
                              src='https://dhan.co/_next/static/media/loss.e2d8b7b1.svg'
                              alt='down'
                              className='img-fluid'
                            />{' '}
                          </span>
                        </h4>
                      </div>
                    </div>
                    <div className='col-lg-3 col-md-6 col-sm-12'>
                      <div className='stocks_box_main'>
                        <img
                          src='https://stock-logos.dhan.co/stock-logos/INE009A01021_infosys.png'
                          alt='stock'
                          className='img-fluid'
                        />
                        <h3>Infosys</h3>
                        <h4>
                          13.40 -0.45{' '}
                          <span className='stock_green_indicator'>
                            {' '}
                            (-3.25%){' '}
                            <img
                              src='https://dhan.co/_next/static/media/profit.3b06dbc4.svg'
                              alt='up'
                              className='img-fluid'
                            />{' '}
                          </span>
                        </h4>
                      </div>
                    </div>
                    <div className='col-lg-3 col-md-6 col-sm-12'>
                      <div className='stocks_box_main'>
                        <img
                          src='https://stock-logos.dhan.co/stock-logos/INE669E01016_vodafone_idea.png'
                          alt='stock'
                          className='img-fluid'
                        />
                        <h3>Vodafone Idea</h3>
                        <h4>
                          13.40 -0.45{' '}
                          <span className='stock_red_indicator'>
                            {' '}
                            (-3.25%){' '}
                            <img
                              src='https://dhan.co/_next/static/media/loss.e2d8b7b1.svg'
                              alt='down'
                              className='img-fluid'
                            />{' '}
                          </span>
                        </h4>
                      </div>
                    </div>
                    <div className='col-lg-3 col-md-6 col-sm-12'>
                      <div className='stocks_box_main'>
                        <img
                          src='https://stock-logos.dhan.co/stock-logos/INE009A01021_infosys.png'
                          alt='stock'
                          className='img-fluid'
                        />
                        <h3>Infosys</h3>
                        <h4>
                          13.40 -0.45{' '}
                          <span className='stock_green_indicator'>
                            {' '}
                            (-3.25%){' '}
                            <img
                              src='https://dhan.co/_next/static/media/profit.3b06dbc4.svg'
                              alt='up'
                              className='img-fluid'
                            />{' '}
                          </span>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='tab-pane fade'
                  id='Top_Losers'
                  role='tabpanel'
                  aria-labelledby='Top_Losers-tab'
                >
                  <div className='row'>
                    <div className='col-lg-3 col-md-6 col-sm-12'>
                      <div className='stocks_box_main'>
                        <img
                          src='https://stock-logos.dhan.co/stock-logos/INE669E01016_vodafone_idea.png'
                          alt='stock'
                          className='img-fluid'
                        />
                        <h3>Vodafone Idea</h3>
                        <h4>
                          13.40 -0.45{' '}
                          <span className='stock_red_indicator'>
                            {' '}
                            (-3.25%){' '}
                            <img
                              src='https://dhan.co/_next/static/media/loss.e2d8b7b1.svg'
                              alt='down'
                              className='img-fluid'
                            />{' '}
                          </span>
                        </h4>
                      </div>
                    </div>
                    <div className='col-lg-3 col-md-6 col-sm-12'>
                      <div className='stocks_box_main'>
                        <img
                          src='https://stock-logos.dhan.co/stock-logos/INE009A01021_infosys.png'
                          alt='stock'
                          className='img-fluid'
                        />
                        <h3>Infosys</h3>
                        <h4>
                          13.40 -0.45{' '}
                          <span className='stock_green_indicator'>
                            {' '}
                            (-3.25%){' '}
                            <img
                              src='https://dhan.co/_next/static/media/profit.3b06dbc4.svg'
                              alt='up'
                              className='img-fluid'
                            />{' '}
                          </span>
                        </h4>
                      </div>
                    </div>
                    <div className='col-lg-3 col-md-6 col-sm-12'>
                      <div className='stocks_box_main'>
                        <img
                          src='https://stock-logos.dhan.co/stock-logos/INE669E01016_vodafone_idea.png'
                          alt='stock'
                          className='img-fluid'
                        />
                        <h3>Vodafone Idea</h3>
                        <h4>
                          13.40 -0.45{' '}
                          <span className='stock_red_indicator'>
                            {' '}
                            (-3.25%){' '}
                            <img
                              src='https://dhan.co/_next/static/media/loss.e2d8b7b1.svg'
                              alt='down'
                              className='img-fluid'
                            />{' '}
                          </span>
                        </h4>
                      </div>
                    </div>
                    <div className='col-lg-3 col-md-6 col-sm-12'>
                      <div className='stocks_box_main'>
                        <img
                          src='https://stock-logos.dhan.co/stock-logos/INE009A01021_infosys.png'
                          alt='stock'
                          className='img-fluid'
                        />
                        <h3>Infosys</h3>
                        <h4>
                          13.40 -0.45{' '}
                          <span className='stock_green_indicator'>
                            {' '}
                            (-3.25%){' '}
                            <img
                              src='https://dhan.co/_next/static/media/profit.3b06dbc4.svg'
                              alt='up'
                              className='img-fluid'
                            />{' '}
                          </span>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-12 mt-5 text-center'>
              <NavLink to='/login' className='yellow_btn start_now_btn'>
                Start Trading <FaLongArrowAltRight />
              </NavLink>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default MostPopularStocks
