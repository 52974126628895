import React from 'react'
import MainLayout from './MainLayout'
import { FaLock } from 'react-icons/fa'
import { NavLink } from 'react-router-dom'
const Buy = () => {
  return (
    <MainLayout>
      <section className='side_content_main_box'>
        <div className='headings'>
          <h2>Buy Stocks</h2>
        </div>
        <section className='dashboard_boxes_main_section '>
          <div className='row'>
            <div className='col-lg-4 col-md-6 col-sm-12'>
              <div className='stocks_box_main'>
                <img
                  src='https://stock-logos.dhan.co/stock-logos/INE669E01016_vodafone_idea.png'
                  alt='stock'
                  className='img-fluid'
                />
                <h3>Vodafone Idea</h3>
                <h4>
                  13.40 -0.45{' '}
                  <span className='stock_red_indicator'>
                    {' '}
                    (-3.25%){' '}
                    <img
                      src='https://dhan.co/_next/static/media/loss.e2d8b7b1.svg'
                      alt='down'
                      className='img-fluid'
                    />{' '}
                  </span>
                </h4>
              </div>
            </div>

            <div className='col-lg-4 col-md-6 col-sm-12'>
              <div className='stocks_box_main'>
                <img
                  src='https://stock-logos.dhan.co/stock-logos/INE009A01021_infosys.png'
                  alt='stock'
                  className='img-fluid'
                />
                <h3>Infosys</h3>
                <h4>
                  13.40 -0.45{' '}
                  <span className='stock_red_indicator'>
                    {' '}
                    (-3.25%){' '}
                    <img
                      src='https://dhan.co/_next/static/media/loss.e2d8b7b1.svg'
                      alt='down'
                      className='img-fluid'
                    />{' '}
                  </span>
                </h4>
              </div>
            </div>

            <div className='col-lg-4 col-md-6 col-sm-12'>
              <div className='stocks_box_main'>
                <img
                  src='https://stock-logos.dhan.co/stock-logos/INE019A01038_jsw_steel.png'
                  alt='stock'
                  className='img-fluid'
                />
                <h3>JSW Steel</h3>
                <h4>
                  13.40 -0.45{' '}
                  <span className='stock_red_indicator'>
                    {' '}
                    (-3.25%){' '}
                    <img
                      src='https://dhan.co/_next/static/media/loss.e2d8b7b1.svg'
                      alt='down'
                      className='img-fluid'
                    />{' '}
                  </span>
                </h4>
              </div>
            </div>

            {/* <div className='col-lg-4 col-md-6 col-sm-12'>
              <NavLink to='#'>
                <div className='Withdrawal_type_box '>
                  <div className='Withdrawal_type_name_div'>
                    <div className='inner'>
                      <img
                        src='https://stock-logos.dhan.co/stock-logos/INE669E01016_vodafone_idea.png'
                        alt='withdrawal-method'
                      />
                      <h3>Vodafone Idea</h3>
                    </div>
                    <div className='method_disable_lock d-none'>
                      <FaLock className='icon' />
                    </div>
                  </div>
                  <div className='payment_method_details'>
                    <ul>
                      <li>
                        <span>Processing time Instant -</span> 30 minutes
                      </li>
                      <li>
                        <span>Fees -</span> 0
                      </li>
                      <li>
                        <span>Limit -</span>10 - 15,000 USD
                      </li>
                    </ul>
                  </div>
                </div>
              </NavLink>
            </div> */}
          </div>
        </section>
      </section>
    </MainLayout>
  )
}

export default Buy
