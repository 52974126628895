import { useEffect } from 'react'
import './App.css'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.min.js'
import './Components/Assets/style.css'
import './Components/Assets/login.css'
import './Components/Assets/dashboard.css'
import './Components/Assets/responsive.css'
import { Routes, Route, useLocation, Navigate } from 'react-router-dom'
import Home from './Components/Home/Home.jsx'
import Login from './Components/Login/Login.jsx'
import Register from './Components/Login/Register.jsx'
import MainLayout from './Components/Dashboard/MainLayout.jsx'
import MyAccounts from './Components/Dashboard/MyAccounts.jsx'
import Sell from './Components/Dashboard/Sell.jsx'
import Buy from './Components/Dashboard/Buy.jsx'
import OrderHistory from './Components/Dashboard/OrderHistory.jsx'
import Analytics from './Components/Dashboard/Analytics.jsx'
import Setting from './Components/Dashboard/Setting.jsx'
import Performance from './Components/Dashboard/Performance.jsx'
import Watchlist from './Components/Dashboard/Watchlist.jsx'

import Charts from './Components/Dashboard/Charts.jsx'
import 'react-toastify/dist/ReactToastify.css'
import { UserProvider } from './Components/Dashboard/UserContext.js'
import TransactionHistory from './Components/Dashboard/TransactionHistory.jsx'
import Holdings from './Components/Dashboard/Holdings.jsx'
import { StockListProvider } from './Components/Dashboard/StockListContext.js'
import { UpstoxTokenProvider } from './Components/Dashboard/UpstoxTokenContext.js'


function App() {
  const { pathname } = useLocation()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])
  const token = localStorage.getItem('accesToken')

  return (
    <>
      <>
        <UserProvider>
          <UpstoxTokenProvider>
            <StockListProvider>
              {!token ? (
                <>
                  <Routes>
                    <Route path='/' element={<Home />} />
                    <Route path='/login' element={<Login />} />
                    <Route path='/register' element={<Register />} />
                    <Route path='*' element={<Navigate to='/login' />} />
                  </Routes>
                </>
              ) : (
                <>
                  <Routes>
                    <Route index element={<Home />} />
                    <Route path='/login' element={<Login />} />

                    <Route path='/user' element={<MainLayout />} />
                    <Route path='/my-account' element={<MyAccounts />} />
                    <Route path='/sell' element={<Sell />} />
                    <Route path='/buy' element={<Buy />} />
                    <Route path='/orders' element={<OrderHistory />} />
                    <Route path='/transaction' element={<TransactionHistory />} />
                    <Route path='/analytics' element={<Analytics />} />
                    <Route path='/settings' element={<Setting />} />
                    <Route path='/performance' element={<Performance />} />
                    <Route path='/watchlist' element={<Watchlist />} />
                    <Route
                      path='/charts/:stockName/:companyName'
                      element={<Charts />}
                    />
                    <Route path='/charts' element={<Charts />} />
                    <Route path='/holding' element={<Holdings />} />
                  </Routes>
                </>
              )}
            </StockListProvider>
          </UpstoxTokenProvider>
        </UserProvider>
      </>
    </>
  )
}

export default App
