import React, { useEffect, useState } from 'react'
import proto from './LivePrice.proto'
import { Buffer } from 'buffer'
const protobuf = require('protobufjs')

let protobufRoot = null
const initProtobuf = async () => {
  protobufRoot = await protobuf.load(proto)
  console.log('Protobuf part initialization complete')
}

// Function to get WebSocket URL
const getUrl = async token => {
  const apiUrl = 'https://api-v2.upstox.com/feed/market-data-feed/authorize'
  let headers = {
    'Content-type': 'application/json',
    Authorization:
      'Bearer eyJ0eXAiOiJKV1QiLCJrZXlfaWQiOiJza192MS4wIiwiYWxnIjoiSFMyNTYifQ.eyJzdWIiOiIyTEIyOUsiLCJqdGkiOiI2NWNkYzQxYWVhOWNhZTZmNmI0Mjc5MGQiLCJpc011bHRpQ2xpZW50IjpmYWxzZSwiaXNBY3RpdmUiOnRydWUsInNjb3BlIjpbImludGVyYWN0aXZlIiwiaGlzdG9yaWNhbCJdLCJpYXQiOjE3MDc5ODM4OTgsImlzcyI6InVkYXBpLWdhdGV3YXktc2VydmljZSIsImV4cCI6MTcwODAzNDQwMH0.41IngX-EBGp1vmKGKYc4-tzJaZ6cqtTbRxsqgfxFl00'
  }
  const response = await fetch(apiUrl, {
    method: 'GET',
    headers: headers
  })
  if (!response.ok) {
    throw new Error('Network response was not ok')
  }
  const res = await response.json()
  return res.data.authorizedRedirectUri
}

// Helper functions for handling Blob and ArrayBuffer
const blobToArrayBuffer = async blob => {
  if ('arrayBuffer' in blob) return await blob.arrayBuffer()
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = () => resolve(reader.result)
    reader.onerror = () => reject()
    reader.readAsArrayBuffer(blob)
  })
}

// Decode Protobuf messages
const decodeProfobuf = buffer => {
  if (!protobufRoot) {
    console.warn('Protobuf part not initialized yet!')
    return null
  }
  const FeedResponse = protobufRoot.lookupType(
    'com.upstox.marketdatafeeder.rpc.proto.FeedResponse'
  )
  return FeedResponse.decode(buffer)
}

export function getLiveData(stockName, callback) {
  const connectWebSocket = async () => {
    try {
      const wsUrl = await getUrl(stockName)
      const ws = new WebSocket(wsUrl)

      ws.onopen = () => {
        console.log('Connected')
        const data = {
          guid: 'someguid',
          method: 'sub',
          data: {
            mode: 'full',
            instrumentKeys: [stockName]
          }
        }
        ws.send(Buffer.from(JSON.stringify(data)))
      }

      ws.onclose = () => {
        console.log('Disconnected')
      }

      ws.onmessage = async event => {
        const arrayBuffer = await blobToArrayBuffer(event.data)
        let buffer = Buffer.from(arrayBuffer)
        let response = decodeProfobuf(buffer)
        callback(response, null)
      }

      ws.onerror = error => {
        callback(null, error)
        console.log('WebSocket error:', error)
      }

      return () => ws.close()
    } catch (error) {
      callback(null, error)
      console.error('WebSocket connection error:', error)
    }
  }
  initProtobuf()
  connectWebSocket(stockName)
}
