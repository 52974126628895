import React from 'react'
const FAQ = () => {
  return (
    <>
      <section className='common_section features_section  faq_main_section'>
        <div className='container'>
          <div className='row'>
            <div className='col-12 mb_50'>
              <div className='headings'>
                <h2>Frequently Asked Questions</h2>
              </div>
            </div>
            <div className='col-12'>
              <div className='accordion' id='accordionExample'>
                <div className='accordion-item'>
                  <h2 className='accordion-header' id='headingOne'>
                    <button
                      className='accordion-button'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapseOne'
                      aria-expanded='true'
                      aria-controls='collapseOne'
                    >
                      1. What is Wells Capital ?
                    </button>
                  </h2>
                  <div
                    id='collapseOne'
                    className='accordion-collapse collapse show'
                    aria-labelledby='headingOne'
                    data-bs-parent='#accordionExample'
                  >
                    <div className='accordion-body'>
                      Wells Capital is a platform dedicated to trading Indian
                      stocks, providing users with a seamless and user-friendly
                      experience to buy and sell stocks listed on Indian stock
                      exchanges.
                    </div>
                  </div>
                </div>
                <div className='accordion-item'>
                  <h2 className='accordion-header' id='headingTwo'>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapseTwo'
                      aria-expanded='false'
                      aria-controls='collapseTwo'
                    >
                      2. How do I get started with Wells Capital ?
                    </button>
                  </h2>
                  <div
                    id='collapseTwo'
                    className='accordion-collapse collapse'
                    aria-labelledby='headingTwo'
                    data-bs-parent='#accordionExample'
                  >
                    <div className='accordion-body'>
                      To begin trading on Wells Capital , sign up for an account
                      on our website, complete the verification process, and
                      then fund your account to start investing in Indian
                      stocks.
                    </div>
                  </div>
                </div>
                <div className='accordion-item'>
                  <h2 className='accordion-header' id='headingThree'>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapseThree'
                      aria-expanded='false'
                      aria-controls='collapseThree'
                    >
                      3. Which Indian stock exchanges can I trade on through
                      Wells Capital ?
                    </button>
                  </h2>
                  <div
                    id='collapseThree'
                    className='accordion-collapse collapse'
                    aria-labelledby='headingThree'
                    data-bs-parent='#accordionExample'
                  >
                    <div className='accordion-body'>
                      Wells Capital allows trading on major Indian stock
                      exchanges such as the National Stock Exchange (NSE) and
                      the Bombay Stock Exchange (BSE).
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default FAQ
