import React from 'react'
import { NavLink } from 'react-router-dom'

const Header = () => {
  const token = localStorage.getItem('accesToken')

  return (
    <>
      <nav className='main_header navbar navbar-expand-lg  sticky-top'>
        <div className='container'>
          <NavLink className='navbar-brand' to='/'>
            <img src='./logo.JPG' alt='logo' className='logo' />
          </NavLink>
          <button
            className='navbar-toggler'
            type='button'
            data-bs-toggle='collapse'
            data-bs-target='#navbarSupportedContent'
            aria-controls='navbarSupportedContent'
            aria-expanded='false'
            aria-label='Toggle navigation'
          >
            <span className='navbar-toggler-icon'></span>
          </button>
          <div className='collapse navbar-collapse' id='navbarSupportedContent'>
            <ul className='navbar-nav me-auto mb-2 mb-lg-0'>
              <li className='nav-item'>
                <NavLink className='nav-link active' aria-current='page' to='/'>
                  Home
                </NavLink>
              </li>

              <li className='nav-item dropdown'>
                <a
                  className='nav-link dropdown-toggle'
                  href='#'
                  id='navbarDropdown'
                  role='button'
                  data-bs-toggle='dropdown'
                  aria-expanded='false'
                >
                  Investments
                </a>
                <ul className='dropdown-menu' aria-labelledby='navbarDropdown'>
                  <li>
                    <a className='dropdown-item' href='#'>
                      Stocks
                    </a>
                  </li>
                </ul>
              </li>
              <li className='nav-item'>
                <NavLink className='nav-link' to='login'>
                  Pricing
                </NavLink>
              </li>
              <li className='nav-item'>
                <NavLink className='nav-link' to='login'>
                  Customer Support
                </NavLink>
              </li>
            </ul>
            {!token ? (
              <div className='d-flex'>
                <NavLink to='/login' className='light_btn'>
                  Login
                </NavLink>
                <NavLink to='/register' className='yellow_btn'>
                  Open Account
                </NavLink>
              </div>
            ) : (
              <NavLink to='/my-account' className='dashboard_btn light_btn'>
                Dashboard
              </NavLink>
            )}
          </div>
        </div>
      </nav>
    </>
  )
}

export default Header
