import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { Modal, Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
const Withdrawal = ({ showModalWithdrawal, handleCloseWithdrawal }) => {
  const [withrawalAmount, setWithrawalAmount] = useState('')
  const [withrawalRemark, setWithrawalRemark] = useState('')
  const token = localStorage.getItem('accesToken')
  const baseUrl = process.env.REACT_APP_API_BASE_URL
  // ==========withdrawal request==============
  const navigate = useNavigate()

  const withdrawalRequest = e => {
    e.preventDefault()
    var myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${token}`)

    var formdata = new FormData()
    formdata.append('amount', withrawalAmount)
    formdata.append('content', withrawalRemark)

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    }

    fetch(`${baseUrl}withdraw-request`, requestOptions)
      .then(response => {
        if (response.status === 401) {
          navigate('/login')
          localStorage.removeItem('accesToken')
        }
        return response.json()
      })
      .then(result => {
        // console.log(result)
        if (result.status) {
          toast.success(result.message, {
            autoClose: 1000
          })
          handleCloseWithdrawal()
          setWithrawalAmount('')
          setWithrawalRemark('')
          setTimeout(() => {
            window.location.reload()
          }, 1000)
        } else {
          toast.error(result.message, {
            autoClose: 2000
          })
        }
      })
      .catch(error => console.log('error', error))
  }

  return (
    <div>
      <Modal show={showModalWithdrawal} onHide={handleCloseWithdrawal} centered>
        <form onSubmit={withdrawalRequest}>
          <Modal.Header closeButton>
            <Modal.Title>Amount Withdrawal</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {' '}
            <form>
              <div className='mb-3'>
                <label for='amount' className='form-label'>
                  Enter Amount
                </label>
                <input
                  type='number'
                  className='form-control'
                  id='amount'
                  placeholder='Enter amount here'
                  value={withrawalAmount}
                  onChange={e => setWithrawalAmount(e.target.value)}
                />
              </div>
              <div className='mb-3'>
                <label for='remark' className='form-label'>
                  Remark
                </label>
                <input
                  type='text'
                  className='form-control'
                  id='remark'
                  placeholder='Enter remark here'
                  value={withrawalRemark}
                  onChange={e => setWithrawalRemark(e.target.value)}
                />
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={handleCloseWithdrawal}>
              Close
            </Button>
            <Button variant='primary' type='submit'>
              Withdrawal
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  )
}

export default Withdrawal
