import React from 'react'
import { NavLink } from 'react-router-dom'

const FavouriteSector = () => {
  return (
    <>
      <section className='common_section text-center Favourite_Sector_section'>
        <div className='container'>
          <div className='row'>
            <div className='col-12 mb_50'>
              <div className='headings'>
                <h2 className='text-light'>
                  Trade & Invest in Stocks from
                  <span> Your Favourite Sector</span>
                </h2>
              </div>
            </div>

            <div className='col-6 col-lg-3 col-md-4 col-sm-6'>
              <NavLink to='#'>
                <div className='Favourite_Sector_box'>
                  <img
                    src='https://stock-logos.dhan.co/static-openweb/invest.svg'
                    alt='sector'
                    className='img-fluid'
                  />
                  <h4>Finance Stocks</h4>
                </div>
              </NavLink>
            </div>

            <div className='col-6 col-lg-3 col-md-4 col-sm-6'>
              <NavLink to='#'>
                <div className='Favourite_Sector_box'>
                  <img
                    src='https://stock-logos.dhan.co/static-openweb/invest.svg'
                    alt='sector'
                    className='img-fluid'
                  />
                  <h4>Bank Stocks</h4>
                </div>
              </NavLink>
            </div>

            <div className='col-6 col-lg-3 col-md-4 col-sm-6'>
              <NavLink to='#'>
                <div className='Favourite_Sector_box'>
                  <img
                    src='https://stock-logos.dhan.co/static-openweb/invest.svg'
                    alt='sector'
                    className='img-fluid'
                  />
                  <h4>IT Stocks</h4>
                </div>
              </NavLink>
            </div>

            <div className='col-6 col-lg-3 col-md-4 col-sm-6'>
              <NavLink to='#'>
                <div className='Favourite_Sector_box'>
                  <img
                    src='https://stock-logos.dhan.co/static-openweb/invest.svg'
                    alt='sector'
                    className='img-fluid'
                  />
                  <h4>Auto Stocks</h4>
                </div>
              </NavLink>
            </div>

            <div className='col-6 col-lg-3 col-md-4 col-sm-6'>
              <NavLink to='#'>
                <div className='Favourite_Sector_box'>
                  <img
                    src='https://stock-logos.dhan.co/static-openweb/invest.svg'
                    alt='sector'
                    className='img-fluid'
                  />
                  <h4>Power Stocks</h4>
                </div>
              </NavLink>
            </div>
            <div className='col-6 col-lg-3 col-md-4 col-sm-6'>
              <NavLink to='#'>
                <div className='Favourite_Sector_box'>
                  <img
                    src='https://stock-logos.dhan.co/static-openweb/invest.svg'
                    alt='sector'
                    className='img-fluid'
                  />
                  <h4>Pharma Stocks</h4>
                </div>
              </NavLink>
            </div>
            <div className='col-6 col-lg-3 col-md-4 col-sm-6'>
              <NavLink to='#'>
                <div className='Favourite_Sector_box'>
                  <img
                    src='https://stock-logos.dhan.co/static-openweb/invest.svg'
                    alt='sector'
                    className='img-fluid'
                  />
                  <h4>Health Stocks</h4>
                </div>
              </NavLink>
            </div>
            <div className='col-6 col-lg-3 col-md-4 col-sm-6'>
              <NavLink to='#'>
                <div className='Favourite_Sector_box'>
                  <img
                    src='https://stock-logos.dhan.co/static-openweb/invest.svg'
                    alt='sector'
                    className='img-fluid'
                  />
                  <h4>FMCG Stocks</h4>
                </div>
              </NavLink>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default FavouriteSector
