import React from 'react'
import { NavLink } from 'react-router-dom'
import { FaFacebookF, FaTelegramPlane } from 'react-icons/fa'
import { FaXTwitter } from 'react-icons/fa6'

import { BsInstagram } from 'react-icons/bs'
const Footer = () => {
  return (
    <>
      <section className='footer_main_section'>
        <div className='container'>
          <div className='row'>
            <div className='col-6 col-lg-2 col-md-6 col-sm-12'>
              <div className='footer_box_main'>
                <img src='logo.JPG' alt='logo' className='img-fluid logo' />
                <h4>Follow us on</h4>
                <div className='social_icons_div'>
                  <NavLink to='#'>
                    <FaFacebookF className='social_icon' />
                  </NavLink>
                  <NavLink to='#'>
                    <BsInstagram className='social_icon' />
                  </NavLink>
                  <NavLink to='#'>
                    <FaXTwitter className='social_icon' />
                  </NavLink>
                  <NavLink to='#'>
                    <FaTelegramPlane className='social_icon' />
                  </NavLink>
                </div>
              </div>
            </div>
            <div className='col-6 col-lg-3 col-md-6 col-sm-12'>
              <div className='footer_box_main'>
                <h3>Customer Service</h3>
                <NavLink to='#'>Knowledge Base</NavLink> <br />
                <NavLink to='#'>Customer Support</NavLink> <br />
                <NavLink to='#'>Safety and Security </NavLink> <br />
                <NavLink to='#'>Grievance Redressal </NavLink> <br />
                <NavLink to='#'>Advisory for Investors</NavLink> <br />
              </div>
            </div>
            <div className='col-6 col-lg-3 col-md-6 col-sm-12'>
              <div className='footer_box_main'>
                <h3>Quick Links</h3>
                <NavLink to='#'>Open Demat Account</NavLink> <br />
                <NavLink to='#'>HUF Demat Account</NavLink> <br />
                <NavLink to='#'>Corporate Demat Account </NavLink> <br />
                <NavLink to='#'>Download Centre </NavLink> <br />
                <NavLink to='#'>Stock Market Holidays</NavLink> <br />
                <NavLink to='#'>Refer and Earn</NavLink> <br />
                <NavLink to='#'>Regulatory Information</NavLink> <br />
              </div>
            </div>
            <div className='col-6 col-lg-2 col-md-6 col-sm-12'>
              <div className='footer_box_main'>
                <h3>Partner with us</h3>
                <NavLink to='#'>Authorised Partner</NavLink> <br />
                <NavLink to='#'>Partner Dashboard</NavLink> <br />
                <NavLink to='#'>Code of Conduct </NavLink> <br />
              </div>
            </div>
            <div className='col-6 col-lg-2 col-md-6 col-sm-12'>
              <div className='footer_box_main'>
                <h3>Company</h3>
                <NavLink to='#'>About Us</NavLink> <br />
                <NavLink to='#'>Blog</NavLink> <br />
                <NavLink to='#'>Pricing </NavLink> <br />
                <NavLink to='#'>Careers </NavLink> <br />
                <NavLink to='#'>Contact Us </NavLink> <br />
              </div>
            </div>
          </div>

          <div className='row'>
            <div className='col-12'>
              <div className='footer_copyright_div'>
                <p>© 2023 All rights reserved.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Footer
