import React from 'react'
import MainLayout from './MainLayout'

const Performance = () => {
  return (
    <>
      <MainLayout>
        <section className='side_content_main_box'>
          <div className='headings'>
            <h2>Performance</h2>
          </div>
          <section className='dashboard_boxes_main_section real_demo_tabs'>
            <div className='row'>
              <div className='col-12 Most_Popular_Stocks_tabs '>
                <ul className='nav nav-tabs' id='myTab' role='tablist'>
                  <li className='nav-item' role='presentation'>
                    <button
                      className='nav-link active'
                      id='Summary-tab'
                      data-bs-toggle='tab'
                      data-bs-target='#Summary'
                      type='button'
                      role='tab'
                      aria-controls='Summary'
                      aria-selected='true'
                    >
                      Summary
                    </button>
                  </li>
                  <li className='nav-item' role='presentation'>
                    <button
                      className='nav-link'
                      id='orderHistoty-tab'
                      data-bs-toggle='tab'
                      data-bs-target='#orderHistoty'
                      type='button'
                      role='tab'
                      aria-controls='orderHistoty'
                      aria-selected='false'
                    >
                      History of Orders
                    </button>
                  </li>
                </ul>
              </div>
              <div className=''>
                <div className='tab-content' id='myTabContent'>
                  <div
                    className='tab-pane fade show active'
                    id='Summary'
                    role='tabpanel'
                    aria-labelledby='Summary-tab'
                  >
                    <div className='row'>
                      <div className='col-12'>
                        <div>
                          {' '}
                          <div className='small_headings '>
                            <h4>Account</h4>
                          </div>
                          {/* filters */}
                          <div className='account_filter_div transaction_filter_div'>
                            {/* account type */}
                            <div>
                              <select
                                className='form-select account_filter_btn'
                                aria-label='Default select example'
                              >
                                <option selected> Select Account Type</option>
                                <option value='All Account'>All Account</option>
                              </select>
                            </div>

                            {/* date filter */}
                            <div>
                              <select
                                className='form-select account_filter_btn'
                                aria-label='Default select example'
                              >
                                <option selected>Select a Time Period</option>
                                <option value='Last 3 days'>Last 3 days</option>
                                <option value='Last 7 days'>Last 7 days</option>
                                <option value='Last 30 days'>
                                  Last 30 days
                                </option>
                                <option value='Last 3 months'>
                                  Last 3 months
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col-lg-3 col-md-4 col-sm-12'>
                        <div className='performance_small_box'>
                          <h4>Net Profit</h4>
                          <h2>₹ 0</h2>
                        </div>
                      </div>
                      <div className='col-lg-3 col-md-4 col-sm-12'>
                        <div className='performance_small_box'>
                          <h4>Closed orders</h4>
                          <h2>₹ 0 </h2>
                        </div>
                      </div>
                      <div className='col-lg-3 col-md-4 col-sm-12'>
                        <div className='performance_small_box'>
                          <h4>Trading volume</h4>
                          <h2>₹ 0</h2>
                        </div>
                      </div>
                      <div className='col-lg-3 col-md-4 col-sm-12'>
                        <div className='performance_small_box'>
                          <h4>Equity</h4>
                          <h2>₹ 0</h2>
                        </div>
                      </div>
                      <div className='col-lg-3 col-md-4 col-sm-12'>
                        <div className='performance_small_box'>
                          <h4>Profit</h4>
                          <h2>₹ 0</h2>
                        </div>
                      </div>
                      <div className='col-lg-3 col-md-4 col-sm-12'>
                        <div className='performance_small_box'>
                          <h4>Profitable</h4>
                          <h2>₹ 0</h2>
                        </div>
                      </div>
                      <div className='col-lg-3 col-md-4 col-sm-12'>
                        <div className='performance_small_box'>
                          <h4>Lifetime</h4>
                          <h2>₹ 0</h2>
                        </div>
                      </div>
                      <div className='col-lg-3 col-md-4 col-sm-12'>
                        <div className='performance_small_box'>
                          <h4>Current</h4>
                          <h2>₹ 0</h2>
                        </div>
                      </div>
                      <div className='col-lg-3 col-md-4 col-sm-12'>
                        <div className='performance_small_box'>
                          <h4>Loss</h4>
                          <h2>₹ 0</h2>
                        </div>
                      </div>
                      <div className='col-lg-3 col-md-4 col-sm-12'>
                        <div className='performance_small_box'>
                          <h4>Unprofitable</h4>
                          <h2>₹ 0</h2>
                        </div>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-12'>
                        <div className='small_headings mt-4'>
                          <h4>Charts</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className='tab-pane fade'
                    id='orderHistoty'
                    role='tabpanel'
                    aria-labelledby='orderHistoty-tab'
                  >
                    <div className='row'>
                      <div className='col-12'>
                        <div>
                          {' '}
                          <div className='small_headings '>
                            <h4>Account</h4>
                          </div>
                          {/* filters */}
                          <div className='account_filter_div transaction_filter_div'>
                            {/* date filter */}
                            <div>
                              <select
                                className='form-select account_filter_btn'
                                aria-label='Default select example'
                              >
                                <option selected>Order TYpe</option>
                                <option value='Open order'>Open order</option>
                                <option value='Closed order'>
                                  Closed order
                                </option>
                              </select>
                            </div>
                            {/* account type */}
                            <div>
                              <select
                                className='form-select account_filter_btn'
                                aria-label='Default select example'
                              >
                                <option selected> Select Account Type</option>
                                <option value='All Account'>All Account</option>
                              </select>
                            </div>

                            {/* date filter */}
                            <div>
                              <select
                                className='form-select account_filter_btn'
                                aria-label='Default select example'
                              >
                                <option selected>Select a Time Period</option>
                                <option value='Last 3 days'>Last 3 days</option>
                                <option value='Last 7 days'>Last 7 days</option>
                                <option value='Last 30 days'>
                                  Last 30 days
                                </option>
                                <option value='Last 3 months'>
                                  Last 3 months
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-12'>
                        <div className='table-responsive common_table'>
                          <table className='table table-striped table-hover  align-middle table-striped'>
                            <thead className=''>
                              <tr>
                                <th scope='col'>Symbol</th>
                                <th scope='col'>Type</th>
                                <th scope='col'>Opening Time (UTC)</th>
                                <th scope='col'>Lots</th>
                                <th scope='col'>Opening price</th>
                                <th scope='col'>Position ID</th>
                                <th scope='col'>Commission₹ )</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <img
                                    src='https://my.exness.com/st/icons/flags/VN.svg'
                                    alt='country'
                                    className='img-fluid'
                                  />{' '}
                                  ₹ /JPY
                                </td>
                                <td>
                                  <span className='table_green_status'>
                                    {' '}
                                    Buy
                                  </span>
                                </td>
                                <td>$24 Nov 10:19:58</td>

                                <td>0.01</td>
                                <td>149.572</td>
                                <td>319531526</td>
                                <td>0</td>
                              </tr>
                              <tr>
                                <td>
                                  <img
                                    src='https://my.exness.com/st/icons/flags/VN.svg'
                                    alt='country'
                                    className='img-fluid'
                                  />{' '}
                                  XA₹ U
                                </td>
                                <td>
                                  <span className='table_red_status'>
                                    {' '}
                                    Sell
                                  </span>
                                </td>
                                <td>$24 Nov 10:19:58</td>

                                <td>0.01</td>
                                <td>149.572</td>
                                <td>319531526</td>
                                <td>0</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>
      </MainLayout>
    </>
  )
}

export default Performance
