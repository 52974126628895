import React, { useContext, useEffect, useState } from 'react'
import { MdOutlineSearch } from 'react-icons/md'
import { IoStatsChartSharp } from 'react-icons/io5'
import { AiOutlineDelete, AiOutlineStock } from 'react-icons/ai'
import { GoPin } from 'react-icons/go'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { NavLink } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import { LiaStopwatchSolid } from 'react-icons/lia'
import { UserContext } from './UserContext'
import { StockListContext } from './StockListContext'
import { UpstoxTokenContext } from './UpstoxTokenContext'



const StcoksList = () => {
  const token = localStorage.getItem('accesToken')
  const baseUrl = process.env.REACT_APP_API_BASE_URL
  const { userProfileData, setUserProfileData } = useContext(UserContext)
  const { socketData } = useContext(StockListContext)
  const { upstoxToken } = useContext(UpstoxTokenContext)


  // console.log('socket Data is ', socketData)

  // const [stockListData, setStockListData] = useState([])
  const [watchListStock, setwatchListStock] = useState([])

  // const [loading, setLoading] = useState(true)
  const [watchListLoading, setWatchListLoading] = useState(true)
  const [error, setError] = useState(false)
  const [selectedStock, setSelectedStock] = useState('')
  const [stockBuyQTY, setStockBuyQTY] = useState('1')
  const [stockBuyPrice, setStockBuyPrice] = useState('')
  const [stockBuyTriggerPrice, setStockBuyTriggerPrice] = useState('')
  const [stockBuyMarketLimt, setStockBuyMarketLimt] = useState('market')
  // const [stockBuySLM, setStockBuySLM] = useState('')
  // const [stockBuystoploss, setStockBuystoploss] = useState('')
  // const [stockBuyTarget, setStockBuyTarget] = useState('')
  const [stockNameList, setStockNameList] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  // const [upstoxToken, setUpstoxToken] = useState('')

  // stock sell hooks
  const [stockSellQTY, setstockSellQTY] = useState('1')
  const [stockSellPrice, setstockSellPrice] = useState('')
  const [stockSellTriggerPrice, setstockSellTriggerPrice] = useState('')
  const [stockSellMarketLimit, setstockSellMarketLimit] = useState('market')
  // const [stockSellSLtype, setstockSellSLtype] = useState('')
  const [stockFullname, setStockFullname] = useState('')
  const [stockSearchData, setStockSearchData] = useState('')



  const [showErrorModal, setShowErrorModal] = useState(false);

  const handleCloseError = () => setShowErrorModal(false);
  const handleShowError = () => setShowErrorModal(true);

  // stock buy modal
  const [showBuy, setShowBuy] = useState(false)
  const handleCloseBuy = () => {
    setShowBuy(false)
    setStockSearchData('')
    setStockBuyMarketLimt('market')
  }
  // const handleShowBuy = () => setShowBuy(true)
  const [lastPrice, setlastPrice] = useState('')
  const handleShowBuy = (displayKey, ltpValue, ltpValue2, keyIs, stockInfo) => {
    // marketQuote()
    setShowBuy(true)
    setSelectedStock(stockInfo?.company_name)
    setStockBuyPrice(ltpValue || ltpValue2 || stockSearchData?.last_price)
    setlastPrice(ltpValue || ltpValue2 || stockSearchData.last_price)
    setStockFullname(stockInfo?.stock_name)
  }

  // show hide more detail button in buy stock modal
  const [showdetailsBuy, setShowdetailsBuy] = useState(false)
  const toggledetailsBuy = () => {
    setShowdetailsBuy(!showdetailsBuy)
  }
  // ========stoploss checkbox========
  const [isCheckedstoploss, setIsCheckedstoploss] = useState(false)
  const handleCheckboxChange = () => {
    setIsCheckedstoploss(!isCheckedstoploss)
  }

  // ========taregt checkbox========
  const [isCheckedtaregt, setIsCheckedtaregt] = useState(false)
  const handleCheckboxChangetaregt = () => {
    setIsCheckedtaregt(!isCheckedtaregt)
  }

  // stock sell modal
  const [showsell, setShowsell] = useState(false)
  const handleClosesell = () => setShowsell(false)
  // const handleShowsell = () => setShowsell(true)

  const handleShowsell = (
    displayKey,
    ltpValue,
    ltpValue2,
    keyIs,
    stockInfo
  ) => {
    setShowsell(true)
    // setSelectedStock(displayKey)
    setSelectedStock(stockInfo?.company_name)

    setstockSellPrice(ltpValue || ltpValue2)
    setlastPrice(ltpValue || ltpValue2)

    setStockFullname(stockInfo?.stock_name)
    // marketQuote()
  }
  // show hide more detail button in sell stock modal
  const [showdetailsSell, setShowdetailsSell] = useState(false)
  const toggledetailsSell = () => {
    setShowdetailsSell(!showdetailsSell)
  }

  // ========stoploss sell checkbox========
  const [isCheckedstoplossSell, setIsCheckedstoplossSell] = useState(false)
  const handleCheckboxChangeSell = () => {
    setIsCheckedstoplossSell(!isCheckedstoplossSell)
  }

  // ========taregt checkbox========
  const [isCheckedtaregtSell, setIsCheckedtaregtSell] = useState(false)
  const handleCheckboxChangetaregtSell = () => {
    setIsCheckedtaregtSell(!isCheckedtaregtSell)
  }

  const [selectedPage, setSelectedPage] = useState(currentPage)
  const pageNumbers = Array.from(
    { length: totalPages },
    (_, index) => index + 1
  )

  const [searchQuery, setSearchQuery] = useState('')

  const handleSearch = event => {
    setSearchQuery(event.target.value)
    setCurrentPage(1)
    setSelectedPage(1)
  }

  const [selectedValue, setSelectedValue] = useState('1')

  const handleRadioChange = event => {
    setSelectedValue(event.target.value)
    // console.log('Selected value:', event.target.value)
  }

  // Function to handle deletion of a stock item
  const handleDelete = symbolToDelete => {
    console.log('Current Stock List:', stockNameList)

    const updatedStockList = stockNameList.filter(
      stock => stock.symbol !== symbolToDelete
    )

    console.log('Updated Stock List:', updatedStockList)

    setStockNameList([...updatedStockList])
  }
  // ================================================================
  // =========================buy stock API=========================
  // ================================================================

  const stockBuy = e => {
    e.preventDefault()

    var myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${token}`)

    var formdata = new FormData()
    formdata.append('stock_name', selectedStock)
    formdata.append('stock_full_name', stockFullname)
    formdata.append('quantity', stockBuyQTY)
    formdata.append(
      'price',
      stockBuyPrice || stockSearchData?.last_price.toFixed(2)
    )
    formdata.append('tiggere_price', stockBuyTriggerPrice)
    formdata.append('buy_sell_type', 'buy')
    formdata.append('market_limit_type', stockBuyMarketLimt)
    formdata.append('leverage', selectedValue)

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    }

    fetch(`${baseUrl}stock-buy`, requestOptions)
      .then(response => response.json())
      .then(result => {
        // console.log(result)
        if (result.status) {
          toast.success(result.message, {
            autoClose: 1000
          })
          setTimeout(() => {
            window.location.reload()
          }, 1000)
          handleCloseBuy()
        } else {
          toast.error(result.message, {
            autoClose: 2000
          })
        }
      })
      .catch(error => console.log('error', error))
  }

  // ================================================================
  // =========================Sell stock API=========================
  // ================================================================
  const stockSell = e => {
    e.preventDefault()
    var myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${token}`)

    var formdata = new FormData()
    formdata.append('stock_name', selectedStock)
    formdata.append('stock_full_name', stockFullname)
    formdata.append('quantity', stockSellQTY)
    formdata.append(
      'price',
      stockSellPrice || stockSearchData?.last_price.toFixed(2)
    )
    formdata.append('tiggere_price', stockSellTriggerPrice)
    formdata.append('buy_sell_type', 'sell')
    formdata.append('market_limit_type', stockSellMarketLimit)
    // formdata.append('sl_type', stockSellSLtype)
    formdata.append('leverage', selectedValue)

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    }

    fetch(`${baseUrl}stock-sell`, requestOptions)
      .then(response => response.json())
      .then(result => {
        // console.log(result)
        if (result.status) {
          toast.success(result.message, {
            autoClose: 1000
          })
          setTimeout(() => {
            window.location.reload()
          }, 1000)
          handleClosesell()
        } else {
          toast.error(result.message, {
            autoClose: 2000
          })
        }
      })
      .catch(error => console.log('error', error))
  }

  // ================================================================
  // =========================Add stock in WatchList API=============
  // ================================================================

  const watcheListStock = (displayKey, selectedStock) => {
    // if (stock && stock.symbol) {
    // console.log('watched ', stock)
    var myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${token}`)

    var formdata = new FormData()
    formdata.append('stock_name', displayKey) // Access symbol directly
    formdata.append('company_name', selectedStock?.company_name)


    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    }

    fetch(`${baseUrl}wach-stock`, requestOptions)
      .then(response => response.json())
      .then(result => {
        // console.log(`Added ${stock.symbol} to watchlist. Response:`, result)
        showWatchList()

        if (result.status) {
          toast.success(result.message, {
            autoClose: 1000
          })
        } else {
          toast.error(result.message, {
            autoClose: 2000
          })
        }
      })
      .catch(error => {
        // console.log(`Error adding ${stock.symbol} to watchlist:`, error)
        // Handle error scenarios here
      })
    // } else {
    //   // console.log('Invalid stock data provided')
    //   // Handle invalid stock data scenario here
    // }
  }

  // ================================================================
  // =========================Show WatchList API====================
  // ================================================================

  const showWatchList = () => {
    var myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${token}`)

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    }

    fetch(`${baseUrl}stock-wach-list`, requestOptions)
      .then(response => response.json())
      .then(result => {
        // console.log(result)
        setwatchListStock(result?.data)
        setWatchListLoading(false)
      })
      .catch(error => {
        console.log('error', error)
        setWatchListLoading(false) // Set loading state to false on error
      })
  }
  useEffect(() => {
    showWatchList()
  }, [])

  // ================================================================
  // =================Delete Stock in WatchList API====================
  // ================================================================
  const watchListStockDelete = stockIdToDelete => {
    const updatedWatchlist = watchListStock.filter(
      stock => stock.id !== stockIdToDelete
    )

    var myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${token}`)

    var formdata = new FormData()
    formdata.append('id', stockIdToDelete)

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    }

    fetch(`${baseUrl}delete-wachlist`, requestOptions)
      .then(response => response.json())
      .then(result => {
        // console.log(result)
        if (result.status) {
          toast.success(result.message, {
            autoClose: 1000
          })
          setwatchListStock(updatedWatchlist)
        } else {
          toast.error(result.message, {
            autoClose: 2000
          })
        }
      })
      .catch(error => console.log('error', error))
  }

  // ================================================================
  // ============Stock name from API================
  // ================================================================

  useEffect(() => {
    fetchData(currentPage, searchQuery, selectedPage)
  }, [currentPage, searchQuery, setSelectedPage])

  const fetchData = page => {
    var requestOptions = {
      method: 'GET',
      redirect: 'follow'
    }
    fetch(
      `${baseUrl}all-stock-list?page=${page}&keyword=${searchQuery}`,
      requestOptions
    )
      .then(response => response.json())
      .then(result => {
        setStockNameList(result?.data?.data)
        setTotalPages(result?.data?.last_page || 1)
        setCurrentPage(result?.data?.current_page || 1)
      })
      .catch(error => console.log('error', error))
  }



  const fetchNextPage = nextPageUrl => {
    var requestOptions = {
      method: 'GET',
      redirect: 'follow'
    }

    fetch(nextPageUrl, requestOptions)
      .then(response => response.json())
      .then(result => {
        setStockNameList(result?.data?.data)
        setTotalPages(result?.data?.last_page || 1) // Adjust the property name if needed
        setCurrentPage(result?.data?.current_page || 1)

      })

      .catch(error => console.log('error', error))
  }

  const fetchPrevPage = prevPageUrl => {
    var requestOptions = {
      method: 'GET',
      redirect: 'follow'
    }

    fetch(prevPageUrl, requestOptions)
      .then(response => response.json())
      .then(result => {
        setStockNameList(result?.data?.data)
        setTotalPages(result?.data?.last_page || 1) // Adjust the property name if needed
        setCurrentPage(result?.data?.current_page || 1)
      })
      .catch(error => console.log('error', error))
  }

  const handleNextPage = () => {
    const nextPageUrl = stockNameList.next_page_url
    if (nextPageUrl) {
      fetchNextPage(nextPageUrl)
    } else {
      fetchData(currentPage + 1)
    }
    setSelectedPage(currentPage + 1)
  }

  const handlePrevPage = () => {
    const prevPageUrl = stockNameList.prev_page_url
    if (prevPageUrl) {
      fetchPrevPage(prevPageUrl)
    } else {
      fetchData(currentPage - 1)
    }
    setSelectedPage(currentPage - 1)

  }

  // =======================================================
  //===================  upstox Search API//===================
  // =======================================================

  useEffect(() => {
    marketQuote()
  }, [upstoxToken, stockFullname, stockSearchData]);

  const marketQuote = () => {
    if (upstoxToken && stockFullname) { // Check if both upstoxToken and stockFullname are truthy
      var myHeaders = new Headers();
      myHeaders.append('Api-Version', '2.0');
      myHeaders.append('Accept', 'application/json');
      myHeaders.append('Authorization', `Bearer ${upstoxToken}`);
      myHeaders.append(
        'Cookie',
        '__cf_bm=oPTQhNWGMFh9tQu3aMrlMlxpxJVM2SV0BxI1fkQjngU-1707716110-1-ARw9lYqV7j07EvHpPbKUEUqE906/kSJDtEGdqocgvFWReNfFELHgJVto/yeakmosAUjcnMhjFOUKw7tx2rbQAGo=; _cfuvid=ZadoH7mlxPSlY0wwYNndAV69QcF2JHDbxZkTPG0mmRk-1707715111700-0-604800000'
      );

      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };

      fetch(
        `https://api-v2.upstox.com/market-quote/quotes?symbol=${stockFullname}`,
        requestOptions
      )
        .then(response => response.json())
        .then(result => {
          if (result.status === 'error') {
            setShowErrorModal(true);
            setShowBuy(false);
            setShowsell(false);
          } else if (result.data && Object.keys(result.data).length > 0) {
            setStockSearchData(result.data[Object.keys(result.data)[0]]);
          } else {
            console.log('API response data is empty.');
          }
        })
        .catch(error => console.log('error', error));
    }
  }

  return (
    <>
      <ToastContainer />

      <section className='dashboard_sidebar_main_div'>
        <ul className='nav nav-tabs' id='myTab' role='tablist'>
          <li className='nav-item' role='presentation'>
            <button
              className='nav-link active'
              id='stock-tab'
              data-bs-toggle='tab'
              data-bs-target='#stock'
              type='button'
              role='tab'
              aria-controls='stock'
              aria-selected='true'
            >
              <AiOutlineStock className='icons' /> Stocks
            </button>
          </li>
          <li className='nav-item' role='presentation'>
            <button
              className='nav-link'
              id='watchlist-tab'
              data-bs-toggle='tab'
              data-bs-target='#watchlist'
              type='button'
              role='tab'
              aria-controls='watchlist'
              aria-selected='false'
            >
              <LiaStopwatchSolid className='icons' /> watchlist
            </button>
          </li>
        </ul>
        <div className='tab-content' id='myTabContent'>
          <div
            className='tab-pane fade show active'
            id='stock'
            role='tabpanel'
            aria-labelledby='stock-tab'
          >
            <div className=''>
              <div className='stock_search_div'>
                <form>
                  <div className='form-group search_box'>
                    <input
                      type='search'
                      className='form-control'
                      placeholder='Search stocks here...'
                      value={searchQuery}
                      onChange={handleSearch}
                    />
                    <MdOutlineSearch className='icon' />
                  </div>
                </form>
              </div>
              <div className='stocks_list_main_outer'>
                <div className='stocks_list_main'>
                  {stockNameList &&
                    stockNameList.map &&
                    stockNameList.map(stockInfo => {
                      const displayKey = stockInfo.stock_name.replace(
                        'NSE_INDEX|',
                        ''
                      )

                      const displayKey2 = stockInfo.stock_name.replace(
                        'NSE_EQ|',
                        ''
                      )

                      {
                        /* const socketDataKey = Object.keys(socketData).find(key =>
                        key.includes(`NSE_INDEX|${displayKey}`)
                      )
                      const socketDataKey2 = Object.keys(socketData).find(key =>
                        key.includes(`NSE_EQ|${displayKey2}`)
                      ) */
                      }

                      const socketDataKey = Object.keys(socketData).find(
                        key => key === `NSE_INDEX|${displayKey}`
                      )

                      const socketDataKey2 = Object.keys(socketData).find(
                        key => key === `NSE_EQ|${displayKey2}`
                      )

                      const ltpValue =
                        socketData[socketDataKey]?.ff?.indexFF?.ltpc?.ltp ||
                        ""

                      const ltpValue2 =
                        socketData[socketDataKey2]?.ff?.marketFF?.ltpc?.ltp ||
                        ""
                      const cp =
                        socketData[socketDataKey]?.ff?.indexFF?.ltpc?.cp || ""

                      const cp2 =
                        socketData[socketDataKey2]?.ff?.marketFF?.ltpc?.cp ||
                        ""

                      let percentageChange = null
                      if (cp !== "" && ltpValue !== "") {
                        const change = ltpValue - cp
                        percentageChange = ((change / cp) * 100).toFixed(2)
                      }

                      let percentageChange2 = null
                      if (cp2 !== "" && ltpValue2 !== "") {
                        const change = ltpValue2 - cp2
                        percentageChange2 = ((change / cp2) * 100).toFixed(2)
                      }

                      // Check if the stock matches the search query
                      const stockMatchesSearch = stockInfo.company_name
                        .toLowerCase()
                        .includes(searchQuery.toLowerCase())

                      const isNSEIndex =
                        stockInfo.stock_name.includes('NSE_INDEX')
                      const isNSE_EQ = stockInfo.stock_name.includes('NSE_EQ')

                      if (stockMatchesSearch) {
                        return (
                          <React.Fragment key={displayKey}>
                            <div className='stocks_list_single'>
                              <h4>{stockInfo.company_name}</h4>

                              <div className='stocks_list_single_price_div'>
                                <h5>
                                  {isNSEIndex
                                    ? (ltpValue !== '' && cp !== '' ? (ltpValue - cp).toFixed(2) : '')
                                    : (ltpValue2 !== '' && cp2 !== '' ? (ltpValue2 - cp2).toFixed(2) : '')}
                                </h5>
                                {/* <h5>
                                  {isNSEIndex
                                    ? (ltpValue - cp).toFixed(2)
                                    : ''
                                      ? (ltpValue2 - cp2).toFixed(2)
                                      : ''}
                                </h5> */}

                                <h5>
                                  {isNSEIndex
                                    ? percentageChange !== null
                                      ? `${percentageChange}%`
                                      : ''
                                    : isNSE_EQ
                                      ? percentageChange2 !== null
                                        ? `${percentageChange2}%`
                                        : ''
                                      : ''}
                                </h5>
                                <h5>
                                  {isNSEIndex
                                    ? ltpValue
                                    : isNSE_EQ
                                      ? ltpValue2
                                      : ''}
                                </h5>
                              </div>

                              <div className='stocks_list_single_buttons'>
                                <button
                                  title='buy'
                                  className='buy_btn'
                                  onClick={() =>
                                    handleShowBuy(
                                      displayKey,
                                      ltpValue,
                                      ltpValue2,
                                      socketDataKey,
                                      stockInfo
                                    )
                                  }
                                >
                                  B
                                </button>
                                <button
                                  title='Sell'
                                  className='sell_btn'
                                  onClick={() =>
                                    handleShowsell(
                                      displayKey,
                                      ltpValue,
                                      ltpValue2,
                                      socketDataKey,
                                      stockInfo
                                    )
                                  }
                                >
                                  S
                                </button>
                                <NavLink
                                  // to={`/charts/${displayKey}`}
                                  to={`/charts/${stockInfo.stock_name}/${stockInfo.company_name}`}
                                  title='Charts'
                                  className='link_btn'
                                >
                                  <IoStatsChartSharp />
                                </NavLink>

                                <button
                                  title='Delete'
                                  onClick={() => handleDelete(displayKey)}
                                >
                                  <AiOutlineDelete />
                                </button>
                                <button
                                  title='Pin'
                                  onClick={() => watcheListStock(displayKey, stockInfo)}
                                >
                                  {' '}
                                  <GoPin />{' '}
                                </button>
                              </div>
                            </div>
                          </React.Fragment>
                        )
                      }

                      return null // If it doesn't match the search query
                    })}
                </div>
              </div>
            </div>
            <div className='pagination'>
              <button onClick={handlePrevPage} disabled={currentPage === 1}>
                Previous
              </button>
              <select
                value={selectedPage}
                onChange={e => {
                  setSelectedPage(parseInt(e.target.value, 10))
                  fetchData(parseInt(e.target.value, 10))
                }}

              >
                {pageNumbers.map(pageNumber => (
                  <option key={pageNumber} value={pageNumber}>
                    {pageNumber}
                  </option>
                ))}
              </select>

              <span>{`Page ${currentPage} of ${totalPages}`}</span>
              <button
                onClick={handleNextPage}
                disabled={currentPage === totalPages}
              >
                Next
              </button>
            </div>
          </div>

          {/*  Watchlist tab */}
          <div
            className='tab-pane fade'
            id='watchlist'
            role='tabpanel'
            aria-labelledby='watchlist-tab'
          >
            <div className='watchList_Section'>
              {watchListStock && watchListStock.length > 0 ? (
                <div className='stocks_list_main'>
                  {watchListStock &&
                    watchListStock.map(watchListStockItem => {
                      const displayKey = watchListStockItem.stock_name.replace(
                        'NSE_INDEX|',
                        ''
                      )
                      const displayKey2 = watchListStockItem.stock_name.replace(
                        'NSE_EQ|',
                        ''
                      )

                      const socketDataKey = Object.keys(socketData).find(key =>
                        key.includes(`NSE_INDEX|${displayKey}`)
                      )
                      const socketDataKey2 = Object.keys(socketData).find(key =>
                        key.includes(`NSE_EQ|${displayKey2}`)
                      )

                      const ltpValue =
                        socketData[socketDataKey]?.ff?.indexFF?.ltpc?.ltp ||
                        null
                      const ltpValue2 =
                        socketData[socketDataKey2]?.ff?.marketFF?.ltpc?.ltp ||
                        null
                      const cp =
                        socketData[socketDataKey]?.ff?.indexFF?.ltpc?.cp || null
                      const cp2 =
                        socketData[socketDataKey2]?.ff?.marketFF?.ltpc?.cp ||
                        null

                      let percentageChange = null
                      if (cp !== null && ltpValue !== null) {
                        const change = ltpValue - cp
                        percentageChange = ((change / cp) * 100).toFixed(2)
                      }

                      let percentageChange2 = null
                      if (cp2 !== null && ltpValue2 !== null) {
                        const change = ltpValue2 - cp2
                        percentageChange2 = ((change / cp2) * 100).toFixed(2)
                      }

                      return (
                        <React.Fragment key={displayKey}>
                          <div className='stocks_list_single'>
                            <h4>{watchListStockItem.company_name}</h4>

                            {/* <div className='stocks_list_single_price_div'>
                              <h5>{ltpValue}</h5>
                              <h5>
                                {percentageChange !== null
                                  ? `${percentageChange}%`
                                  : ''}
                              </h5>
                              <h5>{ltpValue}</h5>
                            </div> */}

                            <div className='stocks_list_single_buttons'>
                              <button
                                title='buy'
                                className='buy_btn'
                                // onClick={() =>
                                //   handleShowBuy(
                                //     displayKey,
                                //     ltpValue,
                                //     socketDataKey
                                //   )
                                // }
                                onClick={() =>
                                  handleShowBuy(
                                    displayKey,
                                    ltpValue,
                                    ltpValue2,
                                    socketDataKey,
                                    watchListStockItem
                                  )
                                }
                              >
                                B
                              </button>
                              <button
                                title='Sell'
                                className='sell_btn'
                                // onClick={() =>
                                //   handleShowsell(
                                //     displayKey,
                                //     ltpValue,
                                //     socketDataKey
                                //   )
                                // }
                                onClick={() =>
                                  handleShowsell(
                                    displayKey,
                                    ltpValue,
                                    ltpValue2,
                                    socketDataKey,
                                    watchListStockItem
                                  )
                                }
                              >
                                S
                              </button>
                              <NavLink
                                // to={`/charts/${displayKey}`}
                                // to={`/charts/${watchListStockItem.stock_name}`}
                                to={`/charts/${watchListStockItem.stock_name}/${watchListStockItem.company_name}`}

                                title='Charts'
                                className='link_btn'
                              >
                                <IoStatsChartSharp />
                              </NavLink>

                              <button
                                title='Delete'
                                onClick={() =>
                                  watchListStockDelete(watchListStockItem.id)
                                }
                              >
                                <AiOutlineDelete />
                              </button>
                            </div>
                          </div>
                        </React.Fragment>
                      )
                    })}
                </div>
              ) : (
                <p className='text-center'>No stocks in your watchlist.</p>
              )}
            </div>
          </div>
        </div>
      </section>

      {/* stock buy modal */}
      {selectedStock && (
        <Modal
          show={showBuy}
          onHide={handleCloseBuy}
          className='stock_modal_main '
          centered
        >
          {' '}
          <form onSubmit={stockBuy}>
            <Modal.Header closeButton>
              <Modal.Title className='stock_modal_title'>
                <h4 className='green_text mr-2'>
                  Buy {selectedStock}
                  <span>BSE * </span> <span>{stockBuyQTY} Qty</span>
                </h4>
                {/* <h6 className='text-light'>
                  Total Price:{' '}
                  {stockBuyQTY * stockBuyPrice.toFixed(2) ||
                    stockSearchData.last_price.toFixed(2)}{' '}
                </h6> */}

                <h6 className='text-light'>
                  Total Price:{' '}
                  {stockBuyQTY && stockBuyPrice
                    ? (stockBuyQTY * stockBuyPrice).toFixed(2)
                    : (stockBuyQTY * stockSearchData?.last_price).toFixed(2)
                      ? (stockBuyQTY * stockSearchData.last_price).toFixed(2)
                      : 'N/A'}
                </h6>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <div className='buy_modal_price_box'>
                    <div className='input-container'>
                      <input
                        type='number'
                        id='inputField'
                        placeholder=''
                        value={stockBuyQTY}
                        onChange={e => setStockBuyQTY(e.target.value)}
                        min='1'
                      />
                      <label for='inputField' className='input-label'>
                        Qty
                      </label>
                    </div>
                    <div>
                      <div className='input-container'>
                        <input
                          type='text'
                          id='inputFieldbuyprice'
                          placeholder=''
                          // value={
                          //   stockBuyMarketLimt === 'market'
                          //     ? lastPrice
                          //     : stockBuyPrice
                          // }
                          value={
                            stockBuyMarketLimt === 'market'
                              ? lastPrice ||
                              stockSearchData?.last_price?.toFixed(2)
                              : stockBuyPrice
                          }
                          onChange={e => setStockBuyPrice(e.target.value)}
                          disabled={
                            stockBuyMarketLimt === 'market' ||
                            stockBuyMarketLimt === 'SL-M'
                          }
                        />
                        <label for='inputFieldbuyprice' className='input-label'>
                          Price
                        </label>
                      </div>
                      <div className='d-flex flex_radio_buttons_div'></div>
                    </div>
                    <div>
                      <div className='input-container'>
                        <input
                          type='text'
                          id='inputField'
                          placeholder=''
                          value={stockBuyTriggerPrice}
                          onChange={e =>
                            setStockBuyTriggerPrice(e.target.value)
                          }
                          disabled={
                            stockBuyMarketLimt === 'market' ||
                            stockBuyMarketLimt === 'limit'
                          }
                        />
                        <label for='inputField' className='input-label'>
                          Trigger Price
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className='flex_radio_buttons_div mb-2'>
                    <div className='d-flex'>
                      <input
                        type='radio'
                        id='market'
                        name='marketLimit'
                        value='market'
                        checked={stockBuyMarketLimt === 'market'}
                        // onChange={() => setStockBuyMarketLimt('market')}
                        onChange={() => {
                          setStockBuyMarketLimt('market')
                          setStockBuyPrice(lastPrice)
                        }}
                      />
                      <label for='market'>Market</label>
                    </div>
                    <div className='d-flex'>
                      <input
                        type='radio'
                        id='limit'
                        name='marketLimit'
                        value='limit'
                        checked={stockBuyMarketLimt === 'limit'}
                        onChange={() => setStockBuyMarketLimt('limit')}
                      />
                      <label for='limit'>Limit</label>
                    </div>
                    <div className='d-flex'>
                      <input
                        type='radio'
                        id='SL'
                        name='marketLimit'
                        value='SL'
                        checked={stockBuyMarketLimt === 'SL'}
                        onChange={() => setStockBuyMarketLimt('SL')}
                      />
                      <label for='SL'>SL</label>
                    </div>
                    <div className='d-flex'>
                      <input
                        type='radio'
                        id='SL_M'
                        name='marketLimit'
                        value='SL-M'
                        checked={stockBuyMarketLimt === 'SL-M'}
                        onChange={() => setStockBuyMarketLimt('SL-M')}
                      />
                      <label for='SL_M'>SL-M</label>
                    </div>
                  </div>

                  <div className='leverage_main_div stoploss_target_div_main'>
                    <h4>Leverage </h4>
                    <br />

                    {userProfileData?.margin === 3 ? (
                      <div className='value-indicators'>
                        <div>
                          <input
                            type='radio'
                            id='1'
                            name='Leverage_margin'
                            value='1'
                            checked={selectedValue === '1'}
                            onChange={handleRadioChange}
                          />
                          <label htmlFor='1'>1X</label>
                        </div>
                        <div>
                          <input
                            type='radio'
                            id='2'
                            name='Leverage_margin'
                            value='2'
                            checked={selectedValue === '2'}
                            onChange={handleRadioChange}
                          />
                          <label htmlFor='2'>2X</label>
                        </div>
                        <div>
                          <input
                            type='radio'
                            id='3'
                            name='Leverage_margin'
                            value='3'
                            checked={selectedValue === '3'}
                            onChange={handleRadioChange}
                          />
                          <label htmlFor='3'>3X</label>
                        </div>
                      </div>
                    ) : userProfileData?.margin === 10 ? (
                      <>
                        <div className='value-indicators'>
                          <div>
                            <input
                              type='radio'
                              id='1'
                              name='Leverage_margin'
                              value='1'
                              checked={selectedValue === '1'}
                              onChange={handleRadioChange}
                            />
                            <label htmlFor='1'>1X</label>
                          </div>
                          <div>
                            <input
                              type='radio'
                              id='3'
                              name='Leverage_margin'
                              value='3'
                              checked={selectedValue === '3'}
                              onChange={handleRadioChange}
                            />
                            <label htmlFor='3'>3X</label>
                          </div>
                          <div>
                            <input
                              type='radio'
                              id='5'
                              name='Leverage_margin'
                              value='5'
                              checked={selectedValue === '5'}
                              onChange={handleRadioChange}
                            />
                            <label htmlFor='5'>5X</label>
                          </div>
                          <div>
                            <input
                              type='radio'
                              id='10'
                              name='Leverage_margin'
                              value='10'
                              checked={selectedValue === '10'}
                              onChange={handleRadioChange}
                            />
                            <label htmlFor='10'>10X</label>
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className='value-indicators'>
                        <div>
                          <input
                            type='radio'
                            id='1'
                            name='Leverage_margin'
                            value='1'
                            checked={selectedValue === '1'}
                            onChange={handleRadioChange}
                          />
                          <label htmlFor='1'>1X</label>
                        </div>
                        <div>
                          <input
                            type='radio'
                            id='3'
                            name='Leverage_margin'
                            value='3'
                            checked={selectedValue === '3'}
                            onChange={handleRadioChange}
                          />
                          <label htmlFor='3'>3X</label>
                        </div>
                        <div>
                          <input
                            type='radio'
                            id='5'
                            name='Leverage_margin'
                            value='5'
                            checked={selectedValue === '5'}
                            onChange={handleRadioChange}
                          />
                          <label htmlFor='5'>5X</label>
                        </div>
                        <div>
                          <input
                            type='radio'
                            id='10'
                            name='Leverage_margin'
                            value='10'
                            checked={selectedValue === '10'}
                            onChange={handleRadioChange}
                          />
                          <label htmlFor='10'>10X</label>
                        </div>

                        <div>
                          <input
                            type='radio'
                            id='20'
                            name='Leverage_margin'
                            value='20'
                            checked={selectedValue === '20'}
                            onChange={handleRadioChange}
                          />
                          <label htmlFor='20'>20X</label>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className='stocks_modal_footer'>
                <div className='d-flex align-items-center'>
                  <h4>
                    Margin:-{' '}
                    <span>
                      {/* {(stockBuyQTY * selectedValue * stockBuyPrice).toFixed(2)} */}
                      {stockBuyQTY && stockBuyPrice
                        ? (stockBuyQTY * stockBuyPrice).toFixed(2)
                        : (stockBuyQTY * stockSearchData?.last_price).toFixed(2)
                          ? (stockBuyQTY * stockSearchData.last_price).toFixed(2)
                          : 'N/A'}
                    </span>
                  </h4>
                  <h4>
                    Charges:- <span>N/A</span>
                  </h4>
                  {/* <button className='stock_refresh_btn'>
                    <IoRefreshOutline />
                  </button> */}
                </div>
              </div>
              <div>
                <button
                  type='submit'
                  className='btn btn-primary '
                  style={{ marginRight: '10px' }}
                >
                  Buy
                </button>
                <Button variant='secondary' onClick={handleCloseBuy}>
                  cancel
                </Button>
              </div>
            </Modal.Footer>
          </form>
        </Modal>
      )}

      {/* stock sell modal */}
      {selectedStock && (
        <Modal
          show={showsell}
          onHide={handleClosesell}
          centered
          className='stock_modal_main stock_sell_modal_main'
        >
          <form onSubmit={stockSell}>
            <Modal.Header closeButton>
              <Modal.Title className='stock_modal_title'>
                <h4 className='green_text mr-2'>
                  Sell {selectedStock}
                  <span>BSE * </span> <span>{stockSellQTY} Qty</span>
                </h4>
                <h6 className='text-light'>
                  Total Price:
                  {/* {(stockSellQTY * stockSellPrice).toFixed(2)} */}
                  {stockSellQTY && stockSellPrice
                    ? (stockSellQTY * stockSellPrice).toFixed(2)
                    : (stockSellQTY * stockSearchData?.last_price).toFixed(2)
                      ? (stockSellQTY * stockSearchData.last_price).toFixed(2)
                      : 'N/A'}
                </h6>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div className='buy_modal_price_box'>
                  <div className='input-container'>
                    <input
                      type='number'
                      id='inputField'
                      placeholder=''
                      value={stockSellQTY}
                      onChange={e => setstockSellQTY(e.target.value)}
                      min='1'
                    />
                    <label for='inputField' className='input-label'>
                      Qty
                    </label>
                  </div>
                  <div>
                    <div className='input-container'>
                      <input
                        type='number'
                        id='inputField'
                        placeholder=''
                        // value={stockSellPrice}
                        // value={
                        //   stockSellMarketLimit === 'market'
                        //     ? lastPrice
                        //     : stockSellPrice
                        // }
                        value={
                          stockSellMarketLimit === 'market'
                            ? lastPrice ||
                            stockSearchData?.last_price?.toFixed(2)
                            : stockSellPrice
                        }
                        onChange={e => setstockSellPrice(e.target.value)}
                        disabled={
                          stockSellMarketLimit === 'market' ||
                          stockSellMarketLimit === 'SL-M'
                        }
                      />
                      <label for='inputField' className='input-label'>
                        Price
                      </label>
                    </div>
                  </div>
                  <div>
                    <div className='input-container'>
                      <input
                        type='number'
                        id='inputField'
                        placeholder=''
                        value={stockSellTriggerPrice}
                        onChange={e => setstockSellTriggerPrice(e.target.value)}
                        disabled={
                          stockSellMarketLimit === 'market' ||
                          stockSellMarketLimit === 'limit'
                        }
                      />
                      <label for='inputField' className='input-label'>
                        Trigger Price
                      </label>
                    </div>
                  </div>
                </div>

                <div className='flex_radio_buttons_div mb-2'>
                  <div className='d-flex'>
                    <input
                      type='radio'
                      id='market'
                      name='marketLimit'
                      value='market'
                      checked={stockSellMarketLimit === 'market'}
                      // onChange={() => setstockSellMarketLimit('market')}
                      onChange={() => {
                        setstockSellMarketLimit('market')
                        setstockSellPrice(lastPrice)
                      }}
                    />
                    <label for='market'>Market</label>
                  </div>
                  <div className='d-flex'>
                    <input
                      type='radio'
                      id='limit'
                      name='marketLimit'
                      value='limit'
                      checked={stockSellMarketLimit === 'limit'}
                      onChange={() => setstockSellMarketLimit('limit')}
                    />
                    <label for='limit'>Limit</label>
                  </div>
                  <div className='d-flex'>
                    <input
                      type='radio'
                      id='SL'
                      name='marketLimit'
                      value='SL'
                      checked={stockSellMarketLimit === 'SL'}
                      onChange={() => setstockSellMarketLimit('SL')}
                    />
                    <label for='SL'>SL</label>
                  </div>
                  <div className='d-flex'>
                    <input
                      type='radio'
                      id='SL_M'
                      name='marketLimit'
                      value='SL-M'
                      checked={stockSellMarketLimit === 'SL-M'}
                      onChange={() => setstockSellMarketLimit('SL-M')}
                    />
                    <label for='SL_M'>SL-M</label>
                  </div>
                </div>

                <div className='leverage_main_div stoploss_target_div_main'>
                  <h4>Leverage </h4>
                  <br />

                  {userProfileData?.margin === 3 ? (
                    <div className='value-indicators'>
                      <div>
                        <input
                          type='radio'
                          id='1'
                          name='Leverage_margin'
                          value='1'
                          checked={selectedValue === '1'}
                          onChange={handleRadioChange}
                        />
                        <label htmlFor='1'>1X</label>
                      </div>
                      <div>
                        <input
                          type='radio'
                          id='2'
                          name='Leverage_margin'
                          value='2'
                          checked={selectedValue === '2'}
                          onChange={handleRadioChange}
                        />
                        <label htmlFor='2'>2X</label>
                      </div>
                      <div>
                        <input
                          type='radio'
                          id='3'
                          name='Leverage_margin'
                          value='3'
                          checked={selectedValue === '3'}
                          onChange={handleRadioChange}
                        />
                        <label htmlFor='3'>3X</label>
                      </div>
                    </div>
                  ) : userProfileData?.margin === 10 ? (
                    <>
                      <div className='value-indicators'>
                        <div>
                          <input
                            type='radio'
                            id='1'
                            name='Leverage_margin'
                            value='1'
                            checked={selectedValue === '1'}
                            onChange={handleRadioChange}
                          />
                          <label htmlFor='1'>1X</label>
                        </div>
                        <div>
                          <input
                            type='radio'
                            id='3'
                            name='Leverage_margin'
                            value='3'
                            checked={selectedValue === '3'}
                            onChange={handleRadioChange}
                          />
                          <label htmlFor='3'>3X</label>
                        </div>
                        <div>
                          <input
                            type='radio'
                            id='5'
                            name='Leverage_margin'
                            value='5'
                            checked={selectedValue === '5'}
                            onChange={handleRadioChange}
                          />
                          <label htmlFor='5'>5X</label>
                        </div>
                        <div>
                          <input
                            type='radio'
                            id='10'
                            name='Leverage_margin'
                            value='10'
                            checked={selectedValue === '10'}
                            onChange={handleRadioChange}
                          />
                          <label htmlFor='10'>10X</label>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className='value-indicators'>
                      <div>
                        <input
                          type='radio'
                          id='1'
                          name='Leverage_margin'
                          value='1'
                          checked={selectedValue === '1'}
                          onChange={handleRadioChange}
                        />
                        <label htmlFor='1'>1X</label>
                      </div>
                      <div>
                        <input
                          type='radio'
                          id='3'
                          name='Leverage_margin'
                          value='3'
                          checked={selectedValue === '3'}
                          onChange={handleRadioChange}
                        />
                        <label htmlFor='3'>3X</label>
                      </div>
                      <div>
                        <input
                          type='radio'
                          id='5'
                          name='Leverage_margin'
                          value='5'
                          checked={selectedValue === '5'}
                          onChange={handleRadioChange}
                        />
                        <label htmlFor='5'>5X</label>
                      </div>
                      <div>
                        <input
                          type='radio'
                          id='10'
                          name='Leverage_margin'
                          value='10'
                          checked={selectedValue === '10'}
                          onChange={handleRadioChange}
                        />
                        <label htmlFor='10'>10X</label>
                      </div>

                      <div>
                        <input
                          type='radio'
                          id='20'
                          name='Leverage_margin'
                          value='20'
                          checked={selectedValue === '20'}
                          onChange={handleRadioChange}
                        />
                        <label htmlFor='20'>20X</label>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className='stocks_modal_footer'>
                <div className='d-flex align-items-center'>
                  <h4>
                    Margin:-{' '}
                    <span>
                      {' '}
                      {/* {(stockSellQTY * selectedValue * stockSellPrice).toFixed(
                        2
                      )} */}
                      {stockSellQTY && stockSellPrice
                        ? (stockSellQTY * stockSellPrice).toFixed(2)
                        : (stockSellQTY * stockSearchData?.last_price).toFixed(
                          2
                        )
                          ? (stockSellQTY * stockSearchData.last_price).toFixed(2)
                          : 'N/A'}
                    </span>
                  </h4>
                  <h4>
                    Charges:- <span>N/A</span>
                  </h4>
                  {/* <button className='stock_refresh_btn'>
                    <IoRefreshOutline />
                  </button> */}
                </div>
              </div>
              <div>
                <button type='submit' className='btn btn-danger buy sell'>
                  Sell
                </button>
                <Button variant='secondary' onClick={handleClosesell}>
                  cancel
                </Button>
              </div>
            </Modal.Footer>
          </form>
        </Modal>
      )}


      {showErrorModal && <Modal show={showErrorModal} onHide={handleCloseError} size="lg" className='error_modal_box' aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body >
          <img src='https://img.freepik.com/free-vector/tiny-people-examining-operating-system-error-warning-web-page-isolated-flat-illustration_74855-11104.jpg?size=626&ext=jpg&ga=GA1.1.2037322370.1669199958&semt=sph' className='img-fluid' />
          <h3>Note - You can access live data and charts during market hours only. In case of any issues, kindly contact with support team.</h3>
        </Modal.Body>

      </Modal>}
    </>
  )
}

export default StcoksList
