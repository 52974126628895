import React, { useEffect, useState, useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { FaRegUser } from 'react-icons/fa6'
import { IoMdNotificationsOutline } from 'react-icons/io'
import { NavLink } from 'react-router-dom'
import { UserContext } from './UserContext'
import { v4 as uuidv4 } from 'uuid'
import { ToastContainer, toast } from 'react-toastify'
import logo from '../Assets/logo.JPG'

const Header = () => {
  const { userProfileData } = useContext(UserContext)
  // console.log('userProfileData ', userProfileData?.device_unique_id)
  const navigate = useNavigate()

  const token = localStorage.getItem('accesToken')
  const device_unique_id = uuidv4()

  // console.log('device_unique_id', device_unique_id)

  const baseUrl = process.env.REACT_APP_API_BASE_URL

  const UserLogout = e => {
    e.preventDefault()
    var myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${token}`)

    var formdata = new FormData()
    // formdata.append('device_token', token)
    formdata.append('device_unique_id', userProfileData?.device_unique_id)

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    }

    fetch(`${baseUrl}logout`, requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        if (result.status) {
          toast.success(result.message, {
            autoClose: 1000
          })
          localStorage.removeItem('accesToken')
          setTimeout(() => {
            navigate('/')
          }, 2000)
        } else {
          toast.error(result.message, {
            autoClose: 2000
          })
        }
      })
      .catch(error => console.log('error', error))
  }
  return (
    <>
      <ToastContainer />

      <section className='dashboard_header'>
        <nav className='main_header dashboard_header navbar navbar-expand-lg  sticky-top'>
          <div className='container-fluid'>
            <NavLink className='navbar-brand' to='/my-account'>
              <img src={logo} alt='logo' className='logo' />
            </NavLink>
            <button
              className='navbar-toggler'
              type='button'
              data-bs-toggle='collapse'
              data-bs-target='#navbarSupportedContent'
              aria-controls='navbarSupportedContent'
              aria-expanded='false'
              aria-label='Toggle navigation'
            >
              <span className='navbar-toggler-icon'></span>
            </button>
            <div
              className='collapse navbar-collapse'
              id='navbarSupportedContent'
            >
              <ul className='navbar-nav ms-auto mb-2 mb-lg-0'>
                <li className='nav-item'>
                  <NavLink
                    className='nav-link'
                    aria-current='page'
                    to='/my-account'
                  >
                    Dashboard
                  </NavLink>
                </li>

                <li className='nav-item'>
                  <NavLink className='nav-link' to='/orders'>
                    Orders
                  </NavLink>
                </li>

                <li className='nav-item'>
                  <NavLink className='nav-link' to='/transaction'>
                    Transaction
                  </NavLink>
                </li>

                {/* <li className='nav-item'>
                  <NavLink className='nav-link' to='/holding'>
                    Holdings
                  </NavLink>
                </li> */}

                {/* <li className='nav-item'>
                  <NavLink className='nav-link' to='/my-account'>
                    <IoMdNotificationsOutline className='icons' />
                  </NavLink>
                </li> */}

                <li className='nav-item dropdown header_Profile'>
                  <a
                    className='nav-link dropdown-toggle'
                    href='#'
                    id='navbarDropdown'
                    role='button'
                    data-bs-toggle='dropdown'
                    aria-expanded='false'
                  >
                    <img
                      src={
                        userProfileData?.profile_picture ||
                        'https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500'
                      }
                      alt={userProfileData?.name}
                      className='img-fluid user_profile_image'
                      onError={e => {
                        e.target.onerror = null // Prevent infinite loop if default image fails to load
                        e.target.src =
                          'https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500'
                      }}
                    />
                    {userProfileData ? userProfileData.name : 'Loading...'}{' '}
                  </a>
                  <ul
                    className='dropdown-menu'
                    aria-labelledby='navbarDropdown'
                  >
                    <li>
                      <NavLink className='dropdown-item' to='/settings'>
                        My Profile
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        className='dropdown-item'
                        to='#'
                        onClick={UserLogout}
                      >
                        {' '}
                        Logout
                      </NavLink>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </section>
    </>
  )
}

export default Header
