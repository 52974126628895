import React from 'react'
import Hero from './Hero'
import Features from './Features'
import MostPopularStocks from './MostPopularStocks'
import FavouriteSector from './FavouriteSector'
import IncredibleFeatures from './IncredibleFeatures'
import IndustryStandardPrices from './IndustryStandardPrices'
import FAQ from './FAQ'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'

const Home = () => {
  return (
    <>
      <Header />
      <Hero />
      <Features />
      <MostPopularStocks />
      <FavouriteSector />
      <IncredibleFeatures />
      <IndustryStandardPrices />
      <FAQ />
      <Footer />
    </>
  )
}

export default Home
