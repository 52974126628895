// import React, { useContext, useEffect, useState } from 'react'

// import Button from 'react-bootstrap/Button'
// import Modal from 'react-bootstrap/Modal'
// import MainLayout from './MainLayout'
// import { BsDownload } from 'react-icons/bs'
// import { NavLink, useNavigate } from 'react-router-dom'
// import { AiOutlinePlus } from 'react-icons/ai'
// import DataTable from 'react-data-table-component'
// import { ToastContainer, toast } from 'react-toastify'

// import dayjs from 'dayjs'
// import { StockListContext, StockListProvider } from './StockListContext'

// const Holdings = () => {
//   const navigate = useNavigate()

//   const token = localStorage.getItem('accesToken')
//   const baseUrl = process.env.REACT_APP_API_BASE_URL

//   const { stockListData, setStockList } = useContext(StockListContext)
//   const [holdingData, setHoldingData] = useState([])

//   useEffect(() => {
//     var myHeaders = new Headers()
//     myHeaders.append('Authorization', `Bearer ${token}`)

//     var requestOptions = {
//       method: 'GET',
//       headers: myHeaders,
//       redirect: 'follow'
//     }

//     fetch(`${baseUrl}stock-hold-list`, requestOptions)
//       .then(response => {
//         if (response.status === 401) {
//           navigate('/login')
//           localStorage.removeItem('accesToken')
//         }
//         return response.json()
//       })
//       .then(result => {
//         setHoldingData(result?.data.reverse())
//       })
//       .catch(error => console.log('error', error))
//   }, [])

//   return (
//     <>
//       <ToastContainer />

//       <MainLayout>
//         <section className='side_content_main_box'>
//           <div className='headings'>
//             <h2>Holdings</h2>
//           </div>
//           <section className='dashboard_boxes_main_section '>
//             <div className='col-12'>
//               <div className='table-responsive'>
//                 <table className='table table-bordered'>
//                   <thead>
//                     <tr>
//                       <th scope='col'>SR.NO</th>
//                       <th scope='col'>Time & Date</th>
//                       <th scope='col'>Instrument</th>
//                       <th scope='col'>QTY</th>
//                       <th scope='col'>LTP</th>
//                       <th scope='col'>Avg. Price</th>
//                       <th scope='col'>Cur. Price</th>
//                       <th scope='col'>P&L</th>
//                       <th scope='col'>Net Chg.</th>
//                     </tr>
//                   </thead>
//                   {holdingData.length > 0 ? (
//                     holdingData.map((holdingData, index) => {
//                       const matchingStock = stockListData.find(
//                         stock => stock.symbol === holdingData.stock_name
//                       )

//                       const priceDifferenceClass =
//                         matchingStock?.pChange < 0 ? 'red_text' : 'green_text'

//                       return (
//                         <tbody>
//                           {matchingStock ? (
//                             <tr key={index}>
//                               <td>{index + 1}</td>
//                               <td>
//                                 {' '}
//                                 {dayjs(holdingData?.created_at).format(
//                                   'hh:mm a, MMM DD, YYYY'
//                                 )}
//                               </td>
//                               <td>{matchingStock?.symbol}</td>
//                               <td>{holdingData?.quantity}</td>
//                               <td>{matchingStock?.dayHigh}</td>
//                               <td>100</td>
//                               <td className='green_text'>
//                                 {matchingStock?.lastPrice}
//                               </td>
//                               <td className={priceDifferenceClass}>
//                                 {matchingStock?.change}
//                               </td>
//                               <td className={priceDifferenceClass}>
//                                 {matchingStock.pChange}%
//                               </td>
//                             </tr>
//                           ) : null}
//                         </tbody>
//                       )
//                     })
//                   ) : (
//                     <div className='refresh_div'>
//                       <p>No data found in the watchlist.</p>
//                     </div>
//                   )}
//                 </table>
//               </div>
//             </div>
//           </section>
//         </section>
//       </MainLayout>
//     </>
//   )
// }

// export default Holdings

import React, { useContext, useEffect, useState } from 'react'

import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import MainLayout from './MainLayout'
import { BsDownload } from 'react-icons/bs'
import { NavLink, useNavigate } from 'react-router-dom'
import { AiOutlinePlus } from 'react-icons/ai'
import DataTable from 'react-data-table-component'
import { ToastContainer, toast } from 'react-toastify'
import { IoClose } from 'react-icons/io5'
import { MdOutlineEdit } from 'react-icons/md'
import dayjs from 'dayjs'
import Deposit from './Deposit'
import Withdrawal from './Withdrawal'
import { UserContext } from './UserContext'
import { StockListContext } from './StockListContext'

const Holdings = () => {
  const { userProfileData, setUserProfileData } = useContext(UserContext)
  const { socketData } = useContext(StockListContext)
  // console.log('socketData is order', socketData)
  const [realTimePrices, setRealTimePrices] = useState({})
  useEffect(() => {
    // Update real-time prices when socketData changes
    if (socketData) {
      setRealTimePrices(socketData)
    }
  }, [socketData])

  const navigate = useNavigate()
  const [withrawalAmount, setWithrawalAmount] = useState('')
  const [withrawalRemark, setWithrawalRemark] = useState('')
  const [transactionTypeFilter, setTransactionTypeFilter] = useState('')

  const token = localStorage.getItem('accesToken')
  const baseUrl = process.env.REACT_APP_API_BASE_URL

  const [historyData, setHistoryData] = useState([])
  const [holdingListData, setHoldingListData] = useState([])

  // =================holding history==================

  const orderHistory = () => {
    var myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${token}`)
    var formdata = new FormData()

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    }

    fetch(`${baseUrl}stock-hold-list`, requestOptions)
      .then(response => {
        if (response.status === 401) {
          navigate('/login')
          localStorage.removeItem('accesToken')
        }
        return response.json()
      })
      .then(result => {
        // console.log(result)
        setHoldingListData(result?.data)
      })
      .catch(error => console.log('error', error))
  }

  useEffect(() => {
    orderHistory()
  }, [token])

  const HoldingListcolumns = [
    {
      name: 'SR No.',
      selector: (row, index) => index + 1,
      sortable: true,
      grow: 0,
      sortable: true,
      maxWidth: '50px'
    },
    {
      name: 'Time & Date',
      selector: 'created_at',
      sortable: true,
      minWidth: '150px',

      cell: row => {
        // Format the 'created_at' date using dayjs
        return dayjs(row.created_at).format('hh:mm A, DD MMM YYYY') // Adjust the format as needed
      }
    },
    {
      name: 'Buy / Sell',
      selector: 'buy_sell_type',
      sortable: true,
      cell: row => {
        const backgroundColor =
          row.buy_sell_type === 'sell' ? '#ff0000cc' : '#008000c4' // Example: Setting different colors based on the type
        const textColor = 'white' // White text color

        const cellStyle = {
          backgroundColor,
          color: textColor,
          padding: '8px 16px', // Add padding as needed
          borderRadius: '2px' // Add border-radius as needed
        }

        return <div style={cellStyle}>{row.buy_sell_type}</div>
      }
    },

    {
      name: 'Instrument',
      selector: 'stock_name',
      sortable: true,
      minWidth: '150px'
    },
    {
      name: 'Quantity',
      cell: row => {
        const quantity = row.quantity
        const leverage = row.leverage

        if (quantity !== 0 || quantity > 2) {
          const quantityByLeverage = quantity / leverage
          return quantityByLeverage // Displaying the result with 2 decimal places
        } else {
          return 'N/A' // To handle division by zero or null leverage
        }
      },
      sortable: false // You may set sortable as per your requirement
    },
    {
      name: 'Current Price',
      selector: 'stock_name',
      sortable: true,
      minWidth: '130px',

      cell: row => {
        const stockKey = row.stock_full_name

        if (
          (realTimePrices[stockKey] &&
            realTimePrices[stockKey].ff &&
            realTimePrices[stockKey].ff.indexFF) ||
          (realTimePrices[stockKey] &&
            realTimePrices[stockKey].ff &&
            realTimePrices[stockKey].ff.marketFF)
        ) {
          const openValue =
            realTimePrices[stockKey].ff?.indexFF?.ltpc?.ltp ||
            realTimePrices[stockKey].ff?.marketFF?.ltpc?.ltp
          return <div>{(openValue * row.quantity).toFixed(2)}</div>
        } else {
          return <div>-</div>
        }
      }
    },

    {
      name: 'Price',
      selector: 'price',
      sortable: true
    },
    {
      name: 'Profit/Loss (%)',
      selector: 'profit_loss_percentage',
      sortable: true,
      minWidth: '140px',
      cell: row => {
        const costPrice = row.price // Assuming this is the cost price
        const currentPrice =
          realTimePrices[row.stock_full_name]?.ff?.indexFF?.ltpc?.ltp ||
          realTimePrices[row.stock_full_name]?.ff?.marketFF?.ltpc?.ltp

        if (costPrice && currentPrice) {
          const profitLoss = ((currentPrice - costPrice) / costPrice) * 100
          const formattedProfitLoss = profitLoss.toFixed(2) // Displaying the result with 2 decimal places
          const textColor = profitLoss >= 0 ? '#008000' : '#ff0000' // Green for profit, red for loss

          const cellStyle = {
            color: textColor,
            padding: '8px 16px', // Add padding as needed
            borderRadius: '2px' // Add border-radius as needed
          }

          return <div style={cellStyle}>{formattedProfitLoss}%</div>
        } else {
          return <div>-</div>
        }
      }
    },
    {
      name: 'Profit/Loss',
      selector: 'profit_loss_percentage',
      sortable: true,
      minWidth: '130px',
      cell: row => {
        const costPrice = row.price // Assuming this is the cost price
        const currentPrice =
          realTimePrices[row.stock_full_name]?.ff?.indexFF?.ltpc?.ltp ||
          realTimePrices[row.stock_full_name]?.ff?.marketFF?.ltpc?.ltp

        if (costPrice && currentPrice) {
          const profitLoss = currentPrice - costPrice
          const formattedProfitLoss = profitLoss.toFixed(2) // Displaying the result with 2 decimal places
          const textColor = profitLoss >= 0 ? '#008000' : '#ff0000' // Green for profit, red for loss

          const cellStyle = {
            color: textColor,
            padding: '8px 16px', // Add padding as needed
            borderRadius: '2px' // Add border-radius as needed
          }

          return <div style={cellStyle}>{formattedProfitLoss}</div>
        } else {
          return <div>-</div>
        }
      }
    },

    {
      name: 'Total Price',
      selector: 'total_amount',
      sortable: true
    }
  ]

  return (
    <div>
      <MainLayout>
        <section className='side_content_main_box'>
          <div className='headings'>
            <h2>Holdings</h2>
          </div>
          <section className='dashboard_boxes_main_section '>
            <DataTable
              className='mt-2'
              columns={HoldingListcolumns}
              data={holdingListData}
              pagination // Enable pagination if needed
            />
          </section>
        </section>
      </MainLayout>
    </div>
  )
}

export default Holdings
