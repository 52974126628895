import React, { useContext, useState, useEffect } from 'react'
import MainLayout from './MainLayout'
import { NavLink } from 'react-router-dom'
import { MdOutlineMailOutline, MdPhone } from 'react-icons/md'
import { IoDocumentTextOutline } from 'react-icons/io5'
import { IoLocationOutline } from 'react-icons/io5'
import { UserContext } from './UserContext'
import { ToastContainer, toast } from 'react-toastify'

const Setting = () => {
  const token = localStorage.getItem('accesToken')
  const baseUrl = process.env.REACT_APP_API_BASE_URL

  const { userProfileData, setUserProfileData } = useContext(UserContext)

  const [currpass, setCurrPass] = useState()
  const [newpass, setNewPass] = useState()
  const [conpass, setConPass] = useState()

  const [userName, setUserName] = useState(userProfileData?.name)
  const [userEmail, setuserEmail] = useState(userProfileData?.email)
  const [userMobile, setuserMobile] = useState(userProfileData?.mobile_number)
  const [userProfile, setUserProfile] = useState('')

  const [accesToken, setAccessToken] = useState('')
  const ChangePassword = e => {
    e.preventDefault()
    const token = localStorage.getItem('accesToken')
    if (token) {
      setAccessToken(token)

      var myHeaders = new Headers()
      myHeaders.append('Authorization', `Bearer ${token}`)

      var formdata = new FormData()
      formdata.append('current_password', currpass)
      formdata.append('new_password', newpass)
      formdata.append('confirm_password', conpass)

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
      }

      fetch(`${baseUrl}change-password`, requestOptions)
        .then(response => response.json())
        .then(result => {
          console.log(result)

          if (result.status) {
            toast.success(result.message, {
              autoClose: 1000
            })
            setConPass('')
            setNewPass('')
            setCurrPass('')
          } else {
            toast.error(result.message, {
              autoClose: 2000
            })
            // setLoginLoader(false)
          }
        })
        .catch(error => console.log('error', error))
    }
  }

  useEffect(() => {
    // Example: Set initial state when userProfileData changes
    if (userProfileData) {
      setUserName(userProfileData.name)
      setuserEmail(userProfileData.email)
      setuserMobile(userProfileData.mobile_number)
    }
  }, [userProfileData])

  // =========================================================
  // ================Edit User profile =======================
  // =========================================================

  const UpdateUserDetails = e => {
    e.preventDefault()

    var myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${token}`)

    var formdata = new FormData()
    formdata.append('name', userName)
    formdata.append('profile_picture', userProfile)

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    }

    fetch(`${baseUrl}edit-profile`, requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        if (result.status) {
          toast.success(result.message, {
            autoClose: 1000
          })
          // setTimeout(() => {
          //   window.location.reload()
          // }, 1000)
        } else {
          toast.error(result.message, {
            autoClose: 2000
          })
        }
      })
      .catch(error => console.log('error', error))
  }

  return (
    <>
      <ToastContainer />

      <MainLayout>
        <section className='side_content_main_box'>
          <div className='headings'>
            <h2>Setting</h2>
          </div>
          <section className='dashboard_boxes_main_section real_demo_tabs setting_tabs'>
            <div className='row'>
              <div className='col-12 Most_Popular_Stocks_tabs '>
                <ul className='nav nav-tabs' id='myTab' role='tablist'>
                  <li className='nav-item' role='presentation'>
                    <button
                      className='nav-link active'
                      id='Profile-tab'
                      data-bs-toggle='tab'
                      data-bs-target='#Profile'
                      type='button'
                      role='tab'
                      aria-controls='Profile'
                      aria-selected='true'
                    >
                      Profile
                    </button>
                  </li>
                  <li className='nav-item' role='presentation'>
                    <button
                      className='nav-link'
                      id='Security-tab'
                      data-bs-toggle='tab'
                      data-bs-target='#Security'
                      type='button'
                      role='tab'
                      aria-controls='Security'
                      aria-selected='false'
                    >
                      Security Settings
                    </button>
                  </li>

                  <li className='nav-item' role='presentation'>
                    <button
                      className='nav-link'
                      id='TradingConditions-tab'
                      data-bs-toggle='tab'
                      data-bs-target='#TradingConditions'
                      type='button'
                      role='tab'
                      aria-controls='TradingConditions'
                      aria-selected='false'
                    >
                      Trading Conditions
                    </button>
                  </li>
                </ul>
              </div>
              <div className=''>
                <div className='tab-content' id='myTabContent'>
                  {/* Profile tab */}
                  <div
                    className='tab-pane fade show active'
                    id='Profile'
                    role='tabpanel'
                    aria-labelledby='Profile-tab'
                  >
                    <div className='small_headings'>
                      <h4>Account</h4>
                    </div>
                    <div className='row'>
                      {/* profile */}
                      <div className='col-lg-6 col-md-6 col-sm-12'>
                        <div className=' card profile_card profile_card '>
                          <div className='d_flex_both_center d_flex_both_center_sp_bt'>
                            <div className='d_flex_both_center'>
                              <div className='profile_img'>
                                <img
                                  src='https://cdn-icons-png.flaticon.com/128/456/456283.png'
                                  alt='profile'
                                  className='img-fluid '
                                />
                              </div>
                              <div className='profile_status'>
                                <h4>Verification status</h4>
                                <h3>Not verified</h3>
                                <h5>
                                  <span>0</span>/3 steps complete
                                </h5>
                              </div>
                            </div>
                            <div className=''>
                              <NavLink className='yellow_btn' to='#'>
                                Complete Now
                              </NavLink>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* deposite Limit */}
                      <div className='col-lg-6 col-md-6 col-sm-12'>
                        <div className=' card profile_card profile_card '>
                          <div className='d_flex_both_center d_flex_both_center_sp_bt'>
                            <div className='d_flex_both_center'>
                              <div className='profile_img'>
                                <img
                                  src='https://cdn-icons-png.flaticon.com/128/2530/2530493.png'
                                  alt='profile'
                                  className='img-fluid '
                                />
                              </div>
                              <div className='profile_status'>
                                <h4>Deposit limit</h4>
                                <h3 className='profile_amount'>₹ 0</h3>
                                <h5>Verify your account to unlock limits</h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='small_headings mt-5'>
                      <h4>Verification steps</h4>
                    </div>

                    <div className='row'>
                      <div className='col-12'>
                        {/* Your confirmed details */}
                        <div className='accordion' id='accordionExample'>
                          <div className='accordion-item'>
                            <h2 className='accordion-header' id='headingOne'>
                              <button
                                className='accordion-button'
                                type='button'
                                data-bs-toggle='collapse'
                                data-bs-target='#collapseOne'
                                aria-expanded='true'
                                aria-controls='collapseOne'
                              >
                                1. Confirm email and phone number. Add personal
                                details
                              </button>
                            </h2>
                            <div
                              id='collapseOne'
                              className='accordion-collapse collapse show'
                              aria-labelledby='headingOne'
                              data-bs-parent='#accordionExample'
                            >
                              <div className='accordion-body'>
                                <h4 className='s_small_headings'>
                                  Your confirmed details:-
                                </h4>
                                <div className='personal_detail_flex'>
                                  <h3>
                                    <MdOutlineMailOutline className='icon' />{' '}
                                    {userProfileData?.email}
                                  </h3>
                                  <h3>
                                    <MdPhone className='icon' />{' '}
                                    {userProfileData?.mobile_number}
                                  </h3>
                                  <h3>
                                    <IoDocumentTextOutline className='icon' />{' '}
                                    {userProfileData?.name}
                                  </h3>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='accordion-item'>
                            <h2 className='accordion-header' id='headingTwo'>
                              <button
                                className='accordion-button collapsed'
                                type='button'
                                data-bs-toggle='collapse'
                                data-bs-target='#collapseTwo'
                                aria-expanded='false'
                                aria-controls='collapseTwo'
                              >
                                2. Verify your identity
                              </button>
                            </h2>
                            <div
                              id='collapseTwo'
                              className='accordion-collapse collapse'
                              aria-labelledby='headingTwo'
                              data-bs-parent='#accordionExample'
                            >
                              <div className='accordion-body'>
                                <h4 className='s_small_headings'>
                                  Provide a document confirming your name:-
                                </h4>
                                <div className='personal_detail_flex'>
                                  <h3>
                                    <IoDocumentTextOutline className='icon' />{' '}
                                    {userProfileData?.name}
                                  </h3>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='accordion-item'>
                            <h2 className='accordion-header' id='headingThree'>
                              <button
                                className='accordion-button collapsed'
                                type='button'
                                data-bs-toggle='collapse'
                                data-bs-target='#collapseThree'
                                aria-expanded='false'
                                aria-controls='collapseThree'
                              >
                                3. Verify residential address
                              </button>
                            </h2>
                            <div
                              id='collapseThree'
                              className='accordion-collapse collapse'
                              aria-labelledby='headingThree'
                              data-bs-parent='#accordionExample'
                            >
                              <div className='accordion-body'>
                                <h4 className='s_small_headings'>
                                  You will need to provide proof of your place
                                  of residence
                                </h4>
                                <div className='personal_detail_flex'>
                                  <h3>
                                    <IoLocationOutline className='icon' />{' '}
                                    Jaipur
                                  </h3>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/*Security Setting  */}
                  <div
                    className='tab-pane fade'
                    id='Security'
                    role='tabpanel'
                    aria-labelledby='Security-tab'
                  >
                    <div className='row'>
                      <div className='col-lg-12 col-md-12 col-sm-12'>
                        <div className='small_headings'>
                          <h4>Verification steps</h4>
                        </div>
                        <p className='common_para mb-0'>
                          Information for logging in to Wells Capital.
                        </p>
                        <p className='common_para mb-0'>
                          Change your password whenever you think it might have
                          been compromised.
                        </p>
                        <div className='row'>
                          <div className='col-lg-6 col-md-6 col-sm-12'>
                            <form onSubmit={UpdateUserDetails}>
                              <div className='login_details card'>
                                <h3>
                                  <span style={{ color: '#ef9309' }}>
                                    {' '}
                                    Personal Details
                                  </span>{' '}
                                </h3>

                                <div className='mb-3'>
                                  <img
                                    src={
                                      userProfileData?.profile_picture ||
                                      `${baseUrl}${userProfileData?.profile_picture}`
                                    }
                                    alt={userProfileData?.name}
                                    className='img-fluid user_profile_img'
                                    onError={e => {
                                      e.target.onerror = null // Prevent infinite loop if default image fails to load
                                      e.target.src = `${baseUrl}public/uploads/user_profile_image/1701843307.jpg`
                                    }}
                                  />

                                  <br />
                                  <label
                                    for='profileImg'
                                    className='form-label'
                                  >
                                    Profile Image
                                  </label>
                                  <input
                                    type='file'
                                    className='form-control'
                                    id='profileImg'
                                    value={userProfile}
                                    onChange={e =>
                                      setUserProfile(e.target.value)
                                    }
                                  />
                                </div>
                                <div className='mb-3'>
                                  <label for='username' className='form-label'>
                                    Username {userProfileData?.name}
                                  </label>
                                  <input
                                    type='text'
                                    className='form-control'
                                    id='username'
                                    value={userName}
                                    onChange={e => setUserName(e.target.value)}
                                  />
                                </div>
                                <div className='mb-3'>
                                  <label for='useremail' className='form-label'>
                                    Email address
                                  </label>
                                  <input
                                    type='text'
                                    className='form-control'
                                    id='useremail'
                                    value={userEmail}
                                    readOnly
                                    onChange={e => setuserEmail(e.target.value)}
                                  />
                                </div>
                                <div className='mb-3'>
                                  <label for='usermob' className='form-label'>
                                    Mobile number
                                  </label>
                                  <input
                                    type='text'
                                    className='form-control'
                                    id='usermob'
                                    value={userMobile}
                                    onChange={e =>
                                      setuserMobile(e.target.value)
                                    }
                                  />
                                </div>
                                <button
                                  type='submit'
                                  className='yellow_btn start_now_btn'
                                >
                                  Update Details
                                </button>
                              </div>
                            </form>
                          </div>
                          <div className='col-lg-6 col-md-6 col-sm-12'>
                            <form onSubmit={ChangePassword}>
                              <div className='login_details card'>
                                <h3>
                                  <span style={{ color: '#ef9309' }}>
                                    {' '}
                                    Change Password
                                  </span>{' '}
                                </h3>
                                <div className='mb-3'>
                                  <label for='Currpass' className='form-label'>
                                    Current Password
                                  </label>
                                  <input
                                    type='text'
                                    className='form-control'
                                    id='Currpass'
                                    value={currpass}
                                    placeholder='Current Password'
                                    onChange={e => setCurrPass(e.target.value)}
                                  />
                                </div>
                                <div className='mb-3'>
                                  <label for='newpass' className='form-label'>
                                    New Password
                                  </label>
                                  <input
                                    type='text'
                                    className='form-control'
                                    id='newpass'
                                    value={newpass}
                                    placeholder='New Password'
                                    onChange={e => setNewPass(e.target.value)}
                                  />
                                </div>
                                <div className='mb-3'>
                                  <label for='Conpass' className='form-label'>
                                    Confirm Password
                                  </label>
                                  <input
                                    type='text'
                                    className='form-control'
                                    id='Conpass'
                                    value={conpass}
                                    placeholder='Confirm Password'
                                    onChange={e => setConPass(e.target.value)}
                                  />
                                </div>
                                <button
                                  type='submit'
                                  className='yellow_btn start_now_btn'
                                >
                                  Change Password
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Trading Conditions */}
                  <div
                    className='tab-pane fade'
                    id='TradingConditions'
                    role='tabpanel'
                    aria-labelledby='TradingConditions-tab'
                  >
                    <div className='small_headings'>
                      <h4 className='mb-0'>Trading Conditions</h4>
                      <p className='common_para'>
                        Here's a list of the better-than-market trading
                        conditions you can currently enjoy on your accounts.
                      </p>
                    </div>
                    <div className='row'>
                      {/* profile */}
                      <div className='col-lg-6 col-md-6 col-sm-12'>
                        <div className=' card profile_card profile_card '>
                          <div className='small_headings'>
                            <h4 className='mb-2 mt-0'>
                              Negative Balance Protection
                            </h4>
                          </div>

                          <p>
                            You can never lose more money than you put into your
                            account. If a stop out causes all your positions to
                            close in a negative balance, we will restore it to
                            0. For example, if a trading account with a balance
                            of $100 has its positions closed with a loss of
                            $150, the account will have a negative balance of
                            -$50. With Negative Balance Protection, we will
                            reset the balance to zero and you won’t need to
                            cover the loss with your own money.
                          </p>
                        </div>
                      </div>
                      {/* deposite Limit */}
                      <div className='col-lg-6 col-md-6 col-sm-12'>
                        <div className=' card profile_card profile_card '>
                          <div className='small_headings'>
                            <h4 className='mb-2 mt-0'>Swap-Free</h4>
                          </div>
                          <p>
                            There are two swap-free levels: Extended and
                            Standard. Your swap-free status is determined by
                            your trading activity. To maintain Extended
                            Swap-free status, you need to trade mostly within
                            the day and hold a low amount of overnight
                            positions. Here are the instruments available for
                            each swap-free status.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>
      </MainLayout>
    </>
  )
}

export default Setting
