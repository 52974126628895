import React from 'react'
import StcoksList from './StcoksList'

const Sidebar = () => {
  return (
    <>
      <section className='dashboard_sidebar_main_div'>
        <div className='Stock_list_sidebar'>
          <StcoksList />
        </div>
      </section>
    </>
  )
}

export default Sidebar
