import React from 'react'

const Features = () => {
  return (
    <>
      <section className='common_section features_section'>
        <div className='container'>
          <div className='row'>
            <div className='col-12 mb_50'>
              <div className='headings'>
                <h2>
                  Everything You Need to <span>Trade & Invest in Stocks</span>
                </h2>
              </div>
            </div>
            <div className='col-lg-4 col-md-6 col-sm-12'>
              <div className='features_box_main'>
                <img
                  src='https://stock-logos.dhan.co/static-openweb/clock.svg'
                  alt='icon'
                  className='img-fluid'
                />
                <h3>Quick Onboarding</h3>
                <p>
                  Open stock trading account online in less than 10 minutes!
                </p>
              </div>
            </div>

            <div className='col-lg-4 col-md-6 col-sm-12'>
              <div className='features_box_main features_box_main_bg_one'>
                <img
                  src='https://stock-logos.dhan.co/static-openweb/dhandematmedpic.png'
                  alt='icon'
                  className='img-fluid'
                />
                <h3>24*7 Support</h3>
                <p>
                  We are available to help you - day or night on email, chat, &
                  social media!
                </p>
              </div>
            </div>
            <div className='col-lg-4 col-md-6 col-sm-12'>
              <div className='features_box_main '>
                <img
                  src='https://stock-logos.dhan.co/static-openweb/star.svg'
                  alt='icon'
                  className='img-fluid'
                />
                <h3>Complete Experience</h3>
                <p>
                  Packed with Technicals, Charts, MTF, All Order Types, SIPs, &
                  much more!
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Features
