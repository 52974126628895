import React, { useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { FaLongArrowAltRight } from 'react-icons/fa'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import { ToastContainer, toast } from 'react-toastify'
import { v4 as uuidv4 } from 'uuid'

const Login = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const device_unique_id = uuidv4()
  const [loginLoader, setLoginLoader] = useState(false)
  const baseUrl = process.env.REACT_APP_API_BASE_URL

  const navigate = useNavigate()
  const UserLogin = e => {
    e.preventDefault()
    var formdata = new FormData()
    formdata.append('email', email)
    formdata.append('password', password)
    formdata.append('device_unique_id', device_unique_id)

    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    }
    setLoginLoader(true)
    fetch(`${baseUrl}login`, requestOptions)
      .then(response => response.json())
      .then(result => {
        // console.log(result)

        if (result.status) {
          toast.success(result.message, {
            autoClose: 1000
          })
          setLoginLoader(false)
          localStorage.setItem('accesToken', result?.token)
          setTimeout(() => {
            navigate('/my-account')
          }, 2000)
        } else {
          toast.error(result.message, {
            autoClose: 2000
          })
          setLoginLoader(false)
        }
      })
      .catch(error => console.log('error', error))
  }
  return (
    <>
      <Header />
      <ToastContainer />

      <section className='login_main_section'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-6 m-auto form_box'>
              <form className='' onSubmit={UserLogin} autoComplete='off'>
                <div className='headings text-center'>
                  <h2 className=''>
                    Welcome to <span>Wells Capital</span>{' '}
                  </h2>
                  <h2 className=''>Login</h2>
                </div>
                <div className='mb-3'>
                  <label for='email' className='form-label'>
                    Email address
                  </label>
                  <input
                    type='email'
                    className='form-control'
                    id='email'
                    placeholder='name@example.com'
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                  />
                </div>
                <div className='mb-3'>
                  <label for='password' className='form-label'>
                    Password
                  </label>
                  <input
                    type='password'
                    className='form-control'
                    id='password'
                    placeholder='***************'
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                  />
                </div>
                <div className='register_link '>
                  <p>
                    Dont't have an account?{' '}
                    <NavLink to='regsiter'>Register</NavLink>
                  </p>
                  <NavLink to='forget-password'>Forget Password?</NavLink>
                </div>
                <div className='col-12 mt-5 text-center'>
                  <button
                    type='submit'
                    className='yellow_btn start_now_btn'
                    disabled={loginLoader}
                  >
                    {loginLoader ? (
                      'Loading...'
                    ) : (
                      <>
                        Login <FaLongArrowAltRight />
                      </>
                    )}{' '}
                  </button>
                </div>
                <div className='col-12 mt-4 text-center'>
                  <p>
                    Are you a new member?
                    <NavLink to='/regsiter ' style={{ marginLeft: '5px' }}>
                      Register
                    </NavLink>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  )
}

export default Login
