import React, { useEffect, useRef, memo, useContext, useState } from 'react'
import MainLayout from './MainLayout'
import { useParams } from 'react-router-dom'
import { createChart, ColorType } from 'lightweight-charts'
import { StockListContext } from './StockListContext'
import socket from './socket'
import { getLiveData } from './ModalLive'
import { useAccordionButton } from 'react-bootstrap'
import Chart from './ChartLine'
import proto from "./LivePrice.proto";
import { UpstoxTokenContext } from './UpstoxTokenContext'

const protobuf = require("protobufjs");


function TradingViewWidget() {
  const { upstoxToken } = useContext(UpstoxTokenContext)
  let { displayKey } = useParams()
  let { stockName, companyName } = useParams()
  const { socketData } = useContext(StockListContext)
  // console.log('companyName is', companyName)
  // console.log('stockName is', stockName)

  const [historicalData, setHistoricalData] = useState([])
  const [intradayData, setIntradayData] = useState([])
  const [livedatad, setLivedatad] = useState([])
  const [lurl, setlurl] = useState(null)

  // Get today's date
  const today = new Date()

  // Get the date components (year, month, day)
  const year = today.getFullYear()
  const month = String(today.getMonth() + 1).padStart(2, '0') // Months are zero-based
  const day = String(today.getDate()).padStart(2, '0')

  // Format the date as "yyyy-mm-dd"
  const getTodayDate = `${year}-${month}-${day}`
  const candleFromDate = '2023-01-01'

  const container = useRef()

  const filterByName = (socketData, name) => {
    const filteredData = {}

    // Iterate over keys to find an exact match
    Object.keys(socketData).forEach(key => {
      if (key === name) {
        filteredData[key] = socketData[key]
      }
    })

    return filteredData
  }

  const [selectedInterval, setSelectedInterval] = useState('day')

  const handleIntervalChange = interval => {
    setSelectedInterval(interval)
    fetchHistoricalData(interval)
  }

  const fetchHistoricalData = interval => {
    setHistoricalData([])
    var myHeaders = new Headers()
    myHeaders.append('Api-Version', '2.0')
    myHeaders.append('Accept', 'application/json')
    myHeaders.append(
      'Authorization',
      `Bearer ${upstoxToken}`
    )
    myHeaders.append(
      'Cookie',
      '__cf_bm=yHs4Ogzl_kOmFOz3YbBWzObU73Mka_34SMY1OaufEH8-1705642349-1-ATvD63VVeSRqTRPehodTvJc8QnVrZQUzqdRVsseBsTcqps5P+qDnTRGhYi+cHnoAaB3YN1/MGxDJrlYuR+RxZQg=; _cfuvid=kk8ButteId9edz1MmRBgBo9BYZ6biJ9IIBorG399FfU-1705639892707-0-604800000'
    )

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    }

    const intervalMapping = {
      '1minute': '1minute',
      '30minute': '30minute',

      day: 'day',
      week: 'week',
      month: 'month'
    }

    const intervalParam = intervalMapping[interval] || 'day'

    fetch(
      `https://api-v2.upstox.com/historical-candle/${stockName}/${intervalParam}/${getTodayDate}/${candleFromDate}`,
      requestOptions
    )
      .then(response => response.json())
      .then(result => {
        // console.log(result)
        setHistoricalData(result?.data?.candles)
      })
      .catch(error => console.log('error', error))
  }

  useEffect(() => {
    fetchHistoricalData(day)
  }, [stockName])

  let protobufRoot = null;
  const initProtobuf = async () => {
    protobufRoot = await protobuf.load(proto);
    console.log("Protobuf part initialization complete");
  };
  // Helper functions for handling Blob and ArrayBuffer
  const blobToArrayBuffer = async (blob) => {
    if ("arrayBuffer" in blob) return await blob.arrayBuffer();
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = () => reject();
      reader.readAsArrayBuffer(blob);
    });
  };
  // Decode Protobuf messages
  const decodeProfobuf = (buffer) => {
    if (!protobufRoot) {
      console.warn("Protobuf part not initialized yet!");
      return null;
    }
    const FeedResponse = protobufRoot.lookupType(
      "com.upstox.marketdatafeeder.rpc.proto.FeedResponse"
    );
    return FeedResponse.decode(buffer);
  };


  const getLiveData = async () => {
    try {
      const ws = new WebSocket(lurl)

      ws.onopen = () => {
        const data = {
          guid: 'someguid',
          method: 'sub',
          data: {
            mode: 'full',
            instrumentKeys: [stockName]
          }
        }
        ws.send(Buffer.from(JSON.stringify(data)))
      }

      ws.onclose = () => {
        console.log('Disconnected')
      }

      ws.onmessage = async event => {
        const arrayBuffer = await blobToArrayBuffer(event.data)
        let buffer = Buffer.from(arrayBuffer)
        let result = decodeProfobuf(buffer)
        if (result != null) {
          const dataPoint = result?.feeds?.[stockName]?.ff.indexFF
            ? result?.feeds?.[stockName]?.ff.indexFF.marketOHLC.ohlc
            : result?.feeds?.[stockName]?.ff.marketFF.marketOHLC.ohlc

          const dataPoint2 = result?.feeds?.[stockName]?.ff.indexFF
            ? result?.feeds?.[stockName]?.ff.indexFF.ltpc.ltp
            : result?.feeds?.[stockName]?.ff.marketFF.ltpc.ltp

          var formattedDatess = new Date()
          setLivedatad([
            formattedDatess,
            dataPoint2,
            parseFloat(dataPoint[0].high),
            parseFloat(dataPoint[0].low),
            parseFloat(dataPoint[0].close)
          ])
        }
      }

      ws.onerror = error => {
        console.log('WebSocket error:', error)
      }

      return () => ws.close()
    } catch (error) {
      console.error('WebSocket connection error:', error)
    }
  }

  const dchart = async () => {
    // const chartContainer = document.getElementById('chart-container')
    // const chartOptions = {
    //   layout: {
    //     textColor: 'black',
    //     background: { type: 'solid', color: 'white' }
    //   }
    // }
    // const chart = createChart(chartContainer, chartOptions)

    // const candlestickSeries = chart.addCandlestickSeries({
    //   upColor: '#26a69a',
    //   downColor: '#ef5350',
    //   borderVisible: false,
    //   wickUpColor: '#26a69a',
    //   wickDownColor: '#ef5350'
    // })

    const allData =
      livedatad.length > 0
        ? [livedatad, ...historicalData]
        : [...historicalData]

    const convertDateToString = inputDate => {
      const date = new Date(inputDate)
      const year = date.getFullYear()
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const day = String(date.getDate()).padStart(2, '0')
      const hours = String(date.getHours()).padStart(2, '0')
      const minutes = String(date.getMinutes()).padStart(2, '0')
      return Date.parse(`${year}-${month}-${day} ${hours}:${minutes}`) / 1000
    }

    const convertedArray = allData.map(data => {
      return {
        time: convertDateToString(data[0]),
        open: data[1],
        high: data[2],
        low: data[3],
        close: data[4]
      }
    })

    // console.log('convertedArray os', convertedArray)
    convertedArray.sort((a, b) => a.time - b.time)
    setIntradayData(convertedArray)
  }

  return (
    <MainLayout>
      <section className='side_content_main_box'>
        <div className='headings'>
          <h2>{companyName}</h2>
          <div className='interval_btns'>
            {/* Interval buttons */}
            <button
              onClick={() => handleIntervalChange('1minute')}
              className={selectedInterval === '1minute' ? 'active' : ''}
            >
              1 Minute
            </button>
            <button
              onClick={() => handleIntervalChange('30minute')}
              className={selectedInterval === '30minute' ? 'active' : ''}
            >
              30 Minute
            </button>
            <button
              onClick={() => handleIntervalChange('day')}
              className={selectedInterval === 'day' ? 'active' : ''}
            >
              1 Day
            </button>
            <button
              onClick={() => handleIntervalChange('week')}
              className={selectedInterval === 'week' ? 'active' : ''}
            >
              1 Week
            </button>
            <button
              onClick={() => handleIntervalChange('month')}
              className={selectedInterval === 'month' ? 'active' : ''}
            >
              1 Month
            </button>
          </div>
        </div>


        <section className='dashboard_boxes_main_section '>
          {
            historicalData.length > 0 && stockName != '' ? <Chart hdatdata={historicalData} stockName={stockName} /> : null
          }

        </section>
      </section>
    </MainLayout>
  )
}

export default memo(TradingViewWidget)




