import React, { useContext, useEffect, useState } from 'react'

import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import MainLayout from './MainLayout'
import { BsDownload } from 'react-icons/bs'
import { NavLink, useNavigate } from 'react-router-dom'
import { AiOutlinePlus } from 'react-icons/ai'
import DataTable from 'react-data-table-component'
import { ToastContainer, toast } from 'react-toastify'
import { IoClose } from 'react-icons/io5'
import { MdOutlineEdit } from 'react-icons/md'
import dayjs from 'dayjs'
import Deposit from './Deposit'
import Withdrawal from './Withdrawal'
import { UserContext } from './UserContext'
import { StockListContext } from './StockListContext'
import OpenTrade from './OpenTrade'
import PendingTrade from './PendingTrade'
const OrderHistory = () => {
  const { userProfileData, setUserProfileData } = useContext(UserContext)

  const { socketData } = useContext(StockListContext)
  // console.log('socketData is order', socketData)
  const navigate = useNavigate()
  const [withrawalAmount, setWithrawalAmount] = useState('')
  const [withrawalRemark, setWithrawalRemark] = useState('')
  const [transactionTypeFilter, setTransactionTypeFilter] = useState('')

  const token = localStorage.getItem('accesToken')
  const baseUrl = process.env.REACT_APP_API_BASE_URL

  const [historyData, setHistoryData] = useState([])
  const [orderListData, setOrderListData] = useState([])

  // ===========deposite modal===========
  const [showModalDeposite, setShowModalDeposite] = useState(false)
  const handleCloseDeposite = () => setShowModalDeposite(false)
  const handleShowDeposite = () => setShowModalDeposite(true)

  // ===========Sell modal===========
  const [showModalWithdrawal, setShowModalWithdrawal] = useState(false)
  const handleCloseWithdrawal = () => setShowModalWithdrawal(false)
  const handleShowWithdrawal = () => setShowModalWithdrawal(true)

  const stockHistory = () => {
    var myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${token}`)

    var formdata = new FormData()
    if (
      transactionTypeFilter &&
      transactionTypeFilter !== 'All transaction types'
    ) {
      formdata.append('type', transactionTypeFilter) // Include transactionTypeFilter in the request if it exists and is not "All transaction types"
    }

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    }

    fetch(`${baseUrl}stock-history`, requestOptions)
      .then(response => {
        if (response.status === 401) {
          navigate('/login')
          localStorage.removeItem('accesToken')
        }
        return response.json()
      })
      .then(result => {
        // console.log(result)
        setHistoryData(result?.data)
      })
      .catch(error => console.log('Error fetching or filtering data:', error))
  }

  useEffect(() => {
    stockHistory()
  }, [token, transactionTypeFilter])

  // Define columns for the table
  const columns = [
    {
      name: 'SR No.',
      selector: (row, index) => index + 1,
      sortable: true,
      grow: 0,
      sortable: true
    },
    {
      name: 'Time & Date',
      selector: 'created_at',
      sortable: true,
      cell: row => {
        // Format the 'created_at' date using dayjs
        return dayjs(row.created_at).format('hh:mm A, DD MMM YYYY') // Adjust the format as needed
      }
    },
    {
      name: 'Buy / Sell',
      selector: 'buy_sell_type',
      sortable: true,
      cell: row => {
        const backgroundColor =
          row.buy_sell_type === 'sell' ? '#ff0000cc' : '#008000c4' // Example: Setting different colors based on the type
        const textColor = 'white' // White text color

        const cellStyle = {
          backgroundColor,
          color: textColor,
          padding: '8px 16px', // Add padding as needed
          borderRadius: '2px' // Add border-radius as needed
        }

        return <div style={cellStyle}>{row.buy_sell_type}</div>
      }
    },

    {
      name: 'Instrument',
      selector: 'stock_name',
      sortable: true
    },
    // {
    //   name: 'Quantity',
    //   cell: row => {
    //     const quantity = row.quantity
    //     const leverage = row.leverage

    //     if (quantity !== 0 || quantity > 2) {
    //       const quantityByLeverage = quantity / leverage
    //       return quantityByLeverage // Displaying the result with 2 decimal places
    //     } else {
    //       return 'N/A' // To handle division by zero or null leverage
    //     }
    //   },
    //   sortable: false // You may set sortable as per your requirement
    // },
    {
      name: 'Quantity',
      selector: 'quantity',
      sortable: true,
      minWidth: '150px'
    },


    {
      name: 'Price',
      selector: 'price',
      sortable: true
    },
    {
      name: 'LTP',
      selector: 'close_price',
      sortable: true
    },
    {
      name: 'Profit/Loss (%)',
      selector: 'profit_loss_percentage',
      sortable: true,
      minWidth: '140px',
      cell: row => {
        const costPrice = row.close_price // Assuming this is the cost price
        const currentPrice = row.price

        // console.log(currentPrice, 'currentPrice')
        if (costPrice && currentPrice) {
          const profitLoss = ((costPrice - currentPrice) / costPrice) * 100
          const formattedProfitLoss = profitLoss.toFixed(2) // Displaying the result with 2 decimal places
          const textColor = profitLoss >= 0 ? '#008000' : '#ff0000' // Green for profit, red for loss

          const cellStyle = {
            color: textColor,
            padding: '8px 16px', // Add padding as needed
            borderRadius: '2px' // Add border-radius as needed
          }

          return <div style={cellStyle}>{formattedProfitLoss}%</div>
        } else {
          return <div>-</div>
        }
      }
    },
    {
      name: 'Profit/Loss',
      selector: 'profit_loss_percentage',
      sortable: true,
      minWidth: '130px',
      cell: row => {
        const costPrice = row.close_price // Assuming this is the cost price
        const currentPrice = row.price


        if (costPrice && currentPrice) {
          const profitLoss = costPrice - currentPrice
          const formattedProfitLoss = profitLoss.toFixed(2) // Displaying the result with 2 decimal places
          const textColor = profitLoss >= 0 ? '#008000' : '#ff0000' // Green for profit, red for loss

          const cellStyle = {
            color: textColor,
            padding: '8px 16px', // Add padding as needed
            borderRadius: '2px' // Add border-radius as needed
          }

          return <div style={cellStyle}>{formattedProfitLoss}</div>
        } else {
          return <div>-</div>
        }
      }
    },
    {
      name: 'Leverage',
      selector: 'leverage',
      sortable: true,
      cell: row => {
        const isNumber = typeof row.leverage === 'number'
        return isNumber ? `${row.leverage}X` : row.leverage
      }
    },
    // {
    //   name: 'Total Price',
    //   selector: 'total_amount',
    //   sortable: true
    // },

    {
      name: 'Type',
      selector: 'market_limit_type',
      sortable: true
    },
    {
      name: 'Trigger Price',
      selector: 'tiggere_price',
      sortable: true,
      cell: row => {
        return row.tiggere_price || '-'
      }
    }
  ]

  const handleTransactionTypeChange = e => {
    setTransactionTypeFilter(e.target.value)
  }

  // Calculate total balance of Price column
  const totalOpenBalance =
    orderListData && orderListData.length > 0
      ? orderListData.reduce(
        (total, item) => total + parseFloat(item.total_amount || 0),
        0
      )
      : 0

  const totalCloseBalance =
    historyData.length > 0
      ? historyData.reduce(
        (total, item) => total + parseFloat(item.total_amount || 0),
        0
      )
      : 0

  // =================order history==================

  const orderHistory = () => {
    var myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${token}`)
    var formdata = new FormData()

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    }

    fetch(`${baseUrl}order-list`, requestOptions)
      .then(response => {
        if (response.status === 401) {
          navigate('/login')
          localStorage.removeItem('accesToken')
        }
        return response.json()
      })
      .then(result => {
        // console.log(result)
        setOrderListData(result?.data)
      })
      .catch(error => console.log('error', error))
  }

  useEffect(() => {
    orderHistory()
  }, [token])

  const [modalData, setmodalData] = useState([])
  const [modalDataEditData, setmodalDataEditData] = useState([])

  // open modal for
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const openModalWithData = rowData => {
    setShow(true)
    setmodalData(rowData)
  }

  // open modal for edit data
  const [showDataEdit, setShowDataEdit] = useState(false)
  const handleCloseDataEdit = () => setShowDataEdit(false)

  const [triggrtPrice, setTriggerPrice] = useState('')
  const [price, setPrice] = useState('')

  const openModalWithDataEdit = rowData => {
    setShowDataEdit(true)
    setmodalDataEditData(rowData)
    setPrice(rowData?.price)
    setTriggerPrice(rowData?.tiggere_price)
    console.log(rowData?.triggrtPrice)
  }

  // ===========stockDataEtit===========
  const stockDataEtit = e => {
    e.preventDefault()
    var myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${token}`)

    var formdata = new FormData()
    formdata.append('id', modalDataEditData?.id)
    formdata.append('tiggere_price', triggrtPrice)
    formdata.append('buy_sell_type', modalDataEditData?.buy_sell_type)

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    }

    fetch(`${baseUrl}order-edit`, requestOptions)
      .then(response => {
        if (response.status === 401) {
          navigate('/login')
          localStorage.removeItem('accesToken')
        }
        return response.json()
      })
      .then(result => {
        console.log(result)

        if (result.status) {
          toast.success(result.message, {
            autoClose: 1000
          })

          setShowDataEdit(false)
          orderHistory()
        } else {
          toast.error(result.message, {
            autoClose: 2000
          })
        }
      })
      .catch(error => console.log('error', error))
  }

  return (
    <>
      <ToastContainer />

      <MainLayout>
        <section className='side_content_main_box'>
          <div className='headings'>
            <h2>Order history</h2>
            <div>
              <div className='account_box_side_buttons'>
                <button onClick={handleShowDeposite}>
                  <AiOutlinePlus className=' sidebar_link_icon' /> Deposite
                </button>
                <Deposit
                  showModalDeposite={showModalDeposite}
                  handleCloseDeposite={handleCloseDeposite}
                />

                <button onClick={handleShowWithdrawal}>
                  <BsDownload className=' sidebar_link_icon' /> Withdrawal
                </button>
                <Withdrawal
                  showModalWithdrawal={showModalWithdrawal}
                  handleCloseWithdrawal={handleCloseWithdrawal}
                />
              </div>
            </div>
          </div>
          <section className='dashboard_boxes_main_section '>
            <div className='col-lg-12 col-md-12 col-sm-12'>
              {/* filters */}
              <div className='account_filter_div transaction_filter_div Tabs_balance_div'>
                {/* <div>
                  <select
                    className='form-select account_filter_btn'
                    aria-label='Default select example'
                  >
                    <option selected>Select a Time Period</option>
                    <option value='Last 3 days'>Last 3 days</option>
                    <option value='Last 7 days'>Last 7 days</option>
                    <option value='Last 30 days'>Last 30 days</option>
                    <option value='Last 3 months'>Last 3 months</option>
                  </select>
                </div>
               
                <div>
                  <select
                    className='form-select account_filter_btn'
                    aria-label='Transaction Type filter'
                    value={transactionTypeFilter}
                    onChange={handleTransactionTypeChange}
                  >
                    <option>All transaction types</option>
                    <option value='sell'>Sell</option>
                    <option value='buy'>Buy</option>
                  </select>
                </div> */}

                <ul className='nav nav-tabs' id='myTab' role='tablist'>
                  <li className='nav-item' role='presentation'>
                    <button
                      className='nav-link active'
                      id='profile-tab'
                      data-bs-toggle='tab'
                      data-bs-target='#profile'
                      type='button'
                      role='tab'
                      aria-controls='profile'
                      aria-selected='false'
                    >
                      Open Positions
                    </button>
                  </li>
                  <li className='nav-item' role='presentation'>
                    <button
                      className='nav-link '
                      id='home-tab'
                      data-bs-toggle='tab'
                      data-bs-target='#home'
                      type='button'
                      role='tab'
                      aria-controls='home'
                      aria-selected='true'
                    >
                      Closed Trades
                    </button>
                  </li>
                  <li className='nav-item' role='presentation'>
                    <button
                      className='nav-link '
                      id='pending-tab'
                      data-bs-toggle='tab'
                      data-bs-target='#pending'
                      type='button'
                      role='tab'
                      aria-controls='pending'
                      aria-selected='true'
                    >
                      Pending Orders
                    </button>
                  </li>
                </ul>
                <div>
                  <h4>
                    Open Trade Balance:{' '}
                    <span>₹ {userProfileData?.holding.toFixed(2)}</span>
                  </h4>
                  <h4>
                    Close Trade Balance:
                    <span>₹ {totalCloseBalance.toFixed(2)}</span>{' '}
                  </h4>
                </div>
              </div>
            </div>

            <div className='col-12'>
              <div>
                <div className='tab-content' id='myTabContent'>
                  <div
                    className='tab-pane fade show active'
                    id='profile'
                    role='tabpanel'
                    aria-labelledby='profile-tab'
                  >
                    <OpenTrade />
                  </div>
                  <div
                    className='tab-pane fade  '
                    id='home'
                    role='tabpanel'
                    aria-labelledby='home-tab'
                  >
                    <DataTable
                      className='mt-2'
                      columns={columns}
                      data={historyData}
                      pagination // Enable pagination if needed
                    />
                  </div>

                  <div
                    className='tab-pane fade  '
                    id='pending'
                    role='tabpanel'
                    aria-labelledby='pending-tab'
                  >
                    <PendingTrade />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>
      </MainLayout>
    </>
  )
}

export default OrderHistory
