
import React, { useEffect, useState, useContext } from "react";
import proto from "./LivePrice.proto";
import { Buffer } from "buffer";
import { UpstoxTokenContext } from './UpstoxTokenContext'

const protobuf = require("protobufjs");
let protobufRoot = null;
const initProtobuf = async () => {
    protobufRoot = await protobuf.load(proto);
};

function ProfitLoss({ stockName, stockPrice, displayType }) {

    const { upstoxToken } = useContext(UpstoxTokenContext)
    // console.log(upstoxToken)
    const [isConnected, setIsConnected] = useState(false);
    const [feedData, setFeedData] = useState([]);

    const getUrl = async (token) => {
        const apiUrl = "https://api-v2.upstox.com/feed/market-data-feed/authorize";
        let headers = {
            "Content-type": "application/json",
            Authorization: `Bearer ${upstoxToken}`,
        };
        const response = await fetch(apiUrl, {
            method: "GET",
            headers: headers,
        });
        if (!response.ok) {
            throw new Error("Network response was not ok");
        }
        const res = await response.json();
        return res.data.authorizedRedirectUri;
    };


    const blobToArrayBuffer = async (blob) => {
        if ("arrayBuffer" in blob) return await blob.arrayBuffer();
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result);
            reader.onerror = () => reject();
            reader.readAsArrayBuffer(blob);
        });
    };

    const decodeProfobuf = (buffer) => {
        if (!protobufRoot) {
            console.warn("Protobuf part not initialized yet!");
            return null;
        }
        const FeedResponse = protobufRoot.lookupType(
            "com.upstox.marketdatafeeder.rpc.proto.FeedResponse"
        );
        return FeedResponse.decode(buffer);
    };

    useEffect(() => {
        const connectWebSocket = async (stockName) => {
            try {
                const wsUrl = await getUrl(stockName);
                const ws = new WebSocket(wsUrl);

                ws.onopen = () => {
                    setIsConnected(true);
                    const data = {
                        guid: "someguid",
                        method: "sub",
                        data: {
                            mode: "full",
                            instrumentKeys: [stockName],
                        },
                    };
                    ws.send(Buffer.from(JSON.stringify(data)));
                };
                ws.onclose = () => {
                    setIsConnected(false);
                    console.log("Disconnected");
                };
                ws.onmessage = async (event) => {
                    const arrayBuffer = await blobToArrayBuffer(event.data);
                    let buffer = Buffer.from(arrayBuffer);
                    let response = decodeProfobuf(buffer);
                    // console.log(response);
                    setFeedData(response);
                };
                ws.onerror = (error) => {
                    setIsConnected(false);
                    console.log("WebSocket error:", error);
                };
                return () => ws.close();
            } catch (error) {
                console.error("WebSocket connection error:", error);
            }
        };

        initProtobuf();
        connectWebSocket(stockName);
    }, [stockName]);

    return (
        <>
            {/* {
                displayType == "price" ?
                    <div>
                        {((feedData?.feeds?.[stockName]?.ff?.marketFF?.ltpc?.ltp || feedData?.feeds?.[stockName]?.ff?.indexFF?.ltpc?.ltp || '0.00') - stockPrice).toFixed(2)}
                    </div>
                    : null
            }
            {
                displayType == "pr" ?
                    <div>
                        {((((feedData?.feeds?.[stockName]?.ff?.marketFF?.ltpc?.ltp || feedData?.feeds?.[stockName]?.ff?.indexFF?.ltpc?.ltp || '0.00') - stockPrice) / stockPrice) * 100).toFixed(2)}%
                    </div>
                    : null
            } */}
            {
                displayType === "price" && (
                    <div style={{ color: (feedData?.feeds?.[stockName]?.ff?.marketFF?.ltpc?.ltp || feedData?.feeds?.[stockName]?.ff?.indexFF?.ltpc?.ltp || '0.00') - stockPrice >= 0 ? 'green' : 'red' }}>
                        {((feedData?.feeds?.[stockName]?.ff?.marketFF?.ltpc?.ltp || feedData?.feeds?.[stockName]?.ff?.indexFF?.ltpc?.ltp || '0.00') - stockPrice).toFixed(2)}
                    </div>
                )
            }
            {
                displayType === "pr" && (
                    <div style={{ color: (feedData?.feeds?.[stockName]?.ff?.marketFF?.ltpc?.ltp || feedData?.feeds?.[stockName]?.ff?.indexFF?.ltpc?.ltp || '0.00') - stockPrice >= 0 ? 'green' : 'red' }}>
                        {((((feedData?.feeds?.[stockName]?.ff?.marketFF?.ltpc?.ltp || feedData?.feeds?.[stockName]?.ff?.indexFF?.ltpc?.ltp || '0.00') - stockPrice) / stockPrice) * 100).toFixed(2)}%
                    </div>
                )
            }
        </>

    );
}

export default ProfitLoss;
