import React from 'react'
import { MdOutlineSearch } from 'react-icons/md'
import { FaLongArrowAltRight } from 'react-icons/fa'
import { NavLink } from 'react-router-dom'
const Hero = () => {
  return (
    <>
      <section className='blue_grident hero_setion'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-6 col-md-12 col-sm-12'>
              <div className='headings'>
                <h1>
                  Lightning Fast Way to
                  <br /> <span> Stock Trading </span>
                </h1>
              </div>
              <div>
                <div className='form-group search_box'>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='Search for scrips you want to invest or trade in..'
                  />
                  <MdOutlineSearch className='icon' />
                </div>
                <div className='hero_filter_btns'>
                  <button>Top Gainers</button>
                  <button>Top Losers</button>
                  <button>52 Weak High</button>
                  <button>52 Weak Low</button>
                  <button>Nifty 50 Stocks</button>
                </div>
                <div className='start_now_btn_div'>
                  <p>Pay ₹0 for Delivery. Get 4x Margin on 950+ stocks.</p>
                  <NavLink to='/login' className='yellow_btn start_now_btn'>
                    Start Now <FaLongArrowAltRight />
                  </NavLink>
                </div>
              </div>
            </div>
            <div className='col-lg-6 col-md-12 col-sm-12'>
              <div className='hero_section_img'>
                <img
                  src='https://stock-logos.dhan.co/static-new-images/stocksfrontpage.png'
                  alt='hero'
                  className='img-fluid'
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Hero
