import React, { useEffect, useState } from 'react'

import MainLayout from './MainLayout'
import { useNavigate } from 'react-router-dom'
import DataTable from 'react-data-table-component'
import { ToastContainer } from 'react-toastify'

import dayjs from 'dayjs'
const TransactionHistory = () => {
  const token = localStorage.getItem('accesToken')
  const baseUrl = process.env.REACT_APP_API_BASE_URL

  const [historyData, setHistoryData] = useState([])
  const [originalData, setOriginalData] = useState([]) // Store original data

  // const handleTransactionTypeChange = e => {
  //   setTransactionTypeFilter(e.target.value)
  // }
  const navigate = useNavigate()

  const [transactionTypeFilter, setTransactionTypeFilter] = useState(
    'All transaction types'
  )
  const [activeFilter, setActiveFilter] = useState('All transaction types')

  //... rest of your code

  useEffect(() => {
    var myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${token}`)

    var formdata = new FormData()
    if (
      transactionTypeFilter &&
      transactionTypeFilter !== 'All transaction types'
    ) {
      formdata.append('type', transactionTypeFilter) // Include transactionTypeFilter in the request if it exists and is not "All transaction types"
    }

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    }

    fetch(`${baseUrl}transaction-history`, requestOptions)
      .then(response => {
        if (response.status === 401) {
          navigate('/login')
          localStorage.removeItem('accesToken')
        }
        return response.json()
      })
      .then(result => {
        setHistoryData(result?.data)
        setOriginalData(result?.data)
      })
      .catch(error => console.log('Error fetching or filtering data:', error))
  }, [token, transactionTypeFilter])

  // Define columns for the table
  const columns = [
    {
      name: 'SR No.',
      selector: (row, index) => index + 1,
      sortable: true,
      grow: 0,
      sortable: true
    },
    {
      name: 'Time & Date',
      selector: 'created_at',
      sortable: true,
      cell: row => {
        // Format the 'created_at' date using dayjs
        return dayjs(row.created_at).format('hh:mm A, DD MMM YYYY') // Adjust the format as needed
      }
    },
    {
      name: 'Type',
      selector: 'trnx_type',
      sortable: true
    },
    {
      name: 'Amount',
      selector: 'amount',
      sortable: true
    },

    {
      name: 'Status',
      selector: 'status',
      sortable: true,
      cell: row => {
        let statusMessage = ''
        let statusColor = '' // Define variable for status color

        switch (row.status) {
          case 0:
            statusMessage = 'Pending'
            statusColor = '#2e7eb5' // Set background color for 'Pending' status
            break
          case 1:
            statusMessage = 'Success'
            statusColor = '#1bb81b' // Set background color for 'Success' status
            break
          case 2:
            statusMessage = 'Reject'
            statusColor = 'red' // Set background color for 'Reject' status
            break
          default: // Set background color for 'Unknown' status
            statusMessage = 'Unknown'
            statusColor = 'grey'
            break
        }

        // Style the cell with background color based on status
        return (
          <div
            style={{
              backgroundColor: statusColor,
              padding: '5px',
              borderRadius: '5px',
              color: '#fff'
            }}
          >
            {statusMessage}
          </div>
        )
      }
    },
    {
      name: 'Remark',
      selector: 'hash',
      sortable: true
    }
  ]

  const filterTransactionData = types => {
    if (types.includes('All transaction types')) {
      setActiveFilter('All transaction types')
      setHistoryData(originalData)
    } else {
      setActiveFilter(types[0]) // Assuming the first type represents the active filter
      const filteredData = originalData.filter(item =>
        types.includes(item.trnx_type)
      )
      setHistoryData(filteredData)
    }
  }
  return (
    <>
      <ToastContainer />

      <MainLayout>
        <section className='side_content_main_box'>
          <div className='headings'>
            <h2>Transaction history</h2>
          </div>
          <section className='dashboard_boxes_main_section '>
            <div className='col-lg-12 col-md-12 col-sm-12'>
              {/* filters */}
              <div className='account_filter_div transaction_filter_div'>
                {/* date filter */}

                <div>
                  <button
                    className={`account_filter_btn ${activeFilter === 'All transaction types' ? 'active' : ''
                      }`}
                    onClick={() =>
                      filterTransactionData('All transaction types')
                    }
                  >
                    All
                  </button>
                  <button
                    className={`account_filter_btn ${activeFilter === 'withdrawal' ? 'active' : ''
                      }`}
                    onClick={() =>
                      filterTransactionData(['withdrawal', 'fund_add'])
                    }
                  >
                    Funds
                  </button>
                  <button
                    className={`account_filter_btn ${activeFilter === 'stock_sell' ? 'active' : ''
                      }`}
                    onClick={() =>
                      filterTransactionData(['stock_sell', 'stock_buy'])
                    }
                  >
                    Trades
                  </button>
                </div>
              </div>
            </div>

            <div className='col-12'>
              <DataTable
                className='mt-2'
                columns={columns}
                data={historyData}
                pagination // Enable pagination if needed
              />
            </div>
          </section>
        </section>
      </MainLayout>
    </>
  )
}

export default TransactionHistory
